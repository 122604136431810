import React, { useEffect, useContext, useState } from "react";
import { BiArrowBack, BiPlus } from "react-icons/bi";
import {BsPrinter} from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";
import Constants from "../../../../util/Constants";
import useFetch from "../../../../util/useFetch";
import CardContainer from "../../../CardContainer";
import PageLoader from "../../../PageLoader";
import logos from "../../../../util/logos";
import Dropdown from "../../../dropdown/Dropdown";
import IndicatorTable from "./IndicatorTable";
import AddEditIndicatorModal from "./AddEditIndicatorModal";
import UserAccessUtil from "../UserAccessUtil";
import AuthContext from "../../../../context/AuthContext";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";

var selectedAgencyIndicatorList;

const IndicatorManualManagement = () => {
  const { data, isLoading, error } = useFetch(
    "/retrieve_agencies", {
      method: "GET",
    },
    Constants.ApiCallerIndicatorManagementAgencies
  );
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const params = location.search;
  const navigate = useNavigate();
  const [selectedAgencyName, setSelectedAgencyName] = useState();
  const [selectedIndicator, setSelectedIndicator] = useState();
  const [showIsRetiringOrReinstatingDialog, setShowIsRetiringOrReinstatingDialog] = useState(false); //retire or reinstate false=reinstate true=retiring
  const [isReinstating, setIsReinstating] = useState(false); //true for reinstating and false for retiring
  const [userAgency, setUserAgency] = useState();

  let modal = document.getElementById("add-edit-modal");
  const userInfo = useContext(AuthContext).userInfo;
  const agencyNameList = useContext(AgencyIndicatorListContext).agencyIndicatorList.agencyList.map((agc)=>agc.agencyName.toLowerCase());
  // const userAgency = data.find((agc)=>agc.agencyName.toLowerCase() === userInfo.agency.toLowerCase());

  useEffect(() => {
    if(!UserAccessUtil.canGoToIndicatorManagement(userInfo,agencyNameList)){
      navigate('/admin');
      return;
    }
    if(data){
      setUserAgency(data.find((agc)=>agc.agencyName.toLowerCase() === String(userInfo.agency).toLowerCase()) ?? null);
    }
    if (params) {
      if (params.includes("agencyId")) { 
        // const selectedAgencyId = query.get("agencyId");
      }
    } else {
      setSelectedAgencyName(null);
    }
  }, [params,data]);

  function onBackClick() {
    navigate(-1);
  }

  function addIndicator() {
    modal.style.display = "block";
  }

  function handleOnClick(agency) {
    setSelectedAgencyName(agency.agencyName);
    selectedAgencyIndicatorList = agency.indicators;
    navigate("/admin/indicatorManagement?agencyId=" + agency.id);
  }

  function onCancel() {
    modal.style.display = "none";
  }

  return (
    <div className="h-screen">
      <AddEditIndicatorModal userAgency={userAgency} isReinstating={isReinstating} onCancel={onCancel} agencyList={data} showIsRetiringOrReinstatingDialog={showIsRetiringOrReinstatingDialog} selectedIndicator={selectedIndicator} setSelectedIndicator={setSelectedIndicator}/>

      <div className="flex flex-col pl-4 mr-3 pt-2 h-full">
        <div className="flex pb-2 justify-between">
          <div
            onClick={() => onBackClick()}
            className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
          >
            <BiArrowBack size={18} color="white" />
            <p>Back</p>
          </div>
          {selectedAgencyName && (
            <h3 className="title-md">{selectedAgencyName + " Indicators"}</h3>
          )}
         <div className="flex space-x-3">
         <div
            onClick={() => {
              setSelectedIndicator(null);
              setShowIsRetiringOrReinstatingDialog(false);
              addIndicator();
            }}
            className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
          >
            <BiPlus size={18} color="white" />
            <p> Add indicator</p>
          </div>
          {/* 
          {selectedAgencyName &&
          <div
            onClick={() => {window.print()}}
            className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
          >
            <BsPrinter size={18} color="white" />
            <p> Print</p>
          </div>} */}
         </div>
        </div>
        <div className="overflow-auto mb-12">
          {selectedAgencyName ? (
            <IndicatorTable setIsReinstating={setIsReinstating} indicatorList={selectedAgencyIndicatorList} setShowIsRetiringOrReinstatingDialog={setShowIsRetiringOrReinstatingDialog} onEditClick={addIndicator} setSelectedIndicator={setSelectedIndicator}/>
          ) : (
            <PageLoader isLoading={isLoading} error={error}>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-2 gap-2 mb-12">
                {data && UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) &&
                  data.map((model) => (
                    <CardContainer
                      key={model.id}
                      child={
                        <div className="flex flex-col justify-center w-full h-full">
                          <img
                            src={logos[model.id]}
                            width={140}
                            height={140}
                            alt="Agency Logo"
                            className="self-center my-3"
                          />
                          <h3 className="text-center text-xl font-bold">
                            {model.agencyName}
                          </h3>
                          <h3 className="w-full text-center text-5xl font-bold mt-4">
                            {model.indicators.length}
                          </h3>
                          <h3 className="w-full h-full text-center title-lg">
                            Indicators
                          </h3>
                          <h3 className="w-full text-center font-bold h-full text-lg">
                            {model.visibleOnDashboardCount} Visualizable
                          </h3>
                          <button
                            onClick={() => handleOnClick(model)}
                            className="bg-black text-white mt-2 rounded-md py-2 px-4"
                          >
                            Manage Indicators
                          </button>
                        </div>
                      }
                    />
                  ))}
               {data && userAgency && !UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) && <CardContainer
                      key={userAgency.id}
                      child={
                        <div className="flex flex-col justify-center w-full h-full">
                          <img
                            src={logos[userAgency.id]}
                            width={140}
                            height={140}
                            alt="Agency Logo"
                            className="self-center my-3"
                          />
                          <h3 className="text-center text-xl font-bold">
                            {userAgency.agencyName}
                          </h3>
                          <h3 className="w-full text-center text-5xl font-bold mt-4">
                            {userAgency.indicators.length}
                          </h3>
                          <h3 className="w-full h-full text-center title-lg">
                            Indicators
                          </h3>
                          <h3 className="w-full text-center font-bold h-full text-lg">
                            {userAgency.visibleOnDashboardCount} Visualizable
                          </h3>
                          <button
                            onClick={() => handleOnClick(userAgency)}
                            className="bg-black text-white mt-2 rounded-md py-2 px-4"
                          >
                            Manage Indicators
                          </button>
                        </div>
                      }
                    />}

              </div>
            </PageLoader>
          )}
        </div>
      </div>
    </div>
  );
};


export default IndicatorManualManagement;
