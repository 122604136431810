import { React, useContext, useState, useEffect } from "react";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import Spinner from "../../../../Spinner/Spinner";
import Dropdown from "../../../dropdown/Dropdown";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Constants from "../../../../util/Constants";
import AuthContext from "../../../../context/AuthContext";

const EditAddDataDictionaryModal = ({
  userAgency,
  hideModal,
  selectedDataDictionary,
  isEditing,
  isDeleting,
}) => {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.userInfo;
  
  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState();
  const [term, setTerm] = useState();
  const [termDefinition, setTermDefinition] = useState();
  const [enableAgencyDropDown, setEnableAgencyDropDown] = useState(true);

  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);
  const agencyList = agencyIndicatorListContext.agencyIndicatorList.agencyList;

  useEffect(() => {
    if ((isEditing && selectedDataDictionary ) || (isDeleting && selectedDataDictionary)) {
      setTerm(selectedDataDictionary.term);
      setTermDefinition(selectedDataDictionary.definition);
      const ag = agencyList.find(
        (item) => item.id === selectedDataDictionary.agencyId
      );
      setSelectedAgency({ agencyName: ag.agencyName, id: ag.id });
    } else {
      setTerm(null);
      setTermDefinition(null);
      setSelectedAgency(null);
    }
    if(userAgency){
      setEnableAgencyDropDown(false);
      setSelectedAgency({ agencyName: userAgency.agencyName, id: userAgency.id })
    }else{
      setEnableAgencyDropDown(true);
    }
  }, [isEditing, isDeleting,userAgency]);

  const handleSave = (e) => {
    e.preventDefault();
    if (!selectedAgency) {
      toastError("Please select agency");
    } else {
        sendRequest();
    }
  };

  function sendRequest(){
    const data = {
        agencyId: selectedAgency.id,
        definition: termDefinition,
        term: term,
        createdBy: selectedDataDictionary ? selectedDataDictionary.createdBy : currentUser.id,
        modifiedBy: currentUser.id,
        lastModified: new Date().toISOString(),
        retiredBy: selectedDataDictionary ? selectedDataDictionary.retiredBy : null,
        dateCreated: selectedDataDictionary ? selectedDataDictionary.dateCreated : new Date().toDateString()
      };
      if((isEditing && selectedDataDictionary) || isDeleting){
          data.id = selectedDataDictionary.id;
      }
      setIsLoading(true);
      fetch(Constants.BaseUrl + ((isDeleting) ? "/delete_dataDictionary/" : "/save_update_dataDictionary/"), {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          if (!res.ok) {
            throw Error(
              "An error occurred while saving, please try again later"
            );
          }
          return res.json();
        })
        .then(async (result) => {
            console.log(result)
          toast.info("Saved Successfully", {
            position: "top-center",
          });
          hideModal();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message, {
            position: "top-center",
          });
        });
  }

  function deleteTerminology(){
      sendRequest();
  }

  function onAgencySelect(agency) {
    const ag = agencyList.find((item) => item.agencyName === agency);
    setSelectedAgency({ agencyName: ag.agencyName, id: ag.id });
  }

  function toastError(message) {
    toast.error(message, {
      position: "top-center",
    });
  }

  return (
    <div
    className="fixed hidden inset-0 bg-gray-600 p-5 md:p-0 lg:p-0 xl:p-0 2xl:p-0 bg-opacity-50 overflow-y-auto h-full w-full"
    id="add-edit-modal"
    >
      <div className="relative top-20 mx-auto p-5 border md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full shadow-lg rounded-xl bg-white">
        <div className="flex justify-between">
          <h3 className="title-md">
            {isEditing && selectedDataDictionary
              ? "Edit " + selectedDataDictionary.term + ":"
              : isDeleting
              ? "Delete " + selectedDataDictionary.term
              : "Add to data dictionary"}
          </h3>
          <button
            type="button"
            onClick={() => hideModal()}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {isLoading && <Spinner />}
        { (!isLoading && isDeleting) && <>
        Are you sure you want to delete {selectedDataDictionary.term}? This cannot be undone.<br/>
        <div className="flex space-x-5 justify-end">
                <div className="flex">
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      hideModal();
                    }}
                    className="flex cursor-pointer bg-green-500 px-8 items-center h-10 text-white rounded-lg"
                  >
                    <p>NO</p>
                  </button>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      deleteTerminology();
                    }}
                    className="flex cursor-pointer bg-red-500 space-x-1.5 items-center h-10 text-white px-8 rounded-lg"
                  >
                    <p>YES</p>
                  </button>
                </div>
              </div>
        </>}
        {!isLoading && !isDeleting && (
          <form className="px-4 pb-4 space-y-3 mt-3" onSubmit={handleSave}>
            {enableAgencyDropDown && <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Select Agency
              </label>
              <Dropdown
                className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                placeHolder="Select One"
                values={agencyIndicatorListContext.agencyIndicatorList.agencyList.map(
                  (item) => item.agencyName
                )}
                selected={selectedAgency && selectedAgency.agencyName}
                onSelect={onAgencySelect}
              />
            </div>}
            <div>
              <label
                htmlFor="term"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Terminology Name
              </label>
              <input
                type="text"
                onChange={(e) => setTerm(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={term ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="term-definition"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Terminology Definition
              </label>
              <textarea
                type="textarea"
                name="term-definition"
                rows={8}
                maxLength={6000}
                onChange={(e) => setTermDefinition(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={termDefinition ?? ""}
                required
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
              >
                <FaSave size={18} color="white" />
                <p>Save</p>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditAddDataDictionaryModal;
