import { BiArrowBack } from "react-icons/bi";
import useFetch from "../../../../util/useFetch";
import CardContainer from "../../../CardContainer";
import PageLoader from "../../../PageLoader";
import { MdDone, MdOutlineClose } from "react-icons/md";
import boy from "../../../../assets/images/boy.png";
import girl from "../../../../assets/images/girl.png";
import { SlideDown } from "react-slidedown";
import Constants from "../../../../util/Constants";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import schoolImg from "../../../../assets/images/school.png";

const notAvailable = "NOT AVAILABLE";

const SchoolPage = ({
  schoolID,
  onBackClicked,
  showTopBar = true,
  setSchoolInfo,
}) => {
  const navigate = useNavigate();
  const [isExpanded, setExpanded] = useState(
    !showTopBar ? true : onBackClicked ? true : false
  );

  const {
    data: schoolData,
    isLoading,
    error,
  } = useFetch(
    "/schoolManuals/&school=" + schoolID, //query.get('schoolID'),
    {
      method: "GET",
    },
    Constants.ApiCallerSchoolPage
  );

  useEffect(() => {
    if (setSchoolInfo && schoolData) setSchoolInfo(schoolData);
  }, [schoolData]);

  const handleDetailsOnClick = () => {
    if (onBackClicked) {
      navigate("/masterFacility/schoolDetails?schoolID=" + schoolID+'&msrcId='+(schoolData ? schoolData.msrcId : 0)+'&emisCode='+(schoolData ? schoolData.emisCode : 0));
    } else {
      setExpanded(!isExpanded);
    }
  };

  return (
    <div className="w-full overflow-y-auto">
      {showTopBar && (
        <div
          className={"flex justify-between " + (onBackClicked ? " pr-20" : "")}
        >
          {onBackClicked && (
            <div
              onClick={() => onBackClicked()}
              className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
            >
              <BiArrowBack size={18} color="white" />
              <p>Back</p>
            </div>
          )}
          <h3 className="title-lg px-2.5">
            {isLoading ? "Loading..." : schoolData ? schoolData.nameOfSchool : ''}
          </h3>

          {/* <div className="flex cursor-pointer w-10 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"></div> */}

          {schoolData &&
            (schoolData.msrcId > 0 ? (  //TODO: uncomment this when you work on the msrc school detail page and remove the useless code above
              <div
                onClick={handleDetailsOnClick}
                className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
              >
                <p>
                  {onBackClicked
                    ? "View School Details"
                    : isExpanded
                    ? "- Collapse School Details"
                    : "+ Expand School Details"}
                </p>
              </div>
            ) : (
              <div></div>
            ))}
        </div>
      )}
      <SlideDown>
        {isExpanded && (
          <div
            className={`grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-3 gap-2 py-3 pb-16 
              ${
                onBackClicked
                  ? " pr-5 md:pr-20 lg:pr-20 xl:pr-20 2xl:pr-20 "
                  : ""
              }`}
          >
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      IDENTIFICATION:
                    </h3>
                    <img
                      width={140}
                      height={140}
                      src={schoolImg}
                      alt="school"
                    />
                    <h3 className="title-lg mt-5 text-center">
                      TYPE: {schoolData ? schoolData.school_type : notAvailable}
                    </h3>
                    <h3 className="title-lg text-center">
                      EMIS CODE:{" "}
                      {schoolData ? schoolData.emisCode : notAvailable}
                    </h3>
                    <h3 className="title-lg text-center">
                      SCHOOL CODE:{" "}
                      {schoolData ? schoolData.schoolCode : notAvailable}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col justify-between h-full items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      LEVELS:
                    </h3>
                    <div className="flex justify-start w-full items-center mt-4">
                      <div
                        className={
                          schoolData
                            ? (schoolData.levelOfEducationKG === "YES"
                                ? "bg-green-600"
                                : "bg-red-500") +
                              " flex flex-col w-8 h-8 my-1 mx-3 items-center justify-center rounded-full"
                            : ""
                        }
                      >
                        {schoolData ? (
                          schoolData.levelOfEducationKG === "YES" ? (
                            <MdDone
                              size={30}
                              color="white"
                              // style={{ display: "block" }}
                            />
                          ) : (
                            <MdOutlineClose size={30} color="white" />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <h3 className="title-md w-full text-center">
                        KINDERGARTEN
                      </h3>
                    </div>
                    <div className="flex justify-start w-full items-center">
                      <div
                        className={
                          schoolData
                            ? (schoolData.levelOfEducationPRIMARY === "YES"
                                ? "bg-green-600"
                                : "bg-red-500") +
                              " flex flex-col w-8 h-8 my-1 mx-3 items-center justify-center rounded-full"
                            : ""
                        }
                      >
                        {schoolData ? (
                          schoolData.levelOfEducationPRIMARY === "YES" ? (
                            <MdDone
                              size={30}
                              color="white"
                              // style={{ display: "block" }}
                            />
                          ) : (
                            <MdOutlineClose size={30} color="white" />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <h3 className="title-md w-full text-center">
                        PRIMARY SCHOOL
                      </h3>
                    </div>
                    <div className="flex justify-start w-full items-center">
                      <div
                        className={
                          schoolData
                            ? (schoolData.levelOfEducationJHS === "YES"
                                ? "bg-green-600"
                                : "bg-red-500") +
                              " flex flex-col w-8 h-8 my-1 mx-3 items-center justify-center rounded-full"
                            : ""
                        }
                      >
                        {schoolData ? (
                          schoolData.levelOfEducationJHS === "YES" ? (
                            <MdDone
                              size={30}
                              color="white"
                              // style={{ display: "block" }}
                            />
                          ) : (
                            <MdOutlineClose size={30} color="white" />
                          )
                        ) : (
                          ""
                        )}
                      </div>
                      <h3 className="title-md w-full text-center">
                        JUNIOR HIGH SCHOOL
                      </h3>
                    </div>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader isLoading={isLoading} error={error} isPage={false}>
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      ENROLLMENT:
                    </h3>
                    <div className="flex space-x-10 my-8">
                      <div className="flex w-full items-center">
                        <div>
                          <img src={boy} width={80} height={80} alt="boy" />
                          <h3 className="title-md mt-2">MALES</h3>
                        </div>
                        <h3 className="title-lg">
                          {schoolData && schoolData.totalMales}
                        </h3>
                      </div>
                      <div className="flex w-full items-center">
                        <div>
                          <img src={girl} width={77} height={77} alt="girl" />
                          <h3 className="title-md mt-3">FEMALES</h3>
                        </div>
                        <h3 className="title-lg">
                          {schoolData && schoolData.totalFemales}
                        </h3>
                      </div>
                    </div>
                    <h3 className="title-lg mt-5">
                      TOTAL: {schoolData && schoolData.totalMalesAndFemales}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-center items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      REGION:
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        Constants.regionList.find(
                          (element) =>
                            element.regionID === String(schoolData.region)
                        ).regionName}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      DISTRICT:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData && (schoolData.district ?? notAvailable)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      CIRCUIT:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData && (schoolData.circuitName ?? notAvailable)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      DISTRICT TYPE:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData && (schoolData.districtType ?? notAvailable)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      LOCALITY:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData && (schoolData.suburb ?? notAvailable)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      CITY/TOWN/VILLAGE:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.city === ""
                          ? notAvailable
                          : schoolData.city)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      TYPE OF LOCALITY:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.locality === ""
                          ? notAvailable
                          : schoolData.locality)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      GPS:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.gpsCordinates === ""
                          ? notAvailable
                          : schoolData.gpsCordinates)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      GHANA POST GPS:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.ghanaPostGPS === ""
                          ? notAvailable
                          : schoolData.ghanaPostGPS)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      POST OFFICE BOX:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.ghanaPostAddress === ""
                          ? notAvailable
                          : schoolData.ghanaPostAddress)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      SHIFT SYSTEM:
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData && (schoolData.shift ? "YES" : "NO")}
                    </h3>{" "}
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      SCHOOL TEL. NUMBER:
                    </h3>

                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.telephoneNumber === ""
                          ? notAvailable
                          : schoolData.telephoneNumber)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      KG HEAD TEACHER:
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.kg_name_of_head === ""
                          ? notAvailable
                          : schoolData.kg_name_of_head)}
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.kg_head_number === ""
                          ? "PHONE " + notAvailable
                          : schoolData.kg_head_number)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      PRIMARY HEAD TEACHER:
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.primary_name_of_head === ""
                          ? notAvailable
                          : schoolData.primary_name_of_head)}
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.primary_head_number === ""
                          ? "PHONE " + notAvailable
                          : schoolData.primary_head_number)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
            <CardContainer
              child={
                <PageLoader
                  isLoading={isLoading}
                  error={error}
                  isPage={false}
                  className="w-full h-full"
                >
                  <div className="flex flex-col h-full w-full justify-between items-center">
                    <h3 className="title-md mb-5 bg-green-500 text-white py-0.5 w-full text-center rounded-md">
                      JHS HEAD TEACHER:
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.jhs_name_of_head === ""
                          ? notAvailable
                          : schoolData.jhs_name_of_head)}
                    </h3>
                    <h3 className="title-lg mt-5 text-center">
                      {schoolData &&
                        (schoolData.jhs_head_number === ""
                          ? "PHONE " + notAvailable
                          : schoolData.jhs_head_number)}
                    </h3>
                  </div>
                </PageLoader>
              }
            />
          </div>
        )}
      </SlideDown>
      {/* <div><CardContainer child={<div>
        <h3>
          About this school
        </h3>
      </div>}/></div> */}
    </div>
  );
};

export default SchoolPage;
