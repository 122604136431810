import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationContext from "../../context/NavigationContext";
import { FaRegUserCircle } from "react-icons/fa";
import Constants from "../../util/Constants";
import AuthContext from "../../context/AuthContext";
import { toast } from "react-toastify";
import { BsPrinter } from "react-icons/bs";
import { HiMenuAlt2, HiMenuAlt3 } from "react-icons/hi";
import SideBar from "./SideBar";

const TitleBar = () => {
  const location = useLocation();
  const navigationContext = useContext(NavigationContext);
  const authContext = useContext(AuthContext);
  const date = new Date().toDateString();
  const pathname = location.pathname;
  const navigate = useNavigate();

  const handleLogout = () => {
    toast.promise(
      fetch(Constants.BaseProxyUrl + "/logout", {
        method: "GET",
        credentials: "include",
      })
        .then((res) => {
          if (!res.ok) {
            throw Error("An error occurred, please try again later");
          }
          return res.json();
        })
        .then((res) => {
          console.log(res);
          setTimeout(() => {
            window.location.reload();
          }, 1200);
        }),
      {
        pending: "Leaving already?, please wait...",
        success: "Logout Successful 😊",
        error: "An error occurred, please try later 😟",
      },
      {
        position: "top-center",
      }
    );
  };

  useEffect(() => {
    navigationContext.setDrawerLeftContent(<SideBar isForNavBar={true} />);
    navigationContext.setDrawerLeftPosition("left");
    navigationContext.setDrawerLeftStyle("bg-black h-full");
    if (pathname) {
      const color = navigationContext.navData.color;
      if (pathname.startsWith("/home")) {
        navigationContext.setNavData({ color: color, title: "Home" });
      } else if (pathname.startsWith("/agencies")) {
        navigationContext.setNavData({ color: color, title: "Agencies" });
      } else if (pathname.startsWith("/summary")) {
        navigationContext.setNavData({ color: color, title: "Summary" });
      } else if (pathname.includes("/indicators")) {
        navigationContext.setNavData({ color: color, title: "Indicators" });
      }else if (pathname.startsWith("/masterFacility/schoolDetails")) {
        navigationContext.setNavData({
          color: color,
          title: "School Data",
        });
      } 
      else if (pathname.startsWith("/master")) {
        navigationContext.setNavData({
          color: color,
          title: "Master Facility (School Data)",
        });
      } else if (pathname.startsWith("/indicatorManual")) {
        navigationContext.setNavData({
          color: color,
          title: "Indicator Manual",
        });
      } else if (pathname.startsWith("/admin")) {
        navigationContext.setNavData({ color: color, title: "Admin" });
      } else if (pathname.startsWith("/dataDictionary")) {
        navigationContext.setNavData({
          color: color,
          title: "Data Dictionary",
        });
      } else if (pathname.startsWith("/intro")) {
        navigationContext.setNavData({
          color: color,
          title: "Dashboard Introduction",
        });
      }
    }
  }, [pathname]);

  const onLeftMenuClick = () => {
    navigationContext.toggleLeftDrawer();
  };

  const onRightMenuClick = () => {
    navigationContext.toggleRightDrawer();
  };

  return (
    location.pathname !== "/login" &&
    location.pathname !== "/" && (
      <div
        className={
          navigationContext.navData.color +
          " items-center w-full h-12 flex fixed top-0 pl-2 py-2 md:pr-20 lg:pr-20 xl:pr-20 2x:pr-20 pr-2  space-x-5 justify-between text-white"
        }
      >
        <div className="flex items-center">
          <h3 className="hidden md:block lg:block xl:block 2xl:block">
            {date} &nbsp;|
          </h3>
          <HiMenuAlt2
            className="w-7 h-7 cursor-pointer md:hidden lg:hidden xl:hidden 2xl:hidden mr-2"
            onClick={onLeftMenuClick}
            color="white"
          />
          <div
            onClick={() => {
              window.print();
            }}
            className="flex cursor-pointer space-x-2 items-center h-7 text-white px-2"
          >
            <BsPrinter className="w-5 h-5" color="white" />
            <p className="hidden md:block lg:block xl:block 2xl:block text-md">
              {" "}
              Print Page
            </p>
          </div>
        </div>
        <h3 className="font-bold text-xl md:text-2xl lg:text-2xl xl:text-2xl 2xl:text-2xl">
          {navigationContext.navData.title}
        </h3>
        <div className="flex space-x-2 items-center">
          <FaRegUserCircle className="w-8 h-8 hidden md:block lg:block xl:block 2xl:block" />
          <div className="flex flex-col items-start">
            <h3 className="hidden md:block lg:block xl:block 2xl:block">
              {authContext.userInfo ? authContext.userInfo.fullName : ""}
            </h3>
            <button onClick={handleLogout} className="text-xs">
              Logout
            </button>
          </div>
          <HiMenuAlt3
            className="w-7 h-7 cursor-pointer md:hidden lg:hidden xl:hidden 2xl:hidden mr-2"
            onClick={onRightMenuClick}
            color="white"
          />
        </div>
      </div>
    )
  );
};

export default TitleBar;
