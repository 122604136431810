import useFetch from "../../../../util/useFetch";
import Carousel from "nuka-carousel";
import Constants from "../../../../util/Constants";

const CarouselWrapper = () => {
  const {
    data: messageList,
    isLoading,
    error,
  } = useFetch("/messages_of_the_day", {
    method: "GET",
  },Constants.ApiCallerCarousel);

  // if(messageList){
  //   console.log(messageList);
  // }

  return (
    <>
      {isLoading && <div className="text-white p-8">Loading...</div>}
      {error && (
        <p className="text-white mt-5">
          An error occurred please try again later{" "}
        </p>
      )}
      {messageList && (
        <div className="w-full">
          <h3 className="text-green-500 mt-10 text-xl font-semibold text-center">
            Message of the day
          </h3>
          <Carousel
            autoplay={true}
            // framePadding={10}
            withoutControls={true}
            swiping={true}
            pauseOnHover={true}
            autoplayInterval={6000}
            // renderCenterLeftControls={({ previousSlide }) => <></>}
            // renderCenterRightControls={({ previousSlide }) => <></>}
          >
            {messageList.map((message,index) => (
              <div key={index} className="px-2">
                <p className="w-full italic text-white">{message.message}</p>
                <h2 className="text-white mt-2 text-sm font-bold">
                  {message.senderName}
                </h2>
              </div>
            ))}

            {/* <div>
              <p className="mt-2 w-full italic text-white">
                Hello Testing the message of the day so hang on
              </p>
              <h2 className="text-white mt-2 text-sm font-bold">CENDLOS</h2>
            </div>

            <div>
              <p className="mt-2 w-full italic text-white">
                Hello Testing the message of the day so hang on
              </p>
              <h2 className="text-white mt-2 text-sm font-bold">CENDLOS</h2>
            </div> */}
          </Carousel>
        </div>
      )}
    </>
  );
};

export default CarouselWrapper;
