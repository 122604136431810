import agencies from "../../assets/images/carousel_agencies.jpg";
import indicatorManual from "../../assets/images/carousel_indicator_manual.jpg";
import indicators from "../../assets/images/carousel_indicators.jpg";
import schoolData from "../../assets/images/carousel_school_data.jpg";
import summary from "../../assets/images/carousel_summary.jpg";
import dataDictionary from "../../assets/images/carousel_data_dictionary.jpg";

import {useState} from 'react';
import Carousel from "nuka-carousel";
import { useNavigate } from "react-router-dom";



export const Intro = () => {
  const itemList = [summary,indicators,agencies,schoolData, indicatorManual,dataDictionary];
  const navigate = useNavigate();
  const navigateToSummary = () => {
    navigate("/home");
  };
  const [autoPlayReverse, setAutoPlayReverse] = useState(false);

  const afterSlide = (currentSlide)=>{
    if((currentSlide+1) === itemList.length){
      setAutoPlayReverse(true);
    }
    if(currentSlide === 0){
      setAutoPlayReverse(false);
    } 
  }

  return (
    <div className="w-screen h-screen pl-10 pt-3">
      <h3 className="text-center p-2 text-xl font-bold">
        Welcome, please take a moment to familiarize yourself with the
        dashboard.
      </h3>
      <div className="h-full flex">
        <button onClick={navigateToSummary} className="w-32 font-bold text-white bg-green-500 rounded-tl-xl rounded-bl-xl h-10 text-center">
          SKIP
        </button>
        <Carousel
          height="85%"
          width="85%"
          autoplay={true}
          pauseOnHover={true}
          autoplayReverse={autoPlayReverse}
          afterSlide={afterSlide}
          swiping={true}
          autoplayInterval={7000}
        >
          {itemList.map((car, index) => (
            <img src={car} alt="Carousel" key={index}></img>
          ))}
        </Carousel>
     
      </div>
    </div>
  );
};
