import {useState} from 'react'
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from 'react-router-dom';

const SchoolSearchBar = () => {
    const [searchQuery, setSearchQuery] = useState();
    const navigate = useNavigate();
    const location = useLocation();
    const query = new URLSearchParams(location.search);

    const onSubmit = (e) => {
        e.preventDefault();
        if (searchQuery && searchQuery !== "") {
          query.set('search',searchQuery);
          navigate("/admin/schools?" + query.toString());
        }
        
      };

      const onChange = (e) => {
        e.preventDefault();
        if (e.target.value === "") {
          query.delete('search');
          const queryString = query.toString();
          navigate("/admin/schools?"+queryString);
        }
      }

  return (
<form className="mt-1 mb-1 w-80" onSubmit={onSubmit}>
      <input
        className="px-2 py-1.5 w-4/5 rounded-xl bg-white bg-opacity-80 border-black border-2"
        type="text"
        onInput={(e) => setSearchQuery(e.target.value)}
        id="school-search"
        placeholder="Search School (Clear to cancel)"
        name="search"
        onChange={(e) => onChange(e)}
      />
      <button className="bg-black p-2.5 mx-2 rounded-3xl" type="submit">
        <BsSearch color="white" />
      </button>
    </form> );
}

export default SchoolSearchBar