import {BiErrorAlt} from 'react-icons/bi';

const Error = () => {
  return (
    <div className="rounded-lg bg-red-300 h-10 flex p-2 items-center m-2">
      <BiErrorAlt size={25} />
      <p className="text-sm m-2">An error occurred, please refresh the page or try again later.</p>
    </div>
  );
};

export default Error;
