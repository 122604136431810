const SimpleList = ({ itemList = [...Array(30).keys()],onItemClick,titleKey='',titleStyle='' }) => {
    return (
      <div>
        {itemList.map((item,index) => (
          <div key={index} onClick={()=>{
            if(onItemClick)onItemClick(item)
          }}>
            <h3 className={"hover-style p-1 "+titleStyle}>{titleKey === '' ? "Item "+item: item[titleKey]}</h3>
            <div className="h-px w-full bg-gray-400 mt-1"></div>
          </div>
        ))}
      </div>
    );
  };

  export default SimpleList;