import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useLocation } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels,
  Legend
);

const options = {
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart - Stacked',
    // },
    // label: function(item,data){
    //   return data+" %";
    // },
    datalabels: {
      formatter: function (value, context) {
        return value.toLocaleString();
      },
      // labels: {
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "600",
        size: 11,
      },
      // },
    },
    legend: {
      display: false,
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 16,
        },
      },
    },
    // tooltip: {
    //   xAlign: "center",
    //   yAlign: "bottom",
    //   backgroundColor: "#000000",
    //   bodyAlign: "center",
    //   callbacks: {
    //     // beforeBody: (context) => {
    //     //   console.log('before body: ', context)
    //     //   return 'before body'
    //     // },
    //     // title: (context) => {
    //     //   console.log('title context: ', context)
    //     //   return 'title'
    //     // },
    //     // beforeLabel: (context) => {
    //     //   console.log('before label: ', context)
    //     //   return 'before label'
    //     // },
    //     // label: (context) => {
    //     //   return context.dataset.label + ": " + context.raw + "%";
    //     // },
    //   },
    // },

    // legend: {
    //   position: "bottom",
    //   labels: {
    //     color: "rgb(0, 0, 0)",
    //     font: {
    //       family: "Inter",
    //       // weight: "bold",
    //       size: 13,
    //     },
    //   },
    // },
  },
  maintainAspectRatio: true,
  responsive: true,
  scales: {
    x: {
      // stacked: true,
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
        // callback: function (value, index, values) {
        //   return value + "%";
        // },
      },
    },
    y: {
      // stacked: true,
      display: true,
      // title: {
      //   display: true,
      //   text: "PERCENTAGE",
      //   color: "#000",
      // },
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
        // callback: function (value, index, values) {
        //   return value + "%";
        // },
      },
      // padding: { top: 30, left: 0, right: 0, bottom: 0 },
    },
  },
};

const PupilAttendance = ({ data: chartData }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const labels = ["MALE", "FEMALE", "TOTAL"];
  let malesValue = chartData.mainValueBreakdown.find(
    (item) => item.nameofBreakdown === "Males"
  ).value;
  let femalesValue = chartData.mainValueBreakdown.find(
    (item) => item.nameofBreakdown === "Females"
  ).value;

  const data = {
    labels: labels,
    datasets: [
      {
        data: [
          Number(malesValue.replace(/,/g, "")),
          Number(femalesValue.replace(/,/g, "")),
          Number(chartData.mainValue.replace(/,/g, "")),
        ],
        backgroundColor: [
          "rgb(53, 162, 210)",
          "rgb(255, 99, 172)",
          "rgb(60, 150, 29)",
        ],
      },
    ],
  };

  return (
    <div>
      <div>
        <h3 className="text-center text-xl font-bold mb-2">Enrolment</h3>

        <h3 className="text-center text-2xl font-bold mb-2">
          {chartData.mainValue}
        </h3>
        <Bar width={400} height={400} options={options} data={data} />
        <p className="font-semibold text-sm text-center">
          Source: SRIM(EMIS) {query.get('year') ?? ''}
        </p>
      </div>
    </div>
  );
};

export default PupilAttendance;
