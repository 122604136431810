import CardContainer from "../../../CardContainer";
import useFetch from "../../../../util/useFetch";
// import { Chart, Tooltip, Interval, Axis } from "bizcharts";
import PageLoader from "../../../PageLoader";
import Constants from "../../../../util/Constants";
import ChartDataLabels from "chartjs-plugin-datalabels";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useContext, useRef } from "react";
import ScreenSizeContext from "../../../../context/ScreenSizeContext";
import FullScreenModal from "../../../FullScreenModal";
import { AiOutlineFullscreen } from "react-icons/ai";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels,
  Legend
);

const IndicatorBreakDown = ({
  indicatorName,
  indicatorId,
  year,
  term,
  region,
  district,
  circuit,
  school,
}) => {
  var url;
  if (region && !district)
    url = `/regionalKeyIndicators/${indicatorId}/${region.regionID}&year=${year}&term=${term}`;
  else if (district && !circuit)
    url = `/districtKeyIndicators/${indicatorId}/${district.districtId}&year=${year}&term=${term}`;
  else if (circuit && !school)
    url = `/circuitKeyIndicators/${indicatorId}/${circuit.circuitID}&year=${year}&term=${term}`;
  else url = `/nationalKeyIndicators/${indicatorId}&year=${year}&term=${term}`;
  const {
    data: breakdownData,
    isLoading,
    error,
  } = useFetch(
    url,
    {
      method: "GET",
    },
    Constants.ApiCallerIndicatorBreakdown
  );

  const modalRef = useRef();

  return (
    <CardContainer
      child={
        <PageLoader
          showNoDataAsError={true}
          isLoading={isLoading}
          error={error}
          isPage={false}
        >
          <FullScreenModal title={`${indicatorName} Breakdown:`} ref={modalRef}>
            <Body
              isFullScreen={true}
              indicatorName={indicatorName}
              modalRef={modalRef}
              breakdownData={breakdownData}
            />
          </FullScreenModal>
          <Body
            isFullScreen={false}
            indicatorName={indicatorName}
            modalRef={modalRef}
            breakdownData={breakdownData}
          />
        </PageLoader>
      }
    />
  );
};

const Body = ({ isFullScreen, indicatorName, modalRef, breakdownData }) => {
  const screenSize = useContext(ScreenSizeContext);

  return (
    <>
      {breakdownData && (
        <div>
          <div className="w-full h-full flex justify-between">
            {!isFullScreen && (
              <h3 className="title-md mb-5">{indicatorName} Breakdown:</h3>
            )}
            {!isFullScreen && (
              <AiOutlineFullscreen
                size={28}
                className="ml-2 border border-gray-600 rounded-md"
                title="Fullscreen"
                onClick={() => modalRef.current.open()}
              />
            )}
          </div>
          <Bar
            height={isFullScreen ? screenSize.height * 0.94 : 400}
            width={isFullScreen ? screenSize.width * 0.95 : 400}
            options={
              isFullScreen
                ? {
                    ...breakdownData.options,
                    responsive: false,
                    maintainAspectRatio: true,
                    plugins: {
                      ...breakdownData.options.plugins,
                      datalabels: {
                        ...breakdownData.options.plugins.datalabels,
                        font: {
                          ...breakdownData.options.plugins.datalabels.font,
                          size: 40,
                          weight: "bold",
                        },
                      },
                    },
                  }
                : breakdownData.options
            }
            data={breakdownData}
          />
        </div>
      )}
    </>
  );
};

export default IndicatorBreakDown;
// module.exports = options;
