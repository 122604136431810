import { useState, useEffect, useContext } from "react";
import AgencyIndicatorListContext from "../context/AgencyIndicatorListContext";
import Constants from "./Constants";

//local
// const BASE_URL =
//   "http://localhost:5000/?url=https://dev.moedashboardgh.com/backendtwo-0.0.1-SNAPSHOT/api";

//production
const BASE_URL = Constants.BaseUrl;

const useFetch = (path, options = {}, caller = "", isCustomCall = false) => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  // const [allAreZeros, setAllAreZeros] = useState(false);

  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);

  if (!path.includes("&")) {
    path = path + "/";
  }

  useEffect(() => {
    const abortController = new AbortController();
    options.signal = abortController.signal;
    options.credentials = "include";
    options.headers = { "Content-Type": "application/json" };
    setIsLoading(true);
    setData(null);
    setError(null);
    if (
      path === "/indicatorManuals/" ||
      (path === "/retrieve_agencies/" &&
        agencyIndicatorListContext.agencyIndicatorList)
    ) {
      //To avoid fetching from the backend again
      setTimeout(() => {
        switch (caller) {
          case Constants.ApiCallerDashboardIndicatorList:
            const filteredIndicatorList =
              agencyIndicatorListContext.agencyIndicatorList.indicatorList.filter(
                (element) => element.visibleOnDashboard === true
              );
            setData(filteredIndicatorList);
            setIsLoading(false);
            setError(null);
            break;
          case Constants.ApiCallerIndicatorManagementAgencies:
            setData(agencyIndicatorListContext.agencyIndicatorList.agencyList);
            setIsLoading(false);
            setError(null);
            break;
          case Constants.ApiCallerIndicatorManual:
            const filteredList = [];
            const originalAgencyList =
              agencyIndicatorListContext.agencyIndicatorList.agencyList;
            //we filter the list for manual page indicators
            for (let i = 0; i < originalAgencyList.length; i++) {
              const filteredAgencyIndicator = originalAgencyList[
                i
              ].indicators.filter((ind) => ind.showOnManualPage === true);
              filteredList.push({
                ...originalAgencyList[i],
                indicators: filteredAgencyIndicator,
              });
            }
            setData(filteredList);
            setIsLoading(false);
            setError(null);
            break;
          default:
            setData(agencyIndicatorListContext.agencyIndicatorList.agencyList);
            setIsLoading(false);
            setError(null);
            break;
        }
      }, 300);
    } else {
      fetch(isCustomCall ? path : BASE_URL + path, options)
        .then((res) => {
          if (!res.ok) {
            throw Error("Unable to fetch data from that resource");
          }
          return res.json();
        })
        .then(async (resultData) => {
          // visibleOnSummary
          switch (caller) {
            case Constants.ApiCallerCarousel:
              const messageList = [];
              resultData.forEach((element) => {
                element.listOfMessageOfTheDay.forEach((msg) => {
                  const item = {
                    senderName: element.senderName,
                    message: msg.message,
                  };
                  messageList.push(item);
                });
              });
              shuffleArray(messageList);
              setData(messageList);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerGenericIndicators:
              setData(resultData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerTopBottomFive:
              const finalTopBottomData = {};
              const topLabels = resultData.top_5.map(
                (item) => item.districtName
              );
              const bottomLabels = resultData.bottom_5.map(
                (item) => item.districtName
              );
              const topData = {
                labels: topLabels,
                datasets: [
                  {
                    label: "Top",
                    data: resultData.top_5.map((item) =>
                      item.value === "�"
                        ? 0
                        : Number(item.value.replace(/,/g, ""))
                    ),
                    backgroundColor: "rgb(16, 185, 129)",
                  },
                ],
              };
              const bottomData = {
                labels: bottomLabels,
                datasets: [
                  {
                    label: "Bottom",
                    data: resultData.bottom_5.map((item) =>
                      item.value === "�"
                        ? 0
                        : Number(item.value.replace(/,/g, ""))
                    ),
                    backgroundColor: "rgb(239, 68, 68)",
                  },
                ],
              };
              finalTopBottomData["topData"] = topData;
              finalTopBottomData["bottomData"] = bottomData;
              setData(finalTopBottomData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerIndicatorBreakdown:
              const filteredList = resultData.filter(
                (element) =>
                  element.keyIndicatorName !== "PRESCHOOL" &&
                  element.keyIndicatorName !== "SHS"
              );

              const breakDownDatasets = [];
              const breakDownLabels = filteredList.map(
                (item) => item.keyIndicatorName + ": " + item.mainValue
              );
              if (filteredList[0].mainValueBreakdown.length > 0) {
                const breakDownLabelOne =
                  filteredList[0].mainValueBreakdown[0].nameofBreakdown;
                const breakDownLabelTwo =
                  filteredList[0].mainValueBreakdown[1].nameofBreakdown;
                breakDownDatasets.push({
                  label: breakDownLabelOne, //"Males",
                  data: filteredList.map((item) =>
                    Number(item.mainValueBreakdown[0].value.replace(/,/g, ""))
                  ),
                  backgroundColor: getColorSequence(breakDownLabelOne)[0],
                });
                breakDownDatasets.push({
                  label: breakDownLabelTwo, //"Females",
                  data: filteredList.map((item) =>
                    Number(item.mainValueBreakdown[1].value.replace(/,/g, ""))
                  ),
                  backgroundColor: getColorSequence(breakDownLabelOne)[1],
                });
              } else {
                // filteredList.forEach((item,index)=>{
                //   breakDownDatasets.push({
                //     label: item.keyIndicatorName,
                //     data: Number(item.mainValue.replace(/,/g, "")),
                //     backgroundColor: getColorSequence(item.keyIndicatorName)[index]
                //   });
                // });

                breakDownDatasets.push({
                  // label: 'Yolo',
                  data: filteredList.map((item) =>
                    Number(item.mainValue.replace(/,/g, ""))
                  ),
                  backgroundColor: getColorSequence("random"),
                });
              }
              indicatorBreakDownGraphOptions.plugins.legend.display =
                breakDownDatasets[0].hasOwnProperty("label");

              const finalData = {
                labels: breakDownLabels,
                datasets: breakDownDatasets,
                options: indicatorBreakDownGraphOptions,
              };

              if (
                checkAllAreZeros(
                  filteredList.map((item) =>
                    Number(item.mainValue.replace(/,/g, ""))
                  )
                ) ||
                isEmpty(resultData)
              ) {
                setData(null);
              } else {
                setData(finalData);
              }

              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerAgencyLandingPageTripleCircle:
              const agencyLandingPageFilteredList = resultData.filter(
                (element) =>
                  element.keyIndicatorName !== "PRESCHOOL" &&
                  element.keyIndicatorName !== "SHS"
              );
              const kgResult = agencyLandingPageFilteredList.find(
                (item) => item.keyIndicatorName === "KINDERGARTEN"
              );
              const primaryResult = agencyLandingPageFilteredList.find(
                (item) => item.keyIndicatorName === "PRIMARY"
              );
              const jhsResult = agencyLandingPageFilteredList.find(
                (item) => item.keyIndicatorName === "JHS"
              );

              const kgData = {
                labels: kgResult.mainValueBreakdown.map(
                  (item) => item.nameofBreakdown
                ),
                values: kgResult.mainValueBreakdown.map((item) =>
                  Number(item.value.replace(/,/g, ""))
                ),
              };
              const primaryData = {
                labels: primaryResult.mainValueBreakdown.map(
                  (item) => item.nameofBreakdown
                ),
                values: primaryResult.mainValueBreakdown.map((item) =>
                  Number(item.value.replace(/,/g, ""))
                ),
              };
              const jhsData = {
                labels: jhsResult.mainValueBreakdown.map(
                  (item) => item.nameofBreakdown
                ),
                values: jhsResult.mainValueBreakdown.map((item) =>
                  Number(item.value.replace(/,/g, ""))
                ),
              };

              const agencyLandingPageData = {
                kgData: {
                  data: {
                    labels: kgData.labels,
                    datasets: [
                      {
                        data: kgData.values,
                        backgroundColor: getColorSequence(
                          String(kgData.labels)
                        ),
                      },
                    ],
                  },
                  mainValue: kgResult.mainValue,
                },
                jhsData: {
                  data: {
                    labels: jhsData.labels,
                    datasets: [
                      {
                        data: jhsData.values,
                        backgroundColor: getColorSequence(
                          String(jhsData.labels)
                        ),
                      },
                    ],
                  },
                  mainValue: jhsResult.mainValue,
                },
                primaryData: {
                  data: {
                    labels: primaryData.labels,
                    datasets: [
                      {
                        data: primaryData.values,
                        backgroundColor: getColorSequence(
                          String(primaryData.labels)
                        ),
                      },
                    ],
                  },
                  mainValue: primaryResult.mainValue,
                },
              };
              setData(agencyLandingPageData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerSchoolSummaryBarChart:
              const summary = resultData.summary;
              const deprivedLabels = [];
              var deprivedTotal = 0;
              const levelsLabels = [];
              var levelsTotal = 0;
              const publicPrivateLables = [];
              var publicAndPrivateTotal = 0;

              for (let key in summary) {
                if (summary.hasOwnProperty(key)) {
                  if (key !== "Total") {
                    if (key.toLocaleLowerCase().includes("deprived")) {
                      deprivedLabels.push(key.split("_").join(" "));
                      deprivedTotal += Number(summary[key].replace(/,/g, ""));
                    }
                    if (
                      key.toLocaleLowerCase().includes("kg") ||
                      key.toLocaleLowerCase().includes("primary") ||
                      key.toLocaleLowerCase().includes("jhs")
                    ) {
                      levelsLabels.push(key.split("_").join(" "));
                      levelsTotal += Number(summary[key].replace(/,/g, ""));
                    }
                    if (
                      key.toLocaleLowerCase().includes("public") ||
                      key.toLocaleLowerCase().includes("private")
                    ) {
                      publicPrivateLables.push(key.split("_").join(" "));
                      publicAndPrivateTotal += Number(
                        summary[key].replace(/,/g, "")
                      );
                    }
                  }
                }
              }
              const deprivedNonDeprivedData = {
                labels: deprivedLabels,
                datasets: [
                  {
                    data: [
                      Number(summary["Total_Deprived"].replace(/,/g, "")),
                      Number(summary["Total_Non_Deprived"].replace(/,/g, "")),
                    ],
                    backgroundColor: getColorSequence("random"),
                  },
                ],
              };
              const kgPrimaJhsData = {
                labels: levelsLabels,
                datasets: [
                  {
                    data: [
                      Number(summary["Total_With_Primary"].replace(/,/g, "")),
                      Number(summary["Total_With_KG"].replace(/,/g, "")),
                      Number(summary["Total_With_JHS"].replace(/,/g, "")),
                    ],
                    backgroundColor: getColorSequence("random"),
                  },
                ],
              };
              const publicPrivateData = {
                labels: publicPrivateLables,
                datasets: [
                  {
                    data: [
                      Number(summary["Total_Public"].replace(/,/g, "")),
                      Number(summary["Total_Private"].replace(/,/g, "")),
                    ],
                    backgroundColor: getColorSequence("random"),
                  },
                ],
              };

              const finalSummaryData = {
                deprivedNonDeprived: deprivedNonDeprivedData,
                deprivedNonDeprivedTotal: deprivedTotal,
                kgPrimJhs: kgPrimaJhsData,
                kgPrimJhsTotal: levelsTotal,
                publicPrivate: publicPrivateData,
                barOptions: schoolSummaryBarGraphOptions,
                publicPrivateTotal: publicAndPrivateTotal,
                total: summary.Total,
              };
              setData(finalSummaryData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerOverviewPieChart:
              const overViewLabels = resultData.mainValueBreakdown.map(
                (item) => item.nameofBreakdown
              );
              const pieValues = resultData.mainValueBreakdown.map((item) =>
                Number(item.value.replace(/,/g, "").replace("�", "0"))
              );
              const finalOverViewData = {
                data: {
                  labels: overViewLabels,
                  datasets: [
                    {
                      data: pieValues,
                      backgroundColor: getColorSequence(String(overViewLabels)),
                    },
                  ],
                },
                indicatorName: resultData.indicatorname,
                mainValue: resultData.mainValue,
                previousYearValue: resultData.previousYearValue,
              };

              setData(finalOverViewData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerLeaderBoard:
              const isRegion = resultData.isRegion === "true";
              const finalLeaderBoardData = {};
              const leaderBoardDatasets = [];
              const labels = [];
              const dataList = [];
              resultData.current.forEach((item) => {
                if (item) {
                  labels.push(isRegion ? item.districtName : item.regionName);
                  const number = Number(
                    (isRegion ? item.mainValue : item.value).replace(/,/g, "")
                  );
                  dataList.push(isNaN(number) ? 0 : number);
                }
              });
              leaderBoardDatasets.push({
                label: resultData.currentYear + " value", //selected year
                data: dataList,
                //   borderColor: "rgb(255, 99, 132)",
                backgroundColor: "rgb(106, 148, 245)",
              });
              if (resultData.hasOwnProperty("previous")) {
                //add previous year
                leaderBoardDatasets.push({
                  label: resultData.previousYear + " value", //previous year
                  data: resultData.previous.map((item) => {
                    const number = Number(
                      (isRegion ? item.mainValue : item.value).replace(/,/g, "")
                    );
                    return isNaN(number) ? 0 : number;
                  }),
                  //   borderColor: "rgb(255, 99, 132)",
                  backgroundColor: "rgb(98, 218, 172)",
                });
              }
              finalLeaderBoardData["datasets"] = leaderBoardDatasets;
              finalLeaderBoardData["labels"] = labels;
              if (!isRegion) {
                finalLeaderBoardData["availableDataRegions"] =
                  resultData.current;
              } else {
                finalLeaderBoardData["availableDataRegions"] =
                  resultData.current;
              }
              setData(finalLeaderBoardData);
              setError(null);
              setIsLoading(false);
              break;

            case Constants.ApiCallerDistrictsCircuits:
              setData(resultData.circuitList);
              setError(null);
              setIsLoading(false);
              break;

            case Constants.ApiCallerCircuitSchools:
              setData(resultData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerRegionDistricts:
              const finalDistrictList = resultData.districtList.map((item) => ({
                districtName: item.districtName,
                districtID: item.districtID,
              }));
              setData(finalDistrictList);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerNumbersOfTheDay:
              shuffleArray(resultData);
              setData(resultData.slice(0, 2));
              setError(null);
              setIsLoading(false);

              break;
            case Constants.ApiCallerDataDictionary:
              const dictionaryItems = resultData.dictionary_full.sort((a, b) =>
                a.term.localeCompare(b.term)
              );
              setData(dictionaryItems);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerGetSystemsUsers:
              setData(resultData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerSchoolPage:
              var males = 0,
                females = 0,
                total = 0;
              const schoolNumbers = await fetch(
                BASE_URL +
                  "/emis_school_enrolment/" +
                  resultData.emisCode +
                  "?year=2019",
                options
              );
              const schoolNumbersData = await schoolNumbers.json();
              schoolNumbersData.forEach((element) => {
                males += element.Males;
                females += element.Females;
                total += element.Total;
              });
              resultData.totalMales = males;
              resultData.totalFemales = females;
              resultData.totalMalesAndFemales = total;

              setData(resultData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerDashboardIndicatorList:
              const filteredIndicatorList = resultData.filter(
                (element) => element.visibleOnDashboard === true
              );
              setData(filteredIndicatorList);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerIndicatorManagementAgencies:
              const indicatorResponse = await fetch(
                BASE_URL + "/indicatorManuals/",
                options
              );
              var indicatorList;
              if (indicatorResponse.ok) {
                indicatorList = await indicatorResponse.json();
              } else {
                throw Error("An error occurred, please refresh the page");
              }
              for (let i = 0; i < resultData.length; i++) {
                const agencyId = resultData[i].id;
                const filtered = indicatorList.filter(
                  (item) => item.sourceID === agencyId
                );
                resultData[i].indicators = filtered; //visibleOnDashboard
                resultData[i].visibleOnDashboard = filtered.filter(
                  (item) => item.visibleOnDashboard === true
                ).length;
              }
              setData(resultData);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerMSRCSchools:
              const data = resultData.school_minimal;
              setData(data);
              setError(null);
              setIsLoading(false);
              break;
            case Constants.ApiCallerSchoolList:
              const schoolData = resultData.school_minimal;
              setData(schoolData);
              setError(null);
              setIsLoading(false);
              break;
            // case Constants.ApiCallerMSRCIndicators:
            //   console.log(resultData);
            //   setData(resultData);
            //   setError(null);
            //   setIsLoading(false);
            //   break;
            default:
              setData(resultData);
              setError(null);
              setIsLoading(false);
              break;
          }
        })
        .catch((err) => {
          if (err.name !== "AbortError") {
            setError(err.message);
            setIsLoading(false);
            setData(null);
          }
        });
    }
    //stops fetching when the component unmounts equivalent of setState called after dispose
    return () => abortController.abort();
  }, [path]);

  return { data, isLoading, error };
};

function getColorSequence(labels) {
  if (String(labels).includes("Male") || String(labels).includes("Female")) {
    return backgroundColor;
  } else {
    return reversedColors;
  }
}

function checkAllAreZeros(values) {
  for (var i = 0; i < values.length; i++) {
    if (values[i] > 0) return false;
    else return true;
  }
}

//background colors for rechart js 2
const backgroundColor = [
  "rgb(54, 162, 235)", //males
  "rgb(255, 99, 132)", //females
  "rgb(255, 206, 86)",
  "rgb(75, 192, 192)",
  "rgb(153, 102, 255)",
  "rgb(255, 159, 64)",
];

const reversedColors = [
  "rgb(255, 159, 64)",
  "rgb(153, 102, 255)",
  "rgb(75, 192, 192)",
  "rgb(255, 206, 86)",
  "rgb(255, 99, 132)",
  "rgb(54, 162, 235)",
];

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
}

function isEmpty(data) {
  if (Array.isArray(data)) {
    return data.length === 0;
  } else {
    return Object.keys(data).length === 0;
  }
}

const indicatorBreakDownGraphOptions = {
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart - Stacked',
    // },
    // label: function(item,data){
    //   return data+" %";
    // },
    datalabels: {
      formatter: function (value, context) {
        // return
        // context.dataset.label
        //   ? context.dataset.label + ":\n" +
        return value.toLocaleString();
        // : "";
      },
      // labels: {
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "500",
        size: 11,
      },
      // },
    },
    legend: {
      // display: false,
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 16,
        },
      },
    },
    // tooltip: {
    //   xAlign: "center",
    //   yAlign: "bottom",
    //   backgroundColor: "#000000",
    //   bodyAlign: "center",
    //   callbacks: {
    //     // beforeBody: (context) => {
    //     //   console.log('before body: ', context)
    //     //   return 'before body'
    //     // },
    //     // title: (context) => {
    //     //   console.log('title context: ', context)
    //     //   return 'title'
    //     // },
    //     // beforeLabel: (context) => {
    //     //   console.log('before label: ', context)
    //     //   return 'before label'
    //     // },
    //     // label: (context) => {
    //     //   return context.dataset.label + ": " + context.raw + "%";
    //     // },
    //   },
    // },

    // legend: {
    //   position: "bottom",
    //   labels: {
    //     color: "rgb(0, 0, 0)",
    //     font: {
    //       family: "Inter",
    //       // weight: "bold",
    //       size: 13,
    //     },
    //   },
    // },
  },
  maintainAspectRatio: true,
  responsive: true,
  // barPercentage: 1,
  scales: {
    x: {
      // stacked: true,
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
        // callback: function (value, index, values) {
        //   return value + "%";
        // },
      },
    },
    y: {
      // stacked: true,
      display: true,
      // title: {
      //   display: true,
      //   text: "PERCENTAGE",
      //   color: "#000",
      // },
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
        // callback: function (value, index, values) {
        //   return value + "%";
        // },
      },
      // padding: { top: 30, left: 0, right: 0, bottom: 0 },
    },
  },
};

const schoolSummaryBarGraphOptions = {
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart - Stacked',
    // },
    // label: function(item,data){
    //   return data+" %";
    // },
    datalabels: {
      formatter: function (value, context) {
        // return
        // context.dataset.label
        //   ? context.dataset.label + ":\n" +
        return value.toLocaleString();
        // : "";
      },
      // labels: {
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "600",
        size: 16,
      },
      // },
    },
    legend: {
      display: false,
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 16,
        },
      },
    },
    // tooltip: {
    //   xAlign: "center",
    //   yAlign: "bottom",
    //   backgroundColor: "#000000",
    //   bodyAlign: "center",
    //   callbacks: {
    //     // beforeBody: (context) => {
    //     //   console.log('before body: ', context)
    //     //   return 'before body'
    //     // },
    //     // title: (context) => {
    //     //   console.log('title context: ', context)
    //     //   return 'title'
    //     // },
    //     // beforeLabel: (context) => {
    //     //   console.log('before label: ', context)
    //     //   return 'before label'
    //     // },
    //     // label: (context) => {
    //     //   return context.dataset.label + ": " + context.raw + "%";
    //     // },
    //   },
    // },

    // legend: {
    //   position: "bottom",
    //   labels: {
    //     color: "rgb(0, 0, 0)",
    //     font: {
    //       family: "Inter",
    //       // weight: "bold",
    //       size: 13,
    //     },
    //   },
    // },
  },
  maintainAspectRatio: true,
  responsive: true,
  // barPercentage: 1,
  scales: {
    x: {
      // stacked: true,
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
        // callback: function (value, index, values) {
        //   return value + "%";
        // },
      },
    },
    y: {
      // stacked: true,
      display: true,
      // title: {
      //   display: true,
      //   text: "PERCENTAGE",
      //   color: "#000",
      // },
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
        // callback: function (value, index, values) {
        //   return value + "%";
        // },
      },
      // padding: { top: 30, left: 0, right: 0, bottom: 0 },
    },
  },
};

export default useFetch;
