// import {useContext} from "react";
// import AgencyIndicatorListContext from "../../../context/AgencyIndicatorListContext";
// import AuthContext from "../../../context/AuthContext";

class UserAccessUtil {
  static canNotGoToUserManagement(userInfo, agencyNameList) {
    return (
      (!userInfo.sysAdmin &&
        String(userInfo.divisionalLevel).toLowerCase() !==
          "ministry of education" &&
        userInfo.userRole !== "admin") ||
      (!userInfo.sysAdmin &&
        String(userInfo.divisionalLevel).toLowerCase() ===
          "ministry of education" &&
        userInfo.userRole !== "admin") ||
      (!userInfo.sysAdmin && !this.isAgencyAdmin(userInfo, agencyNameList))
    );
  }

  static canGoToIndicatorManagement(userInfo, agencyNameList) {
    return (
      this.isSystemAdminOrMOEAdmin(userInfo) ||
      this.isAgencyAdmin(userInfo, agencyNameList)
    );
  }

  static isSystemAdminOrMOEAdmin(userInfo) {
    return (
      userInfo.sysAdmin ||
      (String(userInfo.divisionalLevel).toLowerCase() ===
        "ministry of education" &&
        userInfo.userRole === "admin")
    );
  }

  static isAgencyAdmin(userInfo, agencyNameList) {
    return (
      agencyNameList.includes(String(userInfo.agency).toLowerCase()) &&
      userInfo.userRole === "admin"
    );
  }

  static canGotoSchoolManagement(userInfo) {
    return (
      this.isSystemAdminOrMOEAdmin(userInfo) ||
      (String(userInfo.agency).toLowerCase() === "srim(emis)" &&
        userInfo.userRole === "admin")
    );
  }

  static canGoToDataDictionaryManagement(userInfo, agencyNameList) {
    return (
      this.isSystemAdminOrMOEAdmin(userInfo) ||
      this.isAgencyAdmin(userInfo, agencyNameList)
    );
  }

  static canGoToAgencyManagement(userInfo, agencyNameList) {
    return (
      this.isSystemAdminOrMOEAdmin(userInfo) ||
      this.isAgencyAdmin(userInfo, agencyNameList)
    );
  }
}

export default UserAccessUtil;
