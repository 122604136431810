import logo from "../assets/images/moe_logo_medium.png";

const NoData = ({details,notApplicable = false,useBlackColor = false}) => {
    return ( notApplicable ? <div></div>:
        <div title="Sorry, no information to display" className="flex flex-col w-full justify-center h-80 items-center p-20">
            <img src={logo} alt="coat_of_arms" width={100} height={100}   style={{ filter: "grayscale(100%)" }}></img>
              {/* <GiCardboardBox size={90} color={useBlackColor ? "black":"gray"}/> */}
              {/* <p className={(useBlackColor ? "text-black" :"text-gray-500")+" mt-1"}>{details ? details : "No Data Found"}</p> */}
            </div>
    )
}

export default NoData
