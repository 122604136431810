import React from "react";
import Spinner from "../Spinner/Spinner";
import Error from "./Error";
import NoData from "./NoData";

const PageLoader = ({ isLoading, error,showNoDataAsError = false, children,isPage = true,className}) => {
  return isLoading || error ? (
    <div className={(isPage ? "w-screen h-screen" : className ?? "p-5" )+" flex flex-col items-center justify-center"}>
      {error && (showNoDataAsError ? <NoData/>: <Error />) }
      {isLoading && <Spinner />}
    </div>
  ) : (
    <div className={className ?? ""}>{children ? children : <NoData/>}</div>
  );
};

export default PageLoader;
