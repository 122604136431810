import { useParams,useNavigate } from "react-router-dom";

import React, { useContext } from "react";
import IndicatorManual from "../indicator_manual/IndicatorManual";
import NavigationContext from "../../../context/NavigationContext";

const AgencyIndicators = () => {
//   const {
//     data: indicatorList,
//     isLoading,
//     error,
//   } = useFetch(
//     "/indicatorManuals",
//     {
//       method: "GET",
//     },
//     false
//   );

  const navigate = useNavigate();
  const params = useParams();
  const navigationContext = useContext(NavigationContext);

  const onBackClicked = () =>{
    navigationContext.setNavData({color:"bg-nav-national",title:"Agencies"});
    navigate("/agencies");
  }

  return (
    <div className="w-screen h-screen">
        {/* Details Here, */}
        <div className="pr-14">
          {/* <button className="rounded bg-green-500 text-white">Back</button> */}
        <IndicatorManual onBackClick={onBackClicked} selectedAgencyId={params.id} initialExpand={false}/>

        </div>
        {/* Selected Agency indicators to display, trend analysis or performance (basically graphs for selected agency's indicators) */}
    </div>
  );
};

export default AgencyIndicators;
