import { useContext, useEffect, useState } from "react";
import moeLogoMedium from "../../../assets/images/moe_logo_medium.png";
import "./css/Login.css";
import CarouselWrapper from "./components/Carousel";
import boy from "../../../assets/images/boy.png";
import girl from "../../../assets/images/girl.png";
import LoginSummaryCard from "./components/LoginSummaryCard";
import AuthContext from "../../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Constants from "../../../util/Constants";
import ResetPassword from "./components/ResetPassword";
import AgencyIndicatorListContext from "../../../context/AgencyIndicatorListContext";
import {AiFillEye,AiFillEyeInvisible} from 'react-icons/ai';

const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const Login = () => {
  const authContext = useContext(AuthContext);
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  let modal = document.getElementById("reset-password-modal");

  useEffect(() => {
    if (authContext.isAuth) {
      navigate("/home");
    }
  });

  function validateForm() {
    // return true;
    return email.length > 0 && password.length > 0 && isValidEmail(email);
  }

  const handleForgotPass = (event) => {
    event.preventDefault();
    modal.style.display = "block"; 
  };

  function onCancel() {
    modal.style.display = "none";
  }

  const handleLogin = (event) => {
    event.preventDefault();
    if (!validateForm()) {
      toast.info("Please enter a valid email & password 🙏", {
        position: "top-center",
      });
    } else {
      setIsLoading(true);
      toast.promise(
        fetch(Constants.BaseProxyUrl + "/login", {
          credentials: "include",
          method: "POST",
          body: JSON.stringify({ emailId: email, password: password }),
          headers: { "Content-Type": "application/json" },
        }).then((res) => {
            if (!res.ok) {
              setIsLoading(false);
              throw Error("An error occurred during login, please try again later");
            }
            return res.json();
          })
          .then(async(result) => {            
            //fetch all agencies and indicators once to be used in the app instead of fetching them on almost every page
            const agencyResponse = await fetch(
              Constants.BaseUrl  + "/retrieve_agencies/",{
              credentials: "include",});

              const indicatorResponse = await fetch(
                Constants.BaseUrl  + "/indicatorManuals/",{
                credentials: "include",});

              if(agencyResponse.ok){
                const agencyList = await agencyResponse.json();
                const indicatorList = await indicatorResponse.json();
                for (let i = 0; i < agencyList.length; i++) {
                  const agencyId = agencyList[i].id;
                  const filtered = indicatorList.filter(
                    (item) => item.sourceID === agencyId
                  );
                  agencyList[i].indicators = filtered; 
                  //visibleOnDashboard Count
                  agencyList[i].visibleOnDashboardCount = filtered.filter(
                    (item) => item.visibleOnDashboard === true
                  ).length;
                }
                const finalResult = {
                  agencyList: agencyList,
                  indicatorList: indicatorList,
                }
                agencyIndicatorListContext.setAgencyIndicatorList(finalResult);
              }
              setIsLoading(false);
            // setTimeout(() => {
              authContext.setIsAuth(true);
              authContext.setUserInfo(result);
            // }, 1200);
          }),
        {
          pending: "Logging you in, please wait...",
          success: "Login Successful 😊",
          error: "Wrong email or password 😟",
        },
        {
          position: "top-center",
        }
      );
    }
  };

  const togglePasswordVisibility = ()=>{
    setPasswordShown((prevState)=>!prevState);
  }

  return (
    <div className="bg">
      <div className="flex w-screen h-screen justify-between">
        <div >
          <div className="hidden lg:flex md:flex p-5 text-white max-w-md bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg m-5 rounded-lg">
            <img
              src={moeLogoMedium}
              width={100}
              height={100}
              // className="pt-5"
              alt="logo"
            />
            <div className="flex flex-col items-start pl-2">
              <h2 className="font-bold text-xl">MINISTRY OF EDUCATION</h2>
              <h3 className="font-semibold text-sm">
                ACCOUNTABILITY AND PERFORMANCE <br />
                MONITORING DASHBOARD
              </h3>
            </div>
          </div>

          <div className="hidden lg:block md:block h-screen p-5  mb-5">
            <LoginSummaryCard />
          </div>
        </div>

        <div className="w-full lg:w-1/3 md:w-1/3  bg-black bg-opacity-50 backdrop-filter backdrop-blur-lg h-screen p-5 overflow-y-auto flex flex-col items-center">
          <div className="lg:hidden md:hidden flex">
            <img
              src={moeLogoMedium}
              width={100}
              height={100}
              // className="pt-5"
              alt="logo"
            />
            <div className="flex flex-col items-start pl-2">
              <h2 className="font-bold text-xl py-1 text-white">
                MINISTRY OF EDUCATION
              </h2>
              <h3 className="font-semibold text-white text-sm">
                ACCOUNTABILITY AND PERFORMANCE MONITORING DASHBOARD
              </h3>
            </div>
          </div>
          <h2 className="hidden lg:block md:block font-bold text-xl text-white">
            WELCOME
          </h2>
          <form className="w-full" onSubmit={handleLogin}>
            <input
              className="ring-1 ring-gray-300 w-full mt-8 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              type="email"
              name="emailId"
              placeholder={"E-mail"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="flex mt-3 ring-1 ring-gray-300 w-full items-center bg-white rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500">
            <input
              className="w-full py-2 px-3 rounded-lg"
              type={passwordShown ? "text" : "password"}
              name="password"
              autoComplete="on"
              placeholder={"Password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {!passwordShown && <AiFillEye className="w-6 h-6 mr-1" color="grey" onClick={togglePasswordVisibility}/>}
            {passwordShown && <AiFillEyeInvisible className="w-6 h-6 mr-1" color="grey" onClick={togglePasswordVisibility}/>}
            </div>

            {!isLoading && (
              <div className="flex mt-3 justify-between space-x-10 w-full">
                <button
                  type="submit"
                  className="bg-green-500 px-5 py-2 w-full rounded-lg text-white"
                >
                  Login
                </button>
                <button
                  onClick={handleForgotPass}
                  className="bg-black px-5 py-2 w-full rounded-lg text-white"
                >
                  Forgot Password
                </button>
              </div>
            )}
          </form>

          <CarouselWrapper />
          <div className="flex mt-5 justify-center space-x-14 w-full items-center">
            <div className="flex items-center">
              <img src={boy} width={85} height={85} alt="boy" />
              <h3 className="text-green-500 text-xl font-bold">Male</h3>
            </div>
            <div className="flex items-center">
              <img src={girl} width={83} height={83} alt="girl" />
              <h3 className="text-green-500 text-xl font-bold ml-3">Female</h3>
            </div>
          </div>

          <h3 className="mt-8 text-xl font-semibold text-center text-white">
            Navigation
          </h3>
          <h3 className="w-full bg-nav-national rounded-lg mt-2 border border-white text-white px-5 py-1.5 text-center">
            BLACK BANNER - NATIONAL LEVEL
          </h3>
          <h3 className="w-full bg-nav-regional rounded-lg border border-white mt-2 text-white px-5 py-1.5 text-center">
            GREEN BANNER - REGIONAL LEVEL
          </h3>
          <h3 className="w-full bg-nav-district rounded-lg border border-white mt-2 text-white px-5 py-1.5 text-center">
            BLUE BANNER - DISTRICT LEVEL
          </h3>
          <h3 className="w-full bg-nav-circuit rounded-lg border border-white mt-2 text-white px-5 py-1.5 text-center">
            GOLD BANNER - CIRCUIT LEVEL
          </h3>
          <h3 className="w-full bg-nav-school rounded-lg border border-white mt-2 text-white px-5 py-1.5 text-center">
            RED BANNER - SCHOOL LEVEL
          </h3>

          <div className="lg:hidden md:hidden">
            <LoginSummaryCard />
          </div>

          <p className="text-sm mt-5 text-center w-full text-white">
            Designed & developed by OeSD
          </p>
        </div>
      </div>
      <div
        className="fixed hidden inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
        id="reset-password-modal"
      >
        <ResetPassword onCancel={onCancel}/>
      </div>
    </div>
  );
};

export default Login;
