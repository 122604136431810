import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import logos from "../../../../../src/util/logos";

const IntroModal = () => {
  const navigate = useNavigate();

  const [dontShow, setDontShow] = useState(false);

  let modal = document.getElementById("intro-modal");
  function hideModal() {
    modal.style.display = "none";
  }

  function showModal() {
    modal.style.display = "block";
  }

  function onNoThanks() {
      hideModal();
      if(dontShow)
      localStorage.setItem('show-intro',false);
  }

  function onSure() {
    if(dontShow)
    localStorage.setItem('show-intro',false);
    navigate("/intro");
  }

  return (
    <div
      className="fixed hidden inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="intro-modal"
    >
      <div className="relative top-20 p-5 border md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 md:mx-auto lg:mx-auto xl:mx-auto 2xl:mx-auto mx-4 shadow-lg rounded-xl bg-white">
        <div className="flex justify-start">
          <h3 className="font-extrabold text-4xl">Hi, there👋!</h3>
          {/* <button
            type="button"
            onClick={() => hideModal()}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button> */}
        </div>
        <div className="mt-5 relative w-full flex justify-center flex-col items-center">
          {/* <img
            className="flex-none md:w-1/2"
            // width="50%"
            // height="50%"
            // style={{
            //   backgroundColor: "white",
            //   marginTop: 50,
            //   padding: 10,
            //   borderRadius: 10,
            // }}
            src={noIndicatorSvg}
          /> */}

          <img
            src={logos[1]}
            className="absolute w-4/6 object-cover"
            style={{ filter: "grayscale(100%)", opacity: ".2" }}
          ></img>

          <p className="font-semibold relative text-lg">
            Welcome to the Performance Management and Data Visualization (PMDV)
            Dashboard of The Ministry of Education. The PMDV dashboard
            visualises educational data, statistics and performance from
            selected Ministry Agencies and systems.
            <br />
            <br />
            Data and information from Agencies such as Statistics Research,
            and Information Management (SRIM), National Teaching Council (NTC),
            National Council for Curriculum and Assessment (NaCCA), and National
            Schools Inspectorate Authority (NaSIA), are visulaised on the PMDV.
            <br />
            <br />
            The Vision for the PMDV Dashboard is to serve as a centralised,
            interactive means of monitoring, measuring and analysing selected
            educational data. The intuitive and visual structure of the PMDV is
            intended to encourage insights, ideas and recommendations in key
            policy areas. This centralised tracking and monitoring of
            educational indicators is to enable the MoE and stakeholders track
            improvements in the delivery of quality education. The PMDV
            Dashboard was funded by BigWin Philanthropy.
            <br/>
            <br/>
            As we strive to improve the Learner outcomes, I trust you can utilise the data on this dashboard system to track performance, monitor progress, target interventions and make evidence informed decision making. 
          </p>

          <div className="w-full">
            <p className="text-right">
              Dr. Yaw Osei Adutwum,
              <br />
              Honourable Minister for Education
              <br />
              Republic of Ghana
            </p>
          </div>
        </div>
        <div className="flex justify-between mt-5">
          <div className="flex space-x-2 items-center">
            <input
              type="checkbox"
              name="do-not-show-checkbox"
              id="do-not-show-checkbox"
              onChange={(e) => setDontShow(e.target.checked)}
            />
            <label
              htmlFor="do-not-show-checkbox"
              className="text-gray-600 text-sm"
            >
              Don't show this again
            </label>
          </div>

          <div className="flex space-x-3 items-center">
            <p className="font-semibold">Would you like a quick intro?</p>
            <button
              onClick={() => onNoThanks()}
              className="bg-black rounded-md px-4 py-1 text-white"
            >
              No thanks
            </button>
            <button
              onClick={() => onSure()}
              className="bg-green-500 rounded-md px-4 py-1 text-white"
            >
              Sure
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntroModal;
