import React, { memo } from "react";
import { useLocation } from "react-router-dom";
import Constants from "../../../../util/Constants";
import CardContainer from "../../../CardContainer";
import PageLoader from "../../../PageLoader";
import ChartDataLabels from "chartjs-plugin-datalabels";
import useFetch from "../../../../util/useFetch";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar,Pie } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels,
  Legend
);

const pieOPtions = {
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        // console.log('formatter here,,...', value);
        return value.toLocaleString();
      },
      // labels: {
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "bold",
        size: 22,
      },
      // },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 16,
        },
      },
    },
  },
  maintainAspectRatio: true,
  responsive: true,
  rotation: 180,
};

const SchoolSummaryBarChart = memo(({ height, tempDistrict, tempRegion }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const regionID = query.get("regionID");
  const districtID = query.get("districtID");
  const circuitID = query.get("circuitID");

  var url = "/schoolManuals/summary&only_msrc=false";
  if (regionID && regionID !== "") {
    url += "&region=" + regionID;
    if (tempDistrict) {
      url += "&district=" + tempDistrict.districtID;
    }
  } else if (districtID && districtID !== "") {
    url += "&district=" + districtID;
    if (tempRegion) {
      url += "&region=" + tempRegion.regionID;
    }
  } else if (circuitID && circuitID !== "") {
    url += "&circuit=" + circuitID;
    if (tempRegion) {
      url += "&region=" + tempRegion.regionID;
    }
    if (tempDistrict) {
      url += "&district=" + tempDistrict.districtID;
    }
  }

  const {
    data: summaryInfo,
    isLoading,
    error,
  } = useFetch(
    url,
    {
      method: "GET",
    },
    Constants.ApiCallerSchoolSummaryBarChart
  );

  return (
    <div className="w-full">
      <CardContainer
        child={
          <div>
            <PageLoader isLoading={isLoading} isPage={false} error={error}>
              {summaryInfo && (
                <div>
                  <h3 className="title-md mb-5">KG, Primary & JHS </h3>
                  <Bar
                    options={summaryInfo.barOptions}
                    data={summaryInfo.kgPrimJhs}
                  />
                  <p className="font-semibold text-sm text-center">
                    Source: SRIM(EMIS) 2019 *Includes Multi-Level Schools
                  </p>
                </div>
              )}
            </PageLoader>
          </div>
        }
      />
      <div className="grid w-full mt-3 grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-3 pb-20">
        <CardContainer
          child={
            <PageLoader isLoading={isLoading} isPage={false} error={error}>
              {summaryInfo && (
                <div className="w-full flex flex-col">
                  <h3 className="title-md mb-2">Public & Private</h3>
                  <Pie
                    data={summaryInfo.publicPrivate}
                    options={pieOPtions}
                  />
                  <p className="font-semibold text-sm text-center">
                    Source: SRIM(EMIS) 2019
                  </p>
                </div>
              )}
            </PageLoader>
          }
        />
        <CardContainer
          child={
            <PageLoader isLoading={isLoading} isPage={false} error={error}>
              {summaryInfo && (
                <div className="w-full flex flex-col">
                  <h3 className="title-md mb-2">
                    Deprived & Non Deprived:{" "}
                    {summaryInfo.deprivedNonDeprivedTotal}
                  </h3>
                  <Pie
                    data={summaryInfo.deprivedNonDeprived}
                    options={pieOPtions}
                  />
                  <p className="font-semibold text-sm text-center">
                    Source: SRIM(EMIS) 2019
                  </p>
                </div>
              )}
            </PageLoader>
          }
        />
      </div>
    </div>
  );
});

export default SchoolSummaryBarChart;
