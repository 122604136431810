import { React, useContext, useState, useEffect } from "react";
import CardContainer from "../../CardContainer";
import { FaRegUserCircle } from "react-icons/fa";
import { FaSave } from "react-icons/fa";
import AuthContext from "../../../context/AuthContext";
import { toast } from "react-toastify";
import Constants from "../../../util/Constants";
import Spinner from "../../../Spinner/Spinner";

const GeneralSettings = () => {
  const authContext = useContext(AuthContext);
  const userInfo = authContext.userInfo;
  // console.log(userInfo);

  const [isLoading, setIsLoading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [fullName, setFullName] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  useEffect(() => {
    if (userInfo) {
      setFullName(userInfo.fullName);
      setPhoneNumber(userInfo.phoneNo);
    }
  }, [userInfo]);

  const handleSave = (event) => {
    event.preventDefault();
    if (password && password.length < 8) {
      toastError("Password must be 8 characters or above");
    } else if (password && confirmPassword && confirmPassword !== password) {
      toastError("Passwords do not match");
    } else {
      const updatedUser = {
        ...userInfo,
        fullName: fullName,
        phoneNo: phoneNumber,
        password: password
      };
      setIsLoading(true);
      fetch(Constants.BaseUrl + "/save_system_user/", {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(updatedUser),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          setIsLoading(false);
          if (!res.ok) {
            throw Error(
              "An error occurred while saving, please try again later"
            );
          }
          return res.json();
        })
        .then((result) => {
          console.log(result);
          delete updatedUser.password;
          setIsLoading(false);
          setPassword(null);
          setConfirmPassword(null);
          authContext.setUserInfo(updatedUser);
          toast.info("Details updated successfully", {
            position: "top-center",
          });
        })
        .catch((err) => {
          toast.error(err.message, {
            position: "top-center",
          });
        });
    }
  };

  function toastError(message) {
    toast.error(message, {
      position: "top-center",
    });
  }

  return (
    <div className="flex flex-col justify-center px-5 py-3 items-center">
      <div className="md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full p-2">
        <CardContainer
          child={
            isLoading ? (
              <>
                <Spinner />
              </>
            ) : (
              <div>
                <div className="flex flex-col space-x-2 items-center">
                  <FaRegUserCircle size={120} />
                  <div className="flex flex-col items-center">
                    <h3 className="text-lg">
                      {userInfo ? userInfo.fullName : ""}
                    </h3>
                    <h3 className="text-sm">
                      {userInfo ? userInfo.emailId : ""}
                    </h3>
                  </div>
                </div>
                <form
                  className="px-4 pb-4 space-y-3 mt-10"
                  onSubmit={handleSave}
                >
                  <div>
                    <label
                      htmlFor="name"
                      className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                    >
                      Full Name
                    </label>
                    <input
                      type="name"
                      name="name"
                      onChange={(e) => setFullName(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                      value={fullName ?? ""}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="phone"
                      className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                    >
                      Phone Number
                    </label>
                    <input
                      type="phone"
                      onKeyPress={(event) => {
                        if (!/[0-9]/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      name="phone"
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                      value={phoneNumber ?? ""}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="password"
                      className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                    >
                      Password
                    </label>
                    <input
                      type="password"
                      name="password"
                      onChange={(e) => setPassword(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                      value={password ?? ""}
                      required
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="confirm-password"
                      className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                    >
                      Confirm Password
                    </label>
                    <input
                      type="password"
                      name="confirm-password"
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                      value={confirmPassword ?? ""}
                      required
                    />
                  </div>
                  <div className="flex justify-end">
                    <button
                      type="submit"
                      // onClick={() => onBackClick()}
                      className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
                    >
                      <FaSave size={18} color="white" />
                      <p>Update</p>
                    </button>
                  </div>
                </form>
              </div>
            )
          }
        />
      </div>
    </div>
  );
};

export default GeneralSettings;
