import React, { useState,useEffect,useContext } from "react";
import { BiArrowBack, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import AuthContext from "../../../../context/AuthContext";
import Constants from "../../../../util/Constants";
import useFetch from "../../../../util/useFetch";
import CardContainer from "../../../CardContainer";
import PageLoader from "../../../PageLoader";
import UserAccessUtil from "../UserAccessUtil";
import DataDictionaryTable from "./DataDictionaryTable";
import EditAddDataDictionaryModal from "./EditAddDataDictionaryModal";

const DataDictionaryManagement = () => {
  const navigate = useNavigate();
  const userInfo = useContext(AuthContext).userInfo;
  const agencyList = useContext(AgencyIndicatorListContext).agencyIndicatorList.agencyList;
  const agencyNameList = agencyList.map((agc)=>agc.agencyName.toLowerCase());
  const [userAgency, setUserAgency] = useState();
  const [filteredDataDictList, setfilteredDataDictList] = useState();
 const { data, isLoading, error } = useFetch(
    "/dataDictionary/full",
    {
      method: "GET",
    },
    Constants.ApiCallerDataDictionary
  );

  useEffect(() => {
    if(!UserAccessUtil.canGoToDataDictionaryManagement(userInfo,agencyNameList)){
      navigate('/admin');
      return;
    }
    setUserAgency(agencyList.find((agc)=>agc.agencyName.toLowerCase() === String(userInfo.agency).toLowerCase()) ?? null);
    if(userAgency && data){
      setfilteredDataDictList(data.filter((dict)=> dict.agencyId === userAgency.id))
    }
  }, [data])
  

  let modal = document.getElementById("add-edit-modal");

  const [selectedDataDictionary, setSelectedDataDictionary] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  function onBackClick() {
    navigate(-1);
  }

  function hideModal() {
    modal.style.display = "none";
  }

  function showModal() {
    modal.style.display = "block";
  }

  return (
    <div className="pl-4 pr-2 pt-2 h-screen">
      <div className="flex justify-between mb-2">
        <div
          onClick={() => onBackClick()}
          className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
        >
          <BiArrowBack size={18} color="white" />
          <p>Back</p>
        </div>
        <h3 className="title-md">
          Data Dictionary Management
        </h3>
        <div
          onClick={() => {
            setIsEditing(false);
            setIsDeleting(false);
            showModal();
          }}
          className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
        >
          <BiPlus size={18} color="white" />
          <p> Add</p>
        </div>
      </div>
      <EditAddDataDictionaryModal
        isDeleting={isDeleting}
        userAgency={userAgency}
        selectedDataDictionary={selectedDataDictionary}
        isEditing={isEditing}
        hideModal={hideModal}
      />
      <div className="overflow-y-auto h-full">
        <CardContainer
          setOpacity={false}
          child={
            <PageLoader isLoading={isLoading} error={error}>
               <div>
                <DataDictionaryTable
                  dataList={filteredDataDictList ?? data}
                  setIsDeleting={setIsDeleting}
                  setIsEditing={setIsEditing}
                  setSelectedDataDictionary={setSelectedDataDictionary}
                  showModal={showModal}
                />
              </div>
            </PageLoader>
          }
        />
        <div className="h-28"></div>
      </div>
    </div>
  );
};

export default DataDictionaryManagement;
