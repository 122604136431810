import React, { useEffect, useState, useContext } from "react";
import { toast } from "react-toastify";
import Dropdown from "../../../dropdown/Dropdown";
import { FaSave } from "react-icons/fa";
import Constants from "../../../../util/Constants";
import useFetch from "../../../../util/useFetch";
import Spinner from "../../../../Spinner/Spinner";
import AuthContext from "../../../../context/AuthContext";
import UserAccessUtil from "../UserAccessUtil";

const divisionList = [
  "Circuit",
  "District",
  "Donor Agency",
  "Educational Agency",
  "Ministry of Education",
  "Regional",
  "School",
];

const positionList = ["Director of Department", "Chief Director", "Minister"];

const isValidEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};

const EditAddUserModal = ({
  selectedUser,
  isActivating,
  isSettingUserStatus,
  onCancel,
  agencyList,
}) => {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.userInfo;
  var agencyNameList;
  if (agencyList)
    agencyNameList = agencyList.map((agc) => agc.agencyName.toLowerCase());

  const [isLoading, setIsLoading] = useState(false);
  const [divisionName, setDivisionName] = useState();
  const [isDivisionAdmin, setIsDivisionAdmin] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [isSystemAdmin, setIsSystemAdmin] = useState(false);
  const [fullName, setFullName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  const [selectedDivision, setSelectedDivision] = useState();
  const [position, setPosition] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    setPassword(null);
    setConfirmPassword(null);
    if (selectedUser) {
      setFullName(selectedUser.fullName);
      setEmail(selectedUser.emailId);
      setPhoneNumber(selectedUser.phoneNo);
      setSelectedDivision(selectedUser.divisionalLevel);
      setSelectedAgency(selectedUser.agency);
      setIsActive(selectedUser.active === 1 ? true : false);
      setIsSystemAdmin(selectedUser.sysAdmin ?? false);
      setDivisionName(selectedUser.dept);
      setIsDivisionAdmin(selectedUser.userRole === "admin" ? true : false);
    } else {
      setDivisionName(null);
      setIsDivisionAdmin(false);
      setPhoneNumber(null);
      setIsSystemAdmin(false);
      setFullName(null);
      setEmail(null);
      setPassword(null);
      setConfirmPassword(null);
      setSelectedAgency(null);
      setSelectedDivision(null);
      setPosition(null);
      setSelectedRegion(null);
      setIsActive(true);
    }
  }, [selectedUser]);

  function onDivisionSelect(division) {
    setSelectedDivision(division);
  }

  function onAgencySelect(agency) {
    setSelectedAgency(agency);
  }

  function onPositionSelect(position) {
    setPosition(position);
  }

  function onRegionSelect(region) {
    setSelectedRegion(region);
    setDivisionName(region);
  }

  const handleSave = (event) => {
    event.preventDefault();

    if (!isValidEmail(email)) {
      toast.info("Please enter a valid email", {
        position: "top-center",
      });
    } else if (!selectedUser && (!password || password.length < 8)) {
      toast.info("Passwords too short", {
        position: "top-center",
      });
    } else if (!selectedUser && (!password || password.length > 31)) {
      toast.info("Passwords too long", {
        position: "top-center",
      });
    } else if (!selectedUser && password !== confirmPassword) {
      toast.info("Passwords do not match", {
        position: "top-center",
      });
    } else {
      if (!isSystemAdmin) {
        if (!selectedDivision || selectedDivision === "Select division") {
          toast.info("Please select division", {
            position: "top-center",
          });
        } else if (selectedDivision === "Circuit" && !divisionName) {
          toast.info("Please enter circuit name", {
            position: "top-center",
          });
        } else if (selectedDivision === "District" && !divisionName) {
          toast.info("Please enter district name", {
            position: "top-center",
          });
        } else if (selectedDivision === "Donor Agency" && !divisionName) {
          toast.info("Please enter donor agency", {
            position: "top-center",
          });
        } else if (
          (selectedDivision === "Ministry of Education" && !position) ||
          position === "Select position"
        ) {
          toast.info("Please select position", {
            position: "top-center",
          });
        } else if (
          (selectedDivision === "Regional" && !selectedRegion) ||
          selectedRegion === "Select region"
        ) {
          toast.info("Please select region", {
            position: "top-center",
          });
        } else {
          const user = {
            id: selectedUser ? selectedUser.id : 0,
            fullName: fullName,
            emailId: email,
            phoneNo: phoneNumber,
            dateJoined: selectedUser
              ? selectedUser.dateJoined
              : new Date().toISOString(),
            password: password,
            active: isActive ? 1 : 0,
            accessList: "",
            sysAdmin: isSystemAdmin,
            divisionalLevel: selectedDivision,
            userRole: isDivisionAdmin ? "admin" : "user",
            dept: divisionName ?? position,
            agency: selectedAgency ?? "",
            createdBy: selectedUser ? selectedUser.createdBy : currentUser.id,
            modifiedBy: currentUser.id,
            lastModified: new Date().toISOString(),
          };
          if (!password) user.password = null;

          setIsLoading(true);
          fetch(Constants.BaseUrl + "/save_system_user/", {
            credentials: "include",
            method: "POST",
            body: JSON.stringify(user),
            headers: { "Content-Type": "application/json" },
          })
            .then((res) => {
              setIsLoading(false);
              if (!res.ok) {
                throw Error(
                  "An error occurred while saving, please try again later"
                );
              }
              return res.json();
            })
            .then((result) => {
              toast.info("Saved Successfully", {
                position: "top-center",
              });
              onCancel();
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((err) => {
              toast.error(err.message, {
                position: "top-center",
              });
            });
        }
      } else {
        const user = {
          id: selectedUser ? selectedUser.id : 0,
          fullName: fullName,
          emailId: email,
          phoneNo: phoneNumber,
          dateJoined: new Date().toISOString(),
          password: password,
          active: isActive ? 1 : 0,
          accessList: "",
          sysAdmin: isSystemAdmin,
          divisionalLevel: null,
          userRole: isDivisionAdmin ? "admin" : "user",
          dept: null,
          agency: null,
          createdBy: selectedUser ? selectedUser.createdBy : currentUser.id,
          modifiedBy: currentUser.id,
          lastModified: new Date().toISOString(),
        };
        setIsLoading(true);
        if (!password) user.password = null;

        fetch(Constants.BaseUrl + "/save_system_user/", {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(user),
          headers: { "Content-Type": "application/json" },
        })
          .then((res) => {
            setIsLoading(false);
            if (!res.ok) {
              throw Error(
                "An error occurred while saving, please try again later"
              );
            }
            return res.json();
          })
          .then((result) => {
            toast.info("Saved Successfully", {
              position: "top-center",
            });
            onCancel();
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((err) => {
            toast.error(err.message, {
              position: "top-center",
            });
          });
      }
    }
  };

  function changeUserStatus() {
    const user = {
      id: selectedUser ? selectedUser.id : 0,
      fullName: fullName,
      emailId: email,
      phoneNo: phoneNumber,
      dateJoined: selectedUser
        ? selectedUser.dateJoined
        : new Date().toISOString(),
      password: password,
      active: isActivating ? 1 : 0,
      accessList: "",
      sysAdmin: isSystemAdmin,
      divisionalLevel: selectedDivision,
      userRole: isDivisionAdmin ? "admin" : "user",
      dept: divisionName ?? position,
      agency: selectedAgency ?? "",
      createdBy: selectedUser ? selectedUser.createdBy : currentUser.id,
      modifiedBy: currentUser.id,
      lastModified: new Date().toISOString(),
    };

    setIsLoading(true);
    fetch(Constants.BaseUrl + "/save_system_user/", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(user),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        setIsLoading(false);
        if (!res.ok) {
          throw Error("An error occurred while saving, please try again later");
        }
        return res.json();
      })
      .then((result) => {
        toast.info("Saved Successfully", {
          position: "top-center",
        });
        onCancel();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-center",
        });
      });
  }

  return (
    <div
      className="fixed hidden inset-0 bg-gray-600 p-5 md:p-0 lg:p-0 xl:p-0 2xl:p-0 bg-opacity-50 overflow-y-auto h-full w-full"
      id="add-edit-modal"
    >
      <div className="relative top-20 mx-auto p-5 border md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full shadow-lg rounded-xl bg-white">
        <div className="flex justify-between">
          <h3 className="title-md">
            {selectedUser && isSettingUserStatus
              ? isActivating
                ? "Activate " + selectedUser.fullName
                : "Deactivate " + selectedUser.fullName
              : selectedUser
              ? "Edit User " + selectedUser.fullName + ":"
              : "Add User"}
          </h3>
          <button
            type="button"
            onClick={() => onCancel()}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        {isLoading && <Spinner />}
        {!isLoading && isSettingUserStatus && (
          <>
            Are you sure you want to {isActivating ? "activate" : "deactivate"}{" "}
            {selectedUser.fullName}?
            <br />
            <div className="flex space-x-5 justify-end mt-2">
              <div className="flex">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    onCancel();
                  }}
                  className="flex cursor-pointer bg-green-500 px-8 items-center h-10 text-white rounded-lg"
                >
                  <p>NO</p>
                </button>
              </div>
              <div className="flex justify-end">
                <button
                  type="submit"
                  onClick={(e) => {
                    e.preventDefault();
                    changeUserStatus();
                  }}
                  className="flex cursor-pointer bg-red-500 space-x-1.5 items-center h-10 text-white px-8 rounded-lg"
                >
                  <p>YES</p>
                </button>
              </div>
            </div>
          </>
        )}
        {!isLoading && !isSettingUserStatus && (
          <form className="px-4 pb-4 space-y-3 mt-3" onSubmit={handleSave}>
            <div>
              <label
                htmlFor="name"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Full Name
              </label>
              <input
                type="name"
                name="name"
                onChange={(e) => setFullName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={fullName ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={email ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Phone Number
              </label>
              <input
                type="phone"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                name="phone"
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={phoneNumber ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="password"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Password
              </label>
              <input
                type="password"
                name="password"
                onChange={(e) => setPassword(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={password ?? ""}
              />
            </div>
            <div>
              <label
                htmlFor="confirm-password"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Confirm Password
              </label>
              <input
                type="password"
                name="confirm-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={confirmPassword ?? ""}
              />
            </div>
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Is User Active?
              </label>
              <div className="flex space-x-3">
                <div className="flex">
                  <input
                    type="radio"
                    name="user-active-radio-group"
                    label="userActive-yes-radio"
                    id="userActive-yes-radio"
                    className="m-1"
                    checked={isActive}
                    value="userActive-yes-radio"
                    onChange={(e) => setIsActive(true)}
                  />
                  <label
                    htmlFor="userActive-yes-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    name="user-active-radio-group"
                    id="userActive-no-radio"
                    label="userActive-no-radio"
                    value="userActive-no-radio"
                    className="m-1"
                    checked={!isActive}
                    onChange={(e) => setIsActive(false)}
                  />
                  <label
                    htmlFor="userActive-no-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>

            {UserAccessUtil.isSystemAdminOrMOEAdmin(currentUser) && (
              <>
                <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                  User Type
                </label>
                <div className="flex">
                  <input
                    type="checkbox"
                    name="checkbox"
                    id="user-type-checkbox"
                    className="m-1"
                    checked={isSystemAdmin}
                    onChange={(e) => setIsSystemAdmin(e.target.checked)}
                  />
                  <label
                    htmlFor="user-type-checkbox"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    User is System Admin
                  </label>
                </div>
              </>
            )}
            {!isSystemAdmin && (
              <>
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Division
                  </label>
                  <Dropdown
                    className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                    placeHolder={"Select division"}
                    values={divisionList}
                    selected={selectedDivision}
                    onSelect={onDivisionSelect}
                  />
                  {selectedDivision === "Circuit" && (
                    <div>
                      <label
                        htmlFor="circuit"
                        className="block mb-1 mt-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                      >
                        Enter Circuit
                      </label>
                      <input
                        type="text"
                        name="circuit"
                        value={divisionName ?? ""}
                        onChange={(e) => setDivisionName(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                        required
                      />
                    </div>
                  )}
                  {selectedDivision === "District" && (
                    <div>
                      <label
                        htmlFor="district"
                        className="block mb-1 mt-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                      >
                        Enter District
                      </label>
                      <input
                        type="text"
                        name="district"
                        value={divisionName ?? ""}
                        onChange={(e) => setDivisionName(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                        required
                      />
                    </div>
                  )}
                  {selectedDivision === "Donor Agency" && (
                    <div>
                      <label
                        htmlFor="donor-agency"
                        className="block mb-1 mt-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                      >
                        Enter Donor Agency
                      </label>
                      <input
                        type="text"
                        name="donor-agency"
                        value={divisionName ?? ""}
                        onChange={(e) => setDivisionName(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                        required
                      />
                    </div>
                  )}
                  {selectedDivision === "Ministry of Education" && (
                    <div className="mt-3">
                      <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                        Position
                      </label>
                      <Dropdown
                        className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                        placeHolder={"Select position"}
                        values={positionList}
                        selected={position}
                        onSelect={onPositionSelect}
                      />
                    </div>
                  )}
                  {selectedDivision === "Regional" && (
                    <div className="mt-3">
                      <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                        Region
                      </label>
                      <Dropdown
                        className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                        placeHolder={"Select region"}
                        values={Constants.regionList}
                        itemKey="regionName"
                        selected={divisionName}
                        onSelect={onRegionSelect}
                      />
                    </div>
                  )}
                  {selectedDivision === "School" && (
                    <div>
                      <label
                        htmlFor="school"
                        className="block mb-1 mt-3 text-sm font-medium text-gray-600 dark:text-gray-300"
                      >
                        Enter School
                      </label>
                      <input
                        type="text"
                        name="school"
                        onChange={(e) => setDivisionName(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                        required
                      />
                    </div>
                  )}
                  <div className="flex mt-2">
                    <input
                      type="checkbox"
                      name="checkbox"
                      id="user-role-checkbox"
                      className="m-1"
                      checked={isDivisionAdmin}
                      onChange={(e) => setIsDivisionAdmin(e.target.checked)}
                      // value={}
                    />
                    <label
                      htmlFor="user-role-checkbox"
                      className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                    >
                      User is an admin within division
                    </label>
                  </div>
                </div>
                <div>
                  <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                    Agency
                  </label>
                  {agencyList && (
                    <Dropdown
                      className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                      placeHolder="Select agency"
                      selected={selectedAgency}
                      values={
                        UserAccessUtil.isAgencyAdmin(
                          currentUser,
                          agencyNameList
                        )
                          ? agencyList.filter(
                              (agc) =>
                                agc.agencyName.toLowerCase() ===
                                currentUser.agency.toLowerCase()
                            )
                          : agencyList
                      }
                      itemKey="agencyName"
                      onSelect={onAgencySelect}
                    />
                  )}
                </div>
              </>
            )}

            <div className="flex justify-end">
              <button
                type="submit"
                // onClick={() => onBackClick()}
                className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
              >
                <FaSave size={18} color="white" />
                <p>Save</p>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditAddUserModal;
