import React from "react";
import useFetch from "../../../../util/useFetch";
import Constants from "../../../../util/Constants";
import PageLoader from "../../../PageLoader";

const LoginSummaryCard = () => {
  //end pint: /numbers_of_the_day

  const {
    data: numbersOfDayData,
    isLoading,
    error,
  } = useFetch(
    "/numbers_of_the_day",
    {
      method: "GET",
    },
    Constants.ApiCallerNumbersOfTheDay
  );

  return (
    <div className="h-screen flex items-center">
      <div>
        <h2 className="text-white font-bold text-2xl py-3">
          NUMBERS OF THE DAY
        </h2>

        <PageLoader isLoading={isLoading} error={error} isPage={false}>
          <div className="grid lg:grid-cols-3 sm:grid-cols-1 md:grid-cols-2 gap-5">
            {numbersOfDayData &&
              numbersOfDayData.map((item) => (
                <div
                  key={item.id}
                  className="bg-black py-5 px-10 rounded-lg bg-opacity-70 backdrop-filter backdrop-blur-lg"
                >
                  <h3 className="text-white font-bold text-xl">
                    {item.indicatorName}
                  </h3>
                  <h3 className="text-green-500 font-bold text-4xl">
                    {item.nationalValue}
                  </h3>
                  <h3 className="text-white font-bold text-base">
                    {item.level}
                  </h3>
                  <p className="text-white">
                    {item.source} - {item.year}
                  </p>
                </div>
              ))}
          </div>
        </PageLoader>
      </div>
    </div>
  );
};

export default LoginSummaryCard;
