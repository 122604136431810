import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useContext, useEffect, useRef } from "react";
import { Bar } from "react-chartjs-2";
import CardContainer from "../../../CardContainer";
import useFetch from "../../../../util/useFetch";
import PageLoader from "../../../PageLoader";
import Constants from "../../../../util/Constants";
import NoData from "../../../NoData";
import { AiOutlineFullscreen } from "react-icons/ai";
import FullScreenModal from "../../../FullScreenModal";
import ScreenSizeContext from "../../../../context/ScreenSizeContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const options = {
  indexAxis: "y",
  elements: {
    bar: {
      //   borderWidth: 2,
      borderRadius: 0,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        return value.toLocaleString();
      },
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "600",
        size: 10,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: "16",
        },
      },
    },
  },
  scales: {
    yAxes: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 12,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
      },
    },
    xAxes: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 11,
          weight: "semibold",
        },
        color: "rgb(0, 0, 0)",
      },
    },
  },
};

var previousData = {}; //just a way to check if the current list is different from the previous before we call setState

const LeaderBoard = ({
  indicatorId = 9,
  indicatorName,
  year,
  term,
  region,
  district,
  circuit,
  school,
  setRegionsWithData,
}) => {
  const notApplicable = district || circuit || school;

  const {
    data: leaderBoardData,
    isLoading: isLoadingCurrent,
    error: errorCurrent,
  } = useFetch(
    `${Constants.BaseProxyUrl}/leaderBoard/${
      region !== null
    }/${year}/${indicatorId}/${term}` +
      (region === null ? "" : "?regionId=" + region.regionID),
    {
      method: "GET",
    },
    Constants.ApiCallerLeaderBoard,
    true
  );

  useEffect(() => {
    //setting regions with data to be able to know which regions have data to prevent or allow drill down
    if (leaderBoardData && leaderBoardData.availableDataRegions) {
      console.log(leaderBoardData.availableDataRegions);
      //TODO: Remove code below if everything is fine
      // let currentData = leaderBoardData.availableDataRegions[0];//.map((regionData)=> regionData.regionId).filter((x) => !isNaN(x)).reduce((a,b)=> a+b);
      // if(Object.keys(currentData)[0] === Object.keys(previousData)[0]){
      //   console.log('STOPPEDDD>>>>>>',Object.keys(currentData)[0]);
      //   return;
      // }
      // setRegionsWithData([]);
      setRegionsWithData(leaderBoardData.availableDataRegions);
      // previousData = leaderBoardData.availableDataRegions[0];//.map((regionData)=> regionData.regionId).filter((x) => !isNaN(x)).reduce((a,b)=> a+b);
      // console.log(previousRegionIdTotal);


    }
  }, [leaderBoardData]);

  const modalRef = useRef();

  if (notApplicable) return <div></div>;

  return (
    <CardContainer
      child={
        <PageLoader
          showNoDataAsError={true}
          isLoading={isLoadingCurrent}
          error={errorCurrent}
          isPage={false}
        >
          {notApplicable && <NoData />}

          <FullScreenModal
            ref={modalRef}
            title={`${indicatorName} Leader Board:`}
          >
            <Body
              isFullScreen={true}
              indicatorName={indicatorName}
              modalRef={modalRef}
              notApplicable={notApplicable}
              leaderBoardData={leaderBoardData}
            />
          </FullScreenModal>
          <Body
            isFullScreen={false}
            indicatorName={indicatorName}
            modalRef={modalRef}
            notApplicable={notApplicable}
            leaderBoardData={leaderBoardData}
          />
        </PageLoader>
      }
    />
  );
};

const Body = ({
  isFullScreen,
  notApplicable,
  indicatorName,
  modalRef,
  leaderBoardData,
}) => {
  const screenSize = useContext(ScreenSizeContext);

  return (
    <>
      {leaderBoardData && !notApplicable && (
        <div>
          <div className="w-full h-full flex justify-between">
            {!isFullScreen && (
              <h3 className="title-md mb-5">{indicatorName} Leader Board:</h3>
            )}
            {!isFullScreen && (
              <AiOutlineFullscreen
                size={28}
                className="ml-2 border border-gray-600 rounded-md"
                title="Fullscreen"
                onClick={() => modalRef.current.open()}
              />
            )}
          </div>
          <Bar
            height={isFullScreen ? screenSize.height * 0.94 : 100}
            width={isFullScreen ? screenSize.width * 0.95 : 100}
            options={
              isFullScreen
                ? {
                    ...options,
                    responsive: false,
                    maintainAspectRatio: true,
                    plugins: {
                      ...options.plugins,
                      datalabels: {
                        ...options.plugins.datalabels,
                        font: {
                          ...options.plugins.datalabels.font,
                          size: 22,
                        },
                      },
                    },
                  }
                : options
            }
            data={leaderBoardData}
          />
        </div>
      )}
    </>
  );
};

export default LeaderBoard;
