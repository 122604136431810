import React, { useState, useContext, useEffect } from "react";
import { BiArrowBack, BiPlus } from "react-icons/bi";
import { FaSave } from "react-icons/fa";
import { Navigate, useNavigate } from "react-router-dom";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import AuthContext from "../../../../context/AuthContext";
import Constants from "../../../../util/Constants";
import useFetch from "../../../../util/useFetch";
import CardContainer from "../../../CardContainer";
import Dropdown from "../../../dropdown/Dropdown";
import PageLoader from "../../../PageLoader";
import UserAccessUtil from "../UserAccessUtil";
import EditAddUserModal from "./EditAddUserModal";

const UserManagement = () => {
  const navigate = useNavigate();
  const userInfo = useContext(AuthContext).userInfo;
  const agencyNameList = useContext(
    AgencyIndicatorListContext
  ).agencyIndicatorList.agencyList.map((agc) => agc.agencyName.toLowerCase());

  useEffect(() => {
    if (UserAccessUtil.canNotGoToUserManagement(userInfo, agencyNameList)) {
      navigate("/admin");
      return () => {};
    }
  }, []);

  const { data, isLoading, error } = useFetch(
    "/get_all_system_users",
    {
      method: "GET",
    },
    Constants.ApiCallerGetSystemsUsers
  );
  const { data: agencyList } = useFetch(
    "/retrieve_agencies",
    {
      method: "GET",
    },
    Constants.ApiCallerRetrieveAgencies
  );
  let modal = document.getElementById("add-edit-modal");
  const [stickyEnabled, setStickyEnabled] = useState(true);
  const [isSettingUserStatus, setIsSettingUserStatus] = useState(false);
  const [selectedUser, setSelectedUser] = useState();
  const [isActivating, setIsActivating] = useState(true);

  function onBackClick() {
    navigate(-1);
  }

  function addUser() {
    setSelectedUser(null);
    modal.style.display = "block";
    setStickyEnabled(false);
    setIsSettingUserStatus(false);
  }

  function onEditClick(user) {
    modal.style.display = "block";
    setSelectedUser(user);
    setStickyEnabled(false);
    setIsSettingUserStatus(false);
  }

  function onCancel() {
    modal.style.display = "none";
    setStickyEnabled(true);
    setIsSettingUserStatus(false);
    setSelectedUser(null);
  }

  function changeUserStatus(user, isActive) {
    modal.style.display = "block";
    setSelectedUser(user);
    setIsSettingUserStatus(true);
    setIsActivating(isActive);
    setStickyEnabled(false);
  }

  return (
    <div className="h-screen">
      {/* Modal */}
      <EditAddUserModal
        selectedUser={selectedUser}
        isActivating={isActivating}
        isSettingUserStatus={isSettingUserStatus}
        onCancel={onCancel}
        agencyList={agencyList}
      />
      {/* End of Modal */}
      <div className="flex flex-col pl-4 mr-3 pt-2 h-full">
        <div className="flex pb-2 justify-between">
          <div
            onClick={() => onBackClick()}
            className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
          >
            <BiArrowBack size={18} color="white" />
            <p>Back</p>
          </div>

          <div
            onClick={() => addUser()}
            className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
          >
            <BiPlus size={18} color="white" />
            <p> Add User</p>
          </div>
        </div>
        <CardContainer
          setOpacity={false}
          child={
            <PageLoader isLoading={isLoading} error={error}>
              {data && (
                <div className="h-full w-full">
                  <table className="w-full overflow-y-auto text-left mb-20">
                    <thead
                      style={{
                        borderBottom: "2px solid black",
                        position: stickyEnabled ? "sticky" : "unset",
                        top: "0",
                        background: "white",
                      }}
                    >
                      <tr>
                        <th className="p-2">#</th>
                        <th className="px-2">Name</th>
                        <th className="px-2">Agency</th>
                        <th className="px-2">Phone</th>
                        <th className="px-2">Role</th>
                        <th className="px-2">Actions</th>
                      </tr>
                    </thead>
                    <tbody className="overflow-y-auto h-full">
                      {!UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) && data.filter((user) => userInfo.agency === user.agency).map((user, index) => (
                        <UserRow user={user} index={index} userInfo={userInfo} onEditClick={onEditClick} changeUserStatus={changeUserStatus}/>
                      ))}
                      {UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) && data.map((user, index) => (
                        <UserRow user={user} index={index} userInfo={userInfo} onEditClick={onEditClick} changeUserStatus={changeUserStatus}/>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </PageLoader>
          }
        />
      </div>
    </div>
  );
};

const UserRow = ({user,index,userInfo,onEditClick,changeUserStatus})=>{
  return (<tr
    key={`index${index}`}
    className="border-b hover:bg-blue-200"
  >
    <td>{index + 1}</td>
    <td className="p-2 border-r">{user.fullName}</td>
    <td className="p-2 border-r">
      <div>
        <p>{(user.agency ?? "N/A").toUpperCase()}</p>
        <p className="text-xs text-gray-600">
          {user.dept}
        </p>
      </div>
    </td>
    <td className="p-2 border-r">{user.phoneNo}</td>
    <td className="p-2 border-r">
      {(user.userRole ?? "N/A").toUpperCase()}
    </td>
    <td>
      {(UserAccessUtil.isSystemAdminOrMOEAdmin( userInfo) || userInfo.agency === user.agency) && (
        <button
          onClick={() => onEditClick(user)}
          className="p-2 text-blue-500"
        >
          Edit
        </button>
      )}
      {(UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) ||
        (userInfo.agency === user.agency &&
          user.createdBy === userInfo.id)) && (
        <button
          onClick={() =>
            changeUserStatus(
              user,
              user.active === 1 ? false : true
            )
          }
          className={`p-2 ${
            user.active === 1
              ? "text-red-500"
              : "text-green-500"
          }`}
        >
          {user.active === 1 ? "Deactivate" : "Activate"}
        </button>
      )}
    </td>
  </tr>);
}

export default UserManagement;
