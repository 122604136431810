import React, { useState, useEffect, useContext } from "react";
import NavigationContext from "../../../context/NavigationContext";
import Constants from "../../../util/Constants";
import useFetch from "../../../util/useFetch";
import CardContainer from "../../CardContainer";
import PageLoader from "../../PageLoader";
import SimpleList from "../../SimpleList";

const DataDictionary = () => {
  const [item, setItem] = useState(null);
  const navigationContext = useContext(NavigationContext);

  const { data, isLoading, error } = useFetch(
    "/dataDictionary/full",
    {
      method: "GET",
    },
    Constants.ApiCallerDataDictionary
  );

  useEffect(() => {
    navigationContext.setDrawerRightContent(
      <SideMenuComponent
        isInSideMenu={true}
        data={data}
        isLoading={isLoading}
        error={error}
        onItemClick={onItemClick}
      />
    );
  }, [data,isLoading,error]);

  if (data) {
    if (!item)
      setTimeout(() => {
        setItem(data[0]);
      }, 800);
  }

  const onItemClick = (item,isInSideMenu) => {
    setItem(item);
    if(isInSideMenu) navigationContext.toggleRightDrawer();
  };

  return (
    <div className="w-screen h-screen flex md:ml-4 lg:ml-4 xl:ml-4 2xl:ml-4  mt-2">
      <div className="overflow-y-auto w-full h-full mr-2 md:mr-16 lg:mr-16 xl:mr-16 2xl:mr-16">
        <div className="flex space-x-3 w-full h-full mb-16">
          <SideMenuComponent
            isInSideMenu={false}
            isLoading={isLoading}
            data={data}
            error={error}
            onItemClick={onItemClick}
          />
          <div className="h-full pr-2 md:pr-16 lg:pr-16 xl:pr-16 2xl:pr-16">
            <h3 className="title-lg m-2">DEFINITION</h3>
            <CardContainer
              child={
                <div className="h-full">
                  {item && (
                    <div>
                      <h3 className="title-md text-2xl">{item.term}</h3>
                      <p className="text-xl pt-5">{item.definition}</p>
                      <p className="font-semibold pt-4">
                        Source: {Constants[item.agencyId]}
                      </p>
                    </div>
                  )}
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const SideMenuComponent = ({
  isInSideMenu,
  isLoading,
  error,
  data,
  onItemClick,
}) => {
  const onItemTap = (item)=>{
    onItemClick(item,isInSideMenu);
  }
  return (
    <div className={`${isInSideMenu ? "block w-full p-2" : "hidden"} md:block ld:block xl:block 2xl:block w-1/3`}>
      <h3 className="title-lg m-2">
        LIST OF SPECIALIZED TERMINOLOGIES/ACRONYMS
      </h3>
      <CardContainer
        child={
          <PageLoader isLoading={isLoading} error={error} isPage={false}>
            <SimpleList
              itemList={data}
              titleKey="term"
              titleStyle={"font-semibold text-lg "}
              onItemClick={onItemTap}
            />
          </PageLoader>
        }
      />
    </div>
  );
};

export default DataDictionary;
