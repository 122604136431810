import IndicatorList from "./components/IndicatorList";
import SummaryCardList from "./components/SummaryCardList";
import ByLevel from "./components/ByLevel";
import ByYear from "./components/ByYear";
import Dropdown from "../../dropdown/Dropdown";
import ByRegion from "./components/ByRegion";
import { useState, useEffect, useContext } from "react";
import Speedometer from "./components/Speedometer";
import IntroModal from "./components/IntroModal";
import NavigationContext from "../../../context/NavigationContext";
import { useLocation, useNavigate } from "react-router-dom";
import useFetch from "../../../util/useFetch";
import Constants from "../../../util/Constants";
import PageLoader from "../../PageLoader";
import CardContainer from "../../CardContainer";
import ExpandableList from "../../ExpandableList";

const Summary = () => {
  const [selectedIndicator, setSelectedInd] = useState(null);
  const navigationContext = useContext(NavigationContext);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [hideByRegionChart, setHideByRegionChart] = useState(false);

  const { data, isLoading, error } = useFetch(
    `${Constants.BaseProxyUrl}/home?indicatorId=${
      query.get("indicatorId") ?? 3
    }&year=${query.get("year") ?? 2019}`,
    {
      method: "GET",
    },
    Constants.ApiCallerHomePage,
    true
  );

  useEffect(() => {
    navigationContext.setDrawerRightContent(
      <SideMenuComponent
        onYearSelected={onYearSelected}
        selectedIndicator={selectedIndicator}
        isInSideMenu={true}
        setSelectedIndicator={setSelectedIndicator}
      />
    );
    let modal = document.getElementById("intro-modal");

    setTimeout(() => {
      const showIntro = localStorage.getItem("show-intro") === "false";
      if (!showIntro) showModal();
    }, 2000);

    let year = query.get("year");
    onYearSelected(year ?? "2019");

    function hideModal() {
      modal.style.display = "none";
    }

    function showModal() {
      modal.style.display = "block";
    }
  }, []);

  const setSelectedIndicator = (indicator) => {
    query.set("indicatorId", indicator.id);
    navigate(`/home?${query.toString()}`);
    setSelectedInd(indicator);
  };

  function onYearSelected(year) {
    query.set("year", year);
    navigate(`/home?${query.toString()}`);
  }

  return (
    <div className="p-2 flex space-x-1.5 h-screen">
      <IntroModal />
      {/* <div className="cursor-pointer fixed w-24 h-10 rounded-3xl filter drop-shadow-3xl text-center flex justify-center bg-green-500 bottom-5 right-5"><h3 className="place-self-center text-white">Menu</h3></div> */}
      <SideMenuComponent
        onYearSelected={onYearSelected}
        selectedIndicator={selectedIndicator}
        isInSideMenu={false}
        setSelectedIndicator={setSelectedIndicator}
      />
      <div className="h-full hidden md:block lg:block xl:block 2xl:block w-1.5 rounded-full bg-black"></div>
      <div className="overflow-y-auto w-full">
        <ExpandableList
          title="Summary"
          initialExpand={true}
          child={
            <PageLoader isLoading={isLoading} error={error} isPage={false}>
              <SummaryCardList data={data} />
            </PageLoader>
          }
        />

        <PageLoader isLoading={isLoading} error={error} isPage={false}>
          {data && selectedIndicator &&  <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 mt-2 mb-12">
            <ByYear
              selectedIndicator={selectedIndicator}
              data={data}
              setHideByRegionChart={setHideByRegionChart}
            />
            <ByRegion
              selectedIndicator={selectedIndicator}
              selectedYear={query.get("year") ?? ""}
              hideByRegionChart={hideByRegionChart}
              data={data}
              setHideByRegionChart={setHideByRegionChart}
            />
            <ByLevel
              selectedIndicator={selectedIndicator}
              selectedYear={query.get("year") ?? ""}
              data={data}
            />
            <Details selectedIndicator={selectedIndicator} />
          </div>}
        </PageLoader>
      </div>
    </div>
  );
};

const SideMenuComponent = ({
  onYearSelected,
  selectedIndicator,
  setSelectedIndicator,
  isInSideMenu = false,
}) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigationContext = useContext(NavigationContext);

  function onYearSel(year) {
    if (isInSideMenu) navigationContext.toggleRightDrawer();
    onYearSelected(year);
  }
  return (
    <div
      className={`h-screen ${
        isInSideMenu ? "block" : "hidden"
      } md:flex lg:flex xl:flex 2xl:flex flex-col w-full md:w-2/5 lg:w-1/4 xl:w-1/4 2x:w-1/4`}
    >
      <h3 className="text-center text-xl font-bold">MOE Key Indicators</h3>
      <div className="px-2 pb-1 w-full">
        <Dropdown
          placeHolder="Select Year"
          className="p-2 border-2 border-black rounded-xl w-full"
          values={Constants.yearList}
          selected={query.get("year")}
          onSelect={onYearSel}
        />
      </div>
      <IndicatorList
        isInSideMenu={isInSideMenu}
        selectedIndicator={selectedIndicator}
        setSelectedIndicator={setSelectedIndicator}
      />
    </div>
  );
};

const Details = ({ selectedIndicator }) => {
  return (
    <CardContainer
      // bgColor="bg-blue-200"
      child={
        <>
          {selectedIndicator && (
            <>
              <h3 className="title-md mb-5">
                {selectedIndicator.indicatorName}
              </h3>
              <p className="text-2xl font-semibold p-2">
                Description:
                <br /> {selectedIndicator.description ?? "To Be Developed"}
              </p>
            </>
          )}
        </>
      }
    />
  );
};

//==================================

// const GERByRegion = () => {
//   return (
//     <CardContainer
//       bgColor="bg-blue-200"
//       child={
//         <div className="flex flex-col">
//           <div className="flex flex-wrap">
//             <h3 className="title-md mb-5 mt-2">
//               Gross Enrolment Ratio By Region (2019)
//             </h3>
//             {/* <DropDown
//               onSelect={onSelect}
//               values={yearOptions}
//               placeHolder="Select Year"
//             /> */}
//           </div>
//           <ResponsiveContainer width="98%" aspect={4 / 2}>
//             <BarChart
//               data={regionData}
//               margin={{
//                 top: 5,
//                 right: 30,
//                 left: 20,
//                 bottom: 5,
//               }}
//               barSize={20}
//             >
//               <XAxis dataKey="name" />
//               <YAxis unit="%" />
//               <Tooltip />
//               <Legend />
//               <CartesianGrid strokeDasharray="3 3" />
//               <Bar dataKey="value" fill="#1D4ED8" unit="%">
//                 {regionData.map((item, index) => (
//                   <Cell
//                     key={index}
//                     fill={item.value < item.target ? "#C87A08" : "#3C961D"}
//                   />
//                 ))}
//               </Bar>
//               <Bar dataKey="target" fill="#3C961D" unit="%" />
//             </BarChart>
//           </ResponsiveContainer>
//         </div>
//       }
//     />
//   );
// };

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 2000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 2000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 2181,
//   },
//   {
//     name: "Page F",
//     uv: 2390,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 4300,
//     amt: 2100,
//   },
// ];

// const regionData = [
//   {
//     name: "Ashanti",
//     value: 97.1,
//     target: 115.7,
//   },
//   {
//     name: "Bono",
//     value: 109.8,
//     target: 120.1,
//   },
//   {
//     name: "Bono East",
//     value: 101.6,
//     target: 101.6,
//   },
//   {
//     name: "Ahafo",
//     value: 102.6,
//     target: 102.6,
//   },
//   {
//     name: "Central",
//     value: 114.5,
//     target: 120,
//   },
//   {
//     name: "Eastern",
//     value: 87.1,
//     target: 100.0,
//   },
//   {
//     name: "Greater Accra",
//     value: 94.5,
//     target: 150.0,
//   },
//   {
//     name: "Northern",
//     value: 101.8,
//     target: 101.8,
//   },
//   {
//     name: "Savannah",
//     value: 74.4,
//     target: 108.6,
//   },
//   {
//     name: "North East",
//     value: 82.7,
//     target: 100.0,
//   },
//   {
//     name: "Upper East",
//     value: 101.6,
//     target: 115.5,
//   },
//   {
//     name: "Upper West",
//     value: 103.5,
//     target: 105.5,
//   },
//   {
//     name: "Volta",
//     value: 94.0,
//     target: 103.5,
//   },
//   {
//     name: "Oti",
//     value: 84.7,
//     target: 100.0,
//   },
//   {
//     name: "Western",
//     value: 96.2,
//     target: 120.5,
//   },
//   {
//     name: "Western North",
//     value: 103.4,
//     target: 120.8,
//   },
// ];

export default Summary;
