import { useState } from "react";
import { SlideDown } from "react-slidedown";
import { AiFillDownCircle, AiFillUpCircle } from "react-icons/ai";
import "react-slidedown/lib/slidedown.css";

const ExpandableList = ({ title, child,initialExpand = false }) => {
  const [isExpanded, setExpanded] = useState(initialExpand);

  return (
    <div className="flex flex-col">
      <div
        className="flex h-10 cursor-pointer my-2 rounded-lg bg-green-500 items-center space-x-2 px-5"
        onClick={() => setExpanded(!isExpanded)}
      >
        <h3 className="title-md text-white">{title}</h3>
        {isExpanded ? (
          <AiFillUpCircle size={25} color="white" />
        ) : (
          <AiFillDownCircle size={25} color="white" />
        )}
      </div>
      <SlideDown>{isExpanded && child}</SlideDown>
    </div>
  );
};

export default ExpandableList;
