const regionEncodingList = [
  {
    regionID: "1",
    regionName: "ASHANTI",
    regionCode: "#ar",
  },
  {
    regionID: "2",
    regionName: "BONO",
    regionCode: "#br",
  },
  {
    regionID: "3",
    regionName: "CENTRAL",
    regionCode: "#cr",
  },
  {
    regionID: "4",
    regionName: "EASTERN",
    regionCode: "#er",
  },
  {
    regionID: "5",
    regionName: "GREATER ACCRA",
    regionCode: "#ga",
  },
  {
    regionID: "6",
    regionName: "NORTHERN",
    regionCode: "#nr",
  },
  {
    regionID: "7",
    regionName: "UPPER EAST",
    regionCode: "#ue",
  },
  {
    regionID: "8",
    regionName: "UPPER WEST",
    regionCode: "#uw",
  },
  {
    regionID: "9",
    regionName: "VOLTA",
    regionCode: "#vr",
  },
  {
    regionID: "10",
    regionName: "WESTERN",
    regionCode: "#wr",
  },
  {
    regionID: "236",
    regionName: "AHAFO",
    regionCode: "#ah",
  },
  {
    regionID: "237",
    regionName: "BONO EAST",
    regionCode: "#be",
  },
  {
    regionID: "238",
    regionName: "NORTH EAST",
    regionCode: "#ne",
  },
  {
    regionID: "239",
    regionName: "SAVANNA",
    regionCode: "#sr",
  },
  {
    regionID: "240",
    regionName: "OTI",
    regionCode: "#or",
  },
  {
    regionID: "241",
    regionName: "WESTERN NORTH",
    regionCode: "#wn",
  },
];

const agencyList = [
  { id: 1, name: "SRIM (EMIS)" },
  { id: 2, name: "mSRC" },
  { id: 3, name: "NaCCA" },
  { id: 4, name: "NTC" },
  { id: 5, name: "NaSIA" },
  { id: 6, name: "GES" },
];

const Constants = {
  ApiCallerCarousel: "ApiCallerCarousel",
  ApiCallerIndicatorBreakdown: "ApiCallerIndicatorBreakdown",
  ApiCallerTopBottomFive: "ApiCallerTopBottomFive",
  ApiCallerGenericIndicators: "ApiCallerGenericIndicators",
  ApiCallerDashboardIndicatorList: "ApiCallerIndicatorList",
  ApiCallerOverviewPieChart: "ApiCallerOverviewPieChart",
  ApiCallerLeaderBoard: "ApiCallerLeaderBoard",
  ApiCallerIndicatorManual: "ApiCallerIndicatorManual",
  ApiCallerRegionDistricts: "ApiCallerRegionDistricts",
  ApiCallerDistrictCircuits: "ApiCallerDistrictCircuits",
  ApiCallerNumbersOfTheDay: "ApiCallerNumbersOfTheDay",
  ApiCallerDistrictsCircuits: "ApiCallerDistrictsCircuits",
  ApiCallerCircuitSchools: "ApiCallerCircuitSchools",
  ApiCallerDataDictionary: "ApiCallerDataDictionary",
  ApiCallerSchoolList: "ApiCallerSchoolList",
  ApiCallerGetSystemsUsers: "ApiCallerGetSystemsUsers",
  ApiCallerRetrieveAgencies: "ApiCallerRetrieveAgencies",
  ApiCallerMSRCIndicators:"ApiCallerMSRCIndicators",
  ApiCallerSchoolSummaryBarChart: "ApiCallerSchoolSummaryBarChart",
  ApiCallerSchoolPage: "ApiCallerSchoolPage",
  ApiCallerAgencyLandingPageTripleCircle: "ApiCallerAgencyLandingPageTripleCircle",
  ApiCallerIndicatorManagementAgencies: "ApiCallerIndicatorManagementAgencies",
  ApiCallerMSRCSchools: "ApiCallerMSRCSchools",
  ApiCallerHomePage: "ApiCallerHomePage",
  BaseProxyUrl: "https://dev.moedashboardgh.com/loopback",
  BaseProxyLocalUrl: "http://localhost:3030",
  BaseUrl: "https://dev.moedashboardgh.com/loopback/?url=https://dev.moedashboardgh.com/backendtwo-0.0.1-SNAPSHOT/api",
  BaseLocalUrl: "http://localhost:4000/?url=https://dev.moedashboardgh.com/backendtwo-0.0.1-SNAPSHOT/api",
  1: "SRIM (EMIS)",
  2: "mSRC",
  3: "NaCCA",
  4: "NTC",
  5: "NaSIA",
  6: "GES",
  regionList: regionEncodingList,
  agencyList: agencyList,
  yearList: [
    "2013",
    "2014",
    "2015",
    "2016",
    "2017",
    "2018",
    "2019",
    "2020",
    "2021",
    "2022",
  ],
  termObject: {
    "Term One": "1",
    "Term Two": "2",
    "Term Three": "3",
  },
  termsObjectReversed: {
    "1":"Term One",
    "2":"Term Two",
    "3": "Term Three",
 }
};

export default Constants;
