import { useEffect, useState, useContext, React } from "react";
import { useParams, useNavigate } from "react-router-dom";
import notVisualizable from "../../../../assets/svg/not_visualizable.svg";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import Spinner from "../../../../Spinner/Spinner";
import logos from "../../../../util/logos";
import CardContainer from "../../../CardContainer";
import NoData from "../../../NoData";
import LandingPageCard from "./LandingPageCard";
import MSRCPage from "./MSRCPage";

const AgencyLandingPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const [agency, setAgency] = useState();
  const [selectedIndicatorList, setSelectedIndicatorList] = useState();
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);

  useEffect(() => {
    if (params.id) {
      const selectedAgency =
        agencyIndicatorListContext.agencyIndicatorList.agencyList.find(
          (ag) => ag.id == params.id
        );
      setAgency(selectedAgency);
      // setTimeout(() => {
      setSelectedIndicatorList(
        selectedAgency.indicators.filter((ind) => ind.showOnAgencyPage === true)
      );
      // }, 300);
    }
  }, [params]);

  return (
    <div className="w-full h-screen pl-5 pt-2 overflow-y-auto">
      {agency ? (
        <div className="flex flex-wrap space-x-5">
          <img
            src={logos[agency.id]}
            width={190}
            height={190}
            alt="Agency Logo"
            className="self-center my-3"
          />
          <div className="flex flex-col py-2 space-y-2 items-start w-1/2">
            <h3 className="text-3xl font-bold">{agency.agencyName}</h3>
            <p className="text-lg font-semibold">{agency.description}</p>
          </div>
        </div>
      ) : (
        <Spinner />
      )}
      {agency && agency.id !== 2 && (
        <div className="flex flex-wrap justify-center">
          <h3 className="text-2xl font-bold mr-5 mb-2">
            {agency.visibleOnDashboardCount > 0
              ? "All information below is national based."
              : "Sorry, selected agency has no visualizable indicators"}
          </h3>
          {agency.visibleOnDashboardCount > 0 && (
            <button
              onClick={() => {
                navigate("/indicators?agencyId=" + params.id);
              }}
              className="rounded-lg p-2 text-white bg-black h-10"
            >
              View Other Indicators
            </button>
          )}
        </div>
      )}

      {agency && agency.id === 2 && (
        <div className="mt-3">
          <h3 className="text-xl font-semibold text-center">
            Dear user, mSRC currently obtains data from selected schools. To
            visualize indicators from selected mSRC schools please select a
            flagged school that mSRC currently obtains and compiles data from.
          </h3>
          <MSRCPage agency={agency}/>
        </div>
      )}
      {agency &&
        agency.id !== 2 && agency.id !== 5 &&
        (agency.visibleOnDashboardCount > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2 mt-5 pr-2 pb-16">
            {selectedIndicatorList[agency.id === 3 ? 2 : 0] ? (
              <LandingPageCard indicator={selectedIndicatorList[agency.id === 3 ? 2 : 0]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
            {selectedIndicatorList[1] ? (
              <LandingPageCard indicator={selectedIndicatorList[1]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
            {selectedIndicatorList[agency.id === 3 ? 0 : 2] ? (
              <LandingPageCard indicator={selectedIndicatorList[agency.id === 3 ? 0 : 2]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
            {selectedIndicatorList[3] ? (
              <LandingPageCard indicator={selectedIndicatorList[3]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
          </div>
        ) : (
          <div></div>
        ))}
        {agency &&
        agency.id === 5 &&
        (agency.visibleOnDashboardCount > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2 mt-5 pr-2 pb-16">
             {selectedIndicatorList[2] ? (
              <LandingPageCard indicator={selectedIndicatorList[2]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
            {selectedIndicatorList[0] ? (
              <LandingPageCard indicator={selectedIndicatorList[0]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
            {selectedIndicatorList[1] ? (
              <LandingPageCard indicator={selectedIndicatorList[1]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
           
            {selectedIndicatorList[3] ? (
              <LandingPageCard indicator={selectedIndicatorList[3]} />
            ) : (
              <CardContainer child={<NoData/>} />
            )}
          </div>
        ) : (
          <div></div>
        ))}
      {agency && (agency.id !== 2) && (agency.visibleOnDashboardCount < 1) && (
        <div className="flex justify-center">
          <img
            width={350}
            height={350}
            style={{
              backgroundColor: "white",
              marginTop: 50,
              padding: 10,
              borderRadius: 10,
            }}
            src={notVisualizable}
          />
        </div>
      )}
    </div>
  );
};

export default AgencyLandingPage;
