import React, { useMemo,useContext } from "react";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import TableSearchBar from "../../../TableSearchBar";


const COLUMNS = [
    {
      Header: "Term",
      accessor: "term",
    },
    {
      Header: "Definition",
      accessor: "definition",
    },
    {
      Header: "Source",
    },
    {
      Header: "Actions",
    },
  ];

const DataDictionaryTable = ({dataList,showModal,setIsEditing,setIsDeleting,setSelectedDataDictionary}) => {
// console.log("🚀 ~ file: DataDictionaryTable.js ~ line 25 ~ DataDictionaryTable ~ dataList", dataList)
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => dataList, []);
  const agencyList = useContext(AgencyIndicatorListContext).agencyIndicatorList.agencyList;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows, //if you use rows it will show you everything without pagination
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    page, //if you use page page will put it in pages, thus ten items in a page
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex } = state;


  return (
    <div>
      <TableSearchBar filter={globalFilter} setFilter={setGlobalFilter}/>
      <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => {
                return (
                  <tr {...headerGroup.getFooterGroupProps()} key={index}>
                    {headerGroup.headers.map((column, index2) => (
                      <th {...column.getHeaderProps()} key={index2}>
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                );
              })}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr key={index}>
                    {row.cells.map((cell, index2) => {
                      const originalData = cell.row.original;
                      const agency = agencyList.find(item => item.id ===originalData.agencyId);

                      return (
                        <td {...cell.getCellProps()} key={index2}>
                          {cell.column.Header === "Actions" ? (
                           <div className="flex space-x-3">
                              <button
                              onClick={() => {
                                setSelectedDataDictionary(originalData);
                                setIsEditing(true);
                                setIsDeleting(false);
                                showModal();
                              }}
                              className="text-blue-500"
                            >
                              Edit
                            </button>
                            {/* <button
                              onClick={() => {
                                setSelectedDataDictionary(originalData);
                                setIsEditing(false);
                                setIsDeleting(true);
                                showModal();
                              }}
                              className="text-red-500"
                            >
                              Delete
                            </button> */}
                           </div>
                          ) : cell.column.Header === "Source" ? (<>{agency && agency.agencyName }</>) : (
                            cell.render("Cell")
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="flex justify-center items-center space-x-5 p-3 w-full">
            <button
              onClick={() => gotoPage(0)}
              className="bg-black text-white rounded-lg px-3 py-1"
              disabled={!canPreviousPage}
            >
              {"<<"}
            </button>
            <button
              className="bg-black text-white rounded-lg px-2 py-1"
              disabled={!canPreviousPage}
              onClick={() => previousPage()}
            >
              {"<"} Previous
            </button>
            <span>
              Page {" "}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>
            </span>
            <span>
              | &nbsp;&nbsp;&nbsp;
              <input
                placeholder="Go to page"
                className="px-2 py-1 rounded-lg bg-white bg-opacity-80 w-36 border-black border-2"
                // value={filter || ""}
                onChange={(e) => {
                  var pageNumber = e.target.value
                    ? Number(e.target.value)
                    : 0;
                    pageNumber = pageNumber >= 1 ? (pageNumber -1) : pageNumber;
                  gotoPage(pageNumber);
                }}
              />
            </span>
            <button
              className="bg-black text-white rounded-lg px-3 py-1"
              disabled={!canNextPage}
              onClick={() => nextPage()}
            >
              Next {">"}
            </button>
            <button
              onClick={() => gotoPage(pageCount - 1)}
              className="bg-black text-white rounded-lg px-3 py-1"
              disabled={!canNextPage}
            >
              {">>"}
            </button>
          </div>
    </div>
  )
}

export default DataDictionaryTable