import mapSvg from "../assets/svg/map.svg";
import { SvgLoader, SvgProxy } from "react-svgmt";
import { useState,memo } from "react";

const MapView = memo(({ onSelectRegion }) => {
  const [selectedRegion, setSelectedRegion] = useState(null);

  function handleOnRegionClick(region) {
    setSelectedRegion(region);
    if (onSelectRegion) onSelectRegion(region);
  }

  return (
    <div className="w-full h-full flex flex-col">
      <SvgLoader width="100%" heigh="100%" path={mapSvg}>
        {regionEncodingList.map((region, index) => (
          <SvgProxy
            key={index}
            fill={
              selectedRegion && region.regionID === selectedRegion.regionID
                ? "#6294F8"
                : "#10B981"
            }
            onClick={(e) => {
              e.preventDefault();
              handleOnRegionClick(region);
            }}
            selector={region.regionCode}
          />
        ))}
      </SvgLoader>
    </div>
  );
});

const regionEncodingList = [
  {
    regionID: "1",
    regionName: "ASHANTI",
    regionCode: "#ar",
  },
  {
    regionID: "2",
    regionName: "BONO",
    regionCode: "#br",
  },
  {
    regionID: "3",
    regionName: "CENTRAL",
    regionCode: "#cr",
  },
  {
    regionID: "4",
    regionName: "EASTERN",
    regionCode: "#er",
  },
  {
    regionID: "5",
    regionName: "GREATER ACCRA",
    regionCode: "#ga",
  },
  {
    regionID: "6",
    regionName: "NORTHERN",
    regionCode: "#nr",
  },
  {
    regionID: "7",
    regionName: "UPPER EAST",
    regionCode: "#ue",
  },
  {
    regionID: "8",
    regionName: "UPPER WEST",
    regionCode: "#uw",
  },
  {
    regionID: "9",
    regionName: "VOLTA",
    regionCode: "#vr",
  },
  {
    regionID: "10",
    regionName: "WESTERN",
    regionCode: "#wr",
  },
  {
    regionID: "236",
    regionName: "AHAFO",
    regionCode: "#ah",
  },
  {
    regionID: "237",
    regionName: "BONO EAST",
    regionCode: "#be",
  },
  {
    regionID: "238",
    regionName: "NORTH EAST",
    regionCode: "#ne",
  },
  {
    regionID: "239",
    regionName: "SAVANNA",
    regionCode: "#sr",
  },
  {
    regionID: "240",
    regionName: "OTI",
    regionCode: "#or",
  },
  {
    regionID: "241",
    regionName: "WESTERN NORTH",
    regionCode: "#wn",
  },
];

export default MapView;
