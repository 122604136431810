import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Constants from "../../../../util/Constants";
import CardContainer from "../../../CardContainer";
import PageLoader from "../../../PageLoader";
import NoData from "../../../NoData";
import logos from "../../../../util/logos";


//TODO: search and pagination for drilling down
const SchoolList = ({ tempRegion, tempDistrict }) => {
  const location = useLocation();
  // console.log("🚀 ~ file: SchoolList.js ~ line 10 ~ SchoolList ~ location", location)
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [schoolList, setSchoolList] = useState(null);
  const query = new URLSearchParams(location.search);
  const schoolFilter = query.get("filter");
  const searchQuery = query.get("search");
  const pageNumber = query.get("page");
  const regionID = query.get("regionID");
  const districtID = query.get("districtID");
  const circuitID = query.get("circuitID");
  const options = { method: "GET", credentials: "include" };

  useEffect(() => {
    const abortController = new AbortController();
    options.signal = abortController.signal;
   
    var path = "/schoolManuals/minimal&startAt=1&limitTo=100";
    if (searchQuery && searchQuery !== "") {
      path = "/schoolManuals/&schoolName=" + searchQuery;
    } else if (regionID && regionID !== "") {
      path = "/schoolManuals/minimal&only_msrc=false&region=" + regionID;
      if (tempDistrict) {
        path += "&district=" + tempDistrict.districtID;
      }
    } else if (districtID && districtID !== "") {
      path = "/schoolManuals/minimal&only_msrc=false";
      path += "&district=" + districtID;
      if (tempRegion) {
        path += "&region=" + tempRegion.regionID;
      }
    } else if (circuitID && circuitID !== "") {
      path = "/schoolManuals/minimal&only_msrc=false";
      path += "&circuit=" + circuitID;
      if (tempRegion) {
        path += "&region=" + tempRegion.regionID;
      }
      if (tempDistrict) {
        path += "&district=" + tempDistrict.districtID;
      }
    } else {
      path = "/schoolManuals/minimal&startAt=1&limitTo=100";
      if (pageNumber && pageNumber !== "") {
        path = `/schoolManuals/minimal&startAt=${
          (Number(pageNumber) - 1) * 100
        }&limitTo=${Number(pageNumber) * 100}`;
      }
    }

    async function getData() {
      try {
        if (schoolFilter) {
          setSchoolList(schoolList.filter(school => school.nameOfSchool.toLowerCase().includes(schoolFilter.toLowerCase())));
        } else {
          setIsLoading(true);
          setError(null);
          const res = await fetch(Constants.BaseUrl + path, options);
          if (res.ok) {
            const itemList = await res.json();
            setSchoolList(
              searchQuery && searchQuery !== ""
                ? itemList
                : itemList.school_minimal
            );
            setIsLoading(false);
            setError(null);
          } else {
            throw Error("Unable to fetch data from that resource");
          }
        }
      } catch (err) {
        if (err.name !== "AbortError") {
          setIsLoading(false);
          setError(err.message);
        }
      }
    }

    getData();
    return () => abortController.abort();
  }, [searchQuery, pageNumber, regionID, districtID, circuitID, schoolFilter]);

  function onSchoolClick(school) {
    console.table(school)
    if (location.search === "")
      navigate("/masterFacility?schoolID=" + (school.school_id ?? school.id));
    else {
      if (!location.search.includes("schoolID")) {
        query.set('msrcId',school.msrcId ?? '0');
        navigate(
          "/masterFacility" +
            location.search +
            "&schoolID=" +
            (school.school_id ?? school.id)
        );
      } else {
        query.set("schoolID", school.school_id ?? school.id);
        query.set('msrcId',school.msrcId ?? '0');
        navigate("/masterFacility?" + query.toString());
      }
    }
  }

  return (
    <CardContainer
      child={
        <PageLoader
          showNoDataAsError={true}
          isLoading={isLoading}
          error={error}
          isPage={false}
        >
          <div className="overflow-y-auto">
            {schoolList &&
              (schoolList.length < 1 ? (
                <NoData details="No Results" />
              ) : (
                schoolList.map((school, index) => (
                  <div key={index} onClick={() => onSchoolClick(school)}>
                    <div className="flex justify-between hover-style items-center">
                    <h3 className="p-1">{school.nameOfSchool}</h3>
                    {school.msrc_id > 0 && <img width={34} height={20} className="pt-1" src={logos[2]} alt="msrc_logo"/>}
                    </div>
                    <div className="h-px w-full bg-gray-400 mt-1"></div>
                  </div>
                ))
              ))}
          </div>
        </PageLoader>
      }
    />
  );
};

export default SchoolList;
