// import { Chart, Geom,Legend,Interval, Tooltip, Axis, Coordinate, Text } from "bizcharts";
import useFetch from "../../../../util/useFetch";
import CardContainer from "../../../CardContainer";
import NoData from "../../../NoData";
import PageLoader from "../../../PageLoader";
import offices from "../../../../assets/images/offices.png";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Constants from "../../../../util/Constants";
import boy from "../../../../assets/images/boy.png";
import girl from "../../../../assets/images/girl.png";
import { AiOutlineFullscreen } from "react-icons/ai";
import FullScreenModal from "../../../FullScreenModal";
import { useContext, useRef } from "react";
import ScreenSizeContext from "../../../../context/ScreenSizeContext";

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const options = {
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        return value.toLocaleString();
      },
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "bold",
        size: 25,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 16,
        },
      },
    },
  },
  maintainAspectRatio: true,
  responsive: true,
  rotation: 180,
};

const barOptions = {
  // indexAxis: "y",
  maintainAspectRatio: true,
  responsive: true,
  elements: {
    bar: {
      //   borderWidth: 2,
      borderRadius: 0,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        return value.toLocaleString();
      },
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "600",
        size: 25,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 18,
        },
      },
    },
  },
  scales: {
    yAxes: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
      },
    },
    xAxes: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "semibold",
        },
        color: "rgb(0, 0, 0)",
      },
    },
  },
};

const ignorableIndicatorList = [
  9, 93, 13, 85, 91, 90, 86, 79, 80, 84, 89, 81, 82, 88, 83, 78, 87, 3, 4, 14
];

const OverviewPieChart = ({
  indicatorId,
  indicatorName,
  year,
  term,
  region,
  district,
  circuit,
  school,
}) => {
  var url;
  if (region && !district)
    url = `/regionMainValue/${indicatorId}/${region.regionID}&year=${year}&term=${term}`;
  else if (district && !circuit)
    url = `/districtMainValue/${indicatorId}/${district.districtId}&year=${year}&term=${term}`;
  else if (circuit && !school)
    url = `/circuitMainValue/${indicatorId}/${circuit.circuitID}&year=${year}&term=${term}`;
  else url = `/nationalMainValue/${indicatorId}&year=${year}&term=${term}`;
  const {
    data: overviewData,
    isLoading,
    error,
  } = useFetch(
    url,
    {
      method: "GET",
    },
    Constants.ApiCallerOverviewPieChart
  );
  const modalRef = useRef();

  const showFullScreenDialog = () => {
    modalRef.current.open();
  };

  return (
    <CardContainer
      child={
        <PageLoader
          showNoDataAsError={true}
          isLoading={isLoading}
          error={error}
          isPage={false}
        >
          <FullScreenModal title={indicatorName} ref={modalRef}>
            <Body
              isFullScreen={true}
              overviewData={overviewData}
              showFullScreenDialog={showFullScreenDialog}
              indicatorId={indicatorId}
            />
          </FullScreenModal>
          <Body
            isFullScreen={false}
            overviewData={overviewData}
            showFullScreenDialog={showFullScreenDialog}
            indicatorId={indicatorId}
          />
        </PageLoader>
      }
    />
  );
};

const Body = ({
  isFullScreen,
  overviewData,
  indicatorId,
  showFullScreenDialog,
}) => {
  const screenSize = useContext(ScreenSizeContext);

  return (
    <>
      {!ignorableIndicatorList.includes(indicatorId) &&
        overviewData &&
        overviewData.mainValue === "0" && <NoData />}
      {!ignorableIndicatorList.includes(indicatorId) &&
        overviewData &&
        overviewData.mainValue !== "0" && (
          <div className="w-full">
            <div className="flex lg:justify-between flex-col lg:flex-row">
              <h3 className="title-md mb-5">
                Current Value: {overviewData.mainValue}
              </h3>
              <h3 className="title-md mb-5 flex">
                Previous Value: {overviewData.previousYearValue}
                {!isFullScreen && (
                  <AiOutlineFullscreen
                    size={28}
                    className="ml-2 border border-gray-600 rounded-md"
                    title="Fullscreen"
                    onClick={showFullScreenDialog}
                  />
                )}
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <Pie
                height={isFullScreen ? screenSize.height * 0.89 : 400}
                width={isFullScreen ? screenSize.width * 0.8 : 400}
                data={overviewData.data}
                options={
                  isFullScreen
                    ? {
                        ...options,
                        responsive: false,
                        maintainAspectRatio: true,
                        plugins: {
                          ...options.plugins,
                          datalabels: {
                            ...options.plugins.datalabels,
                            font: {
                              ...options.plugins.datalabels.font,
                              size: 40,
                            },
                          },
                        },
                      }
                    : options
                }
              />
            </div>
          </div>
        )}
      {indicatorId === 9 && overviewData && (
        <div
          className="flex flex-col "
          style={{ height: isFullScreen ? "93.5vh" : "100%" }}
        >
          <div className="flex lg:justify-between flex-col lg:flex-row">
            <h3 className="title-md mb-5">
              Current Value: {overviewData.mainValue}
            </h3>
            <h3 className="title-md mb-5 flex">
              Previous Value: {overviewData.previousYearValue}
              {!isFullScreen && (
                <AiOutlineFullscreen
                  size={28}
                  className="ml-2 border border-gray-600 rounded-md"
                  title="Fullscreen"
                  onClick={showFullScreenDialog}
                />
              )}
            </h3>
          </div>
          <div className="flex space-x-10 mt-24 mb-8 justify-center w-full h-full">
            <div className="flex flex-col items-center h-full">
              <div>
                <img
                  src={boy}
                  width={isFullScreen ? screenSize.height * 0.5 : 253}
                  height={isFullScreen ? screenSize.height * 0.5 : 253}
                  alt="boy"
                />
                <h3 className="text-xl font-bold text-center mt-2">MALES</h3>
              </div>
              <h3 className="text-5xl text-center font-bold">
                {overviewData.data.datasets[0].data[0]}
              </h3>
            </div>
            <div className="flex flex-col items-center">
              <div>
                <img
                  src={girl}
                  width={isFullScreen ? screenSize.height * 0.5 : 250}
                  height={isFullScreen ? screenSize.height * 0.5 : 250}
                  alt="girl"
                />
                <h3 className="text-xl font-bold text-center mt-3">FEMALES</h3>
              </div>
              <h3 className="text-5xl text-center font-bold">
                {overviewData.data.datasets[0].data[1]}
              </h3>
            </div>
          </div>
        </div>
      )}
      {(indicatorId === 13 ||
        indicatorId === 85 ||
        indicatorId === 91 ||
        indicatorId === 90 ||
        indicatorId === 86 ||
        indicatorId === 79 ||
        indicatorId === 80 ||
        indicatorId === 84 ||
        indicatorId === 89 ||
        indicatorId === 81 ||
        indicatorId === 82 ||
        indicatorId === 88 ||
        indicatorId === 83 ||
        indicatorId === 3 ||
        indicatorId === 4 ||
        indicatorId === 14 ||
        indicatorId === 87 ||
        indicatorId === 78) &&
        overviewData && (
          <div className="flex flex-col h-full ">
            <div className="flex lg:justify-between flex-col">
              <div className="flex justify-between">
                <h3 className="title-md mb-5">
                  Current Value: {overviewData.mainValue}
                </h3>
                <h3 className="title-md mb-5 flex">
                  Previous Value: {overviewData.previousYearValue}
                  {!isFullScreen && (
                    <AiOutlineFullscreen
                      size={28}
                      className="ml-2 border border-gray-600 rounded-md"
                      title="Fullscreen"
                      onClick={showFullScreenDialog}
                    />
                  )}
                </h3>
              </div>
              <Bar
                height={isFullScreen ? screenSize.height * 0.89 : 400}
                width={isFullScreen ? screenSize.width * 0.8 : 400}
                options={
                  isFullScreen
                    ? {
                        ...barOptions,
                        responsive: false,
                        maintainAspectRatio: true,
                        plugins: {
                          ...barOptions.plugins,
                          datalabels: {
                            ...barOptions.plugins.datalabels,
                            font: {
                              ...barOptions.plugins.datalabels.font,
                              size: 40,
                            },
                          },
                        },
                      }
                    : barOptions
                }
                data={{
                  labels: [overviewData.indicatorName],
                  datasets: [
                    {
                      label: overviewData.data.labels[0],
                      data: [overviewData.data.datasets[0].data[0]],
                      backgroundColor: String(overviewData.data.labels[0])
                        .toLowerCase()
                        .includes("male")
                        ? "rgb(54, 162, 235)"
                        : "rgb(255, 159, 64)",
                    },
                    {
                      label: overviewData.data.labels[1],
                      data: [overviewData.data.datasets[0].data[1]],
                      backgroundColor: String(overviewData.data.labels[0])
                        .toLowerCase()
                        .includes("male")
                        ? "rgb(255, 99, 132)"
                        : "rgb(153, 102, 255)",
                    },
                  ],
                }}
              />
            </div>
          </div>
        )}
      {indicatorId === 93 && overviewData && (
        <div
          className="flex flex-col justify-center items-center w-full"
          style={{ height: isFullScreen ? "93.5vh" : "100%" }}
        >
          <img src={offices} alt="providers" height={250} width={250} />
          <h1 className="text-6xl font-bold">{overviewData.mainValue}</h1>
          <h3 className="title-md mt-3">{overviewData.indicatorName}</h3>
        </div>
      )}
    </>
  );
};

export default OverviewPieChart;
