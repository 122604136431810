import logos from "../../../util/logos";
import useFetch from "../../../util/useFetch";
import CardContainer from "../../CardContainer";
import Dropdown from "../../dropdown/Dropdown";
import Error from "../../Error";
import Spinner from "../../../Spinner/Spinner";
import MapView from "../../MapView";
import { useState, useContext, useEffect, useRef } from "react";
import LeaderBoard from "./components/LeaderBoard";
import BottomAndTopFive from "./components/BottomAndTopFive";
import IndicatorBreakDown from "./components/IndicatorBreakDown";
import OverviewPieChart from "./components/OverviewPieChart";
import ScreenSizeContext from "../../../context/ScreenSizeContext";
import { BiArrowBack, BiInfoCircle } from "react-icons/bi";
import NavigationContext from "../../../context/NavigationContext";
import SimpleList from "../../SimpleList";
import PageLoader from "../../PageLoader";
import Constants from "../../../util/Constants";
// import Districts from "./Districts";
import IndicatorDrillDownContext from "../../../context/IndicatorDrillDownContext";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import noIndicatorSvg from "../../../assets/svg/no_indicator_graph2.svg";
import notVisualizable from "../../../assets/svg/not_visualizable.svg";
import AgencyIndicatorListContext from "../../../context/AgencyIndicatorListContext";
import FullScreenModal from "../../FullScreenModal";
import SchoolPage from "../master_facility/components/SchoolPage";

const termList = ["Term One", "Term Two", "Term Three"];
var tempCircuit = {},
  tempRegion = {},
  tempDistrict = {};

const Indicators = () => {
  const [selectedIndicator, setSelectedIndicator] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [selectedCircuit, setSelectedCircuit] = useState(null);
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [selectedAgency, setSelectedAgency] = useState();
  const [areasWithData, setRegionsWithData] = useState();
  const [agencyHasVisualizableIndicators, setAgencyHasVisualizableIndicators] =
    useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const params = location.search;
  const [mainIndicatorList, setMainIndicatorList] = useState([]);
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);
  const agencyList = agencyIndicatorListContext.agencyIndicatorList.agencyList;
  const screenSize = useContext(ScreenSizeContext);
  const navigationContext = useContext(NavigationContext);

  // fetch list of indicators for dropdown
  const {
    data: indicatorList,
    isLoading,
    error,
  } = useFetch(
    "/indicatorManuals",
    {
      method: "GET",
    },
    Constants.ApiCallerDashboardIndicatorList
  );

  useEffect(() => {
    navigationContext.setDrawerRightContent(
      <SideMenuComponent
        selectedIndicator={selectedIndicator}
        selectedDistrict={selectedDistrict}
        selectedSchool={selectedSchool}
        selectedCircuit={selectedCircuit}
        selectedRegion={selectedRegion}
        areasWithData={areasWithData}
        selectedYear={selectedYear}
        selectedTerm={selectedTerm}
        selectedAgency={selectedAgency}
        screenSize={screenSize}
        onRegionSelected={onRegionSelected}
        onDistrictClick={onDistrictClick}
        onCircuitClick={onCircuitClick}
        onBackClicked={onBackClicked}
        isInSideMenu={true}
      />
    );
    if (params) {
      if (params.includes("agencyId")) {
        const selectedAgencyId = query.get("agencyId");
        if (indicatorList) {
          const agencyIndicators = indicatorList.filter(
            (element) => element.sourceID === Number(selectedAgencyId)
          );
          if (agencyIndicators && agencyIndicators.length > 0) {
            setAgencyHasVisualizableIndicators(true);
          } else {
            setAgencyHasVisualizableIndicators(false);
          }
          setMainIndicatorList(agencyIndicators);
        }
        if (agencyList) {
          setSelectedAgency(agencyList.find((ag) => ag.id == selectedAgencyId));
        }
      }      
      if (params.includes("indicatorId")) {
        if (indicatorList) {
          const foundIndicator = indicatorList.find(
            (ind) => ind.id == query.get("indicatorId")
          );
          setSelectedIndicator(foundIndicator);
          setTimeout(() => {
            if (
              (foundIndicator.sourceID === 5 ||
                foundIndicator.sourceID === 1 ||
                foundIndicator.sourceID === 4) &&
              selectedTerm !== "0"
            ) {
              setSelectedTerm("0");
            }
          }, 200);
        }
      }
      if (params.includes("year")) {
        setSelectedYear(query.get("year"));
      }
      if (params.includes("agencyId") && params.includes("term") && query.get("agencyId") !== "1") {
        setSelectedTerm(query.get("term"));
      }   
    } else {
      if (indicatorList) setMainIndicatorList(indicatorList);
    }
  }, [
    params,
    selectedIndicator,
    selectedCircuit,
    selectedDistrict,
    selectedAgency,
    selectedRegion,
    areasWithData,
    indicatorList,
    selectedYear,
  ]);

  function onAgencySelect(agencyName) {
    setSelectedIndicator(null);
    setSelectedYear(null);
    setSelectedTerm(null);
    const agency = agencyList.find(
      (element) => element.agencyName === agencyName
    );
    if(agency.id === 2){
      navigate("/agencies/landingPage/2")
    }else{
      if (agency) navigate("/indicators?agencyId=" + agency.id);
    else navigate("/indicators");
    }
    
  }

  function onIndicatorSelected(indicator) {
    const indObject = indicatorList.find(
      (element) => element.indicatorName === indicator
    );
    if (indObject) {
      query.set("agencyId", indObject.sourceID);
      query.set("indicatorId", indObject.id);
      navigate("/indicators?" + query.toString());
    }
  }

  function onDistrictClick(district) {
    if (
      district.mainValue &&
      district.mainValue !== "�" &&
      district.mainValue !== "0"
    ) {
      Object.assign(tempDistrict, district);
      navigationContext.setNavData({
        color: "bg-nav-district",
        title: "Indicators",
      });
      setSelectedDistrict(district);
    } else {
      toast.info("No information for selected district");
    }
  }

  function onRegionSelected(region) {
    if (selectedIndicator && selectedYear) {
      if(selectedIndicator.id === 94 || selectedIndicator.id === 93){
        toast.info("Sorry, drill down not possible");
      }else{
         if (areasWithData) {
        let selectedRegionData = areasWithData.find(
          (reg) => String(reg.regionId) === String(region.regionID)
        );
        if (selectedRegionData && selectedRegionData.value !== "0") {
          Object.assign(tempRegion, region);
          setSelectedRegion(region);
          navigationContext.setNavData({
            color: "bg-nav-regional",
            title: "Indicators",
          });
        } else {
          toast.info("No information for selected region");
        }
      } else {
        Object.assign(tempRegion, region);
        setSelectedRegion(region);
        navigationContext.setNavData({
          color: "bg-nav-regional",
          title: "Indicators",
        });
      }
      }
     
    } else {
      toast.info("Please follow the instructions");
    }
  }

  function onTermSelected(term) {
    if (termList.indexOf(term) !== -1) {
      if (
        selectedIndicator &&
        (selectedIndicator.sourceID === 5 ||
          selectedIndicator.sourceID === 1 ||
          selectedIndicator.sourceID === 4) &&
        selectedTerm !== "0"
      ) {
        query.set("term", "0");
        navigate("/indicators?" + query.toString());
      } else {
        query.set("term", String(termList.indexOf(term) + 1));
        navigate("/indicators?" + query.toString());
      }
    }
  }

  function onYearSelected(year) {
    if (Constants.yearList.indexOf(year) !== -1) {
      query.set("year", year);
      navigate("/indicators?" + query.toString());
    }
  }

  function onCircuitClick(circuit) {
    if (selectedAgency.id !== 1 && !circuit.hasOwnProperty("school_id")) {
      Object.assign(tempCircuit, circuit);
      navigationContext.setNavData({
        color: "bg-nav-circuit",
        title: "Indicators",
      });
      setSelectedCircuit(circuit);
    }
  }

  function onBackClicked() {
    if (selectedSchool) {
      setSelectedSchool(null);
      setSelectedCircuit(tempCircuit);
      navigationContext.setNavData({
        color: "bg-nav-circuit",
        title: "Indicators",
      });
    } else if (selectedCircuit) {
      setSelectedCircuit(null);
      setSelectedDistrict(tempDistrict);
      navigationContext.setNavData({
        color: "bg-nav-district",
        title: "Indicators",
      });
    } else if (selectedDistrict) {
      setSelectedDistrict(null);
      setSelectedRegion(tempRegion);
      navigationContext.setNavData({
        color: "bg-nav-regional",
        title: "Indicators",
      });
    } else {
      navigationContext.setNavData({
        color: "bg-nav-national",
        title: "Indicators",
      });
      setSelectedRegion(null);
    }
  }

  return (
    <IndicatorDrillDownContext.Provider
      values={
        (selectedDistrict,
        setSelectedDistrict,
        selectedCircuit,
        setSelectedCircuit)
      }
    >
      <div style={{ height: "100%", width: "100%" }}>
        {isLoading || error ? (
          <div className="w-screen h-screen flex flex-col items-center justify-center">
            {error && <Error />}
            {isLoading && <Spinner />}
          </div>
        ) : (
          indicatorList &&
          indicatorList.length > 0 && (
            <div className="mt-2 ml-4 flex h-screen space-x-2 overflow-auto justify-between">
              <div className="w-full">
                <div className="flex flex-wrap justify-start mb-1">
                  <div className="p-1">
                    <Dropdown
                      placeHolder="Select Indicator"
                      values={mainIndicatorList.map((ind) => ind.indicatorName)}
                      onSelect={onIndicatorSelected}
                      selected={
                        selectedIndicator && selectedIndicator.indicatorName
                      }
                    />
                  </div>
                  <div className="p-1">
                    <Dropdown
                      placeHolder="Select Year"
                      values={Constants.yearList}
                      onSelect={onYearSelected}
                      selected={selectedYear}
                    />
                  </div>
                  <div className="p-1">
                    <Dropdown
                      placeHolder="Select Term"
                      values={termList}
                      selected={selectedTerm}
                      onSelect={onTermSelected}
                    />
                  </div>
                  <div className="p-1">
                    <Dropdown
                      placeHolder="All Agencies"
                      selected={selectedAgency && selectedAgency.agencyName}
                      values={agencyList.filter((item) => item.id !== 6)}
                      itemKey="agencyName"
                      onSelect={onAgencySelect}
                    />
                  </div>
                </div>
                <div className="flex">
                  {selectedRegion && (
                    <div
                      onClick={() => onBackClicked()}
                      className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
                    >
                      <BiArrowBack size={18} color="white" />
                      <p>Back</p>
                    </div>
                  )}
                  {/* <div className="flex justify-center w-full"> */}

                  {agencyHasVisualizableIndicators &&
                    selectedIndicator &&
                    selectedYear &&
                    selectedTerm && (
                      <h3
                        className="title-lg w-full text-center mb-3 flex justify-center"
                        title={selectedIndicator.description}
                      >
                        {selectedIndicator.indicatorName}
                        {!selectedRegion && selectedIndicator && "(National)"}
                        {selectedRegion &&
                          !selectedDistrict &&
                          " (" + selectedRegion.regionName + ")"}
                        {selectedDistrict &&
                          !selectedCircuit &&
                          " (" + selectedDistrict.districtName + ")"}
                        {selectedCircuit &&
                          !selectedSchool &&
                          " (" + selectedCircuit.circuitName + ")"}
                        {selectedIndicator && (
                          <BiInfoCircle size={32} className="ml-2" />
                        )}
                      </h3>
                    )}
                  {/* </div> */}
                </div>

                <div className="flex flex-col w-full">
                  {/* <div className="flex flex-col overflow-y-auto h-screen w-full"> */}
                  {agencyHasVisualizableIndicators ? (
                    selectedIndicator && selectedYear && selectedTerm ? (
                      <div className="grid grid-cols-1 w-full gap-2 lg:grid-cols-2 mb-40">
                        <OverviewPieChart
                          indicatorId={selectedIndicator.id}
                          indicatorName={selectedIndicator.indicatorName}
                          year={selectedYear}
                          term={selectedTerm}
                          region={selectedRegion}
                          district={selectedDistrict}
                          circuit={selectedCircuit}
                          school={selectedSchool}
                        />
                        <IndicatorBreakDown
                          indicatorId={selectedIndicator.id}
                          indicatorName={selectedIndicator.indicatorName}
                          year={selectedYear}
                          term={selectedTerm}
                          region={selectedRegion}
                          district={selectedDistrict}
                          circuit={selectedCircuit}
                          school={selectedSchool}
                        />
                        <LeaderBoard
                          indicatorId={selectedIndicator.id}
                          indicatorName={selectedIndicator.indicatorName}
                          year={selectedYear}
                          term={selectedTerm}
                          region={selectedRegion}
                          district={selectedDistrict}
                          circuit={selectedCircuit}
                          school={selectedSchool}
                          setRegionsWithData={setRegionsWithData}
                        />
                        <BottomAndTopFive
                          indicatorId={selectedIndicator.id}
                          indicatorName={selectedIndicator.indicatorName}
                          year={selectedYear}
                          term={selectedTerm}
                          region={selectedRegion}
                          district={selectedDistrict}
                          circuit={selectedCircuit}
                          school={selectedSchool}
                        />
                      </div>
                    ) : (
                      <div className="flex flex-col justify-center items-center">
                        <h3 className="title-lg mb-10 mt-3">
                          {!selectedIndicator &&
                            !selectedYear &&
                            !selectedTerm &&
                            params &&
                            "Selected " +
                              Constants[Number(query.get("agencyId"))] +
                              ","}
                          {!selectedIndicator &&
                            !selectedYear &&
                            !selectedTerm &&
                            (params ? " p" : "P") +
                              "lease make selections above to visualize"}
                          {selectedIndicator &&
                            !selectedYear &&
                            !selectedTerm &&
                            "Please select year and term to visualize"}
                          {!selectedIndicator &&
                            selectedYear &&
                            !selectedTerm &&
                            "Please select indicator and term to visualize"}
                          {!selectedIndicator &&
                            !selectedYear &&
                            selectedTerm &&
                            "Please select indicator and year to visualize"}
                          {selectedIndicator &&
                            selectedYear &&
                            !selectedTerm &&
                            "Please select term to visualize"}
                          {selectedIndicator &&
                            !selectedYear &&
                            selectedTerm &&
                            "Please select year to visualize"}
                          {!selectedIndicator &&
                            selectedYear &&
                            selectedTerm &&
                            "Please select indicator to visualize"}
                        </h3>

                        <img
                          width={500}
                          height={500}
                          style={{
                            backgroundColor: "white",
                            marginTop: 50,
                            padding: 10,
                            borderRadius: 10,
                          }}
                          src={noIndicatorSvg}
                        ></img>
                      </div>
                    )
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <h3 className="title-lg mb-10 mt-3">
                        Sorry, selected agency has no visualizable indicators
                      </h3>

                      <img
                        width={400}
                        height={400}
                        style={{
                          backgroundColor: "white",
                          marginTop: 50,
                          padding: 10,
                          borderRadius: 10,
                        }}
                        src={notVisualizable}
                      ></img>
                    </div>
                  )}
                </div>
              </div>
              <SideMenuComponent
                selectedIndicator={selectedIndicator}
                selectedDistrict={selectedDistrict}
                selectedSchool={selectedSchool}
                selectedCircuit={selectedCircuit}
                selectedRegion={selectedRegion}
                areasWithData={areasWithData}
                selectedYear={selectedYear}
                selectedTerm={selectedTerm}
                selectedAgency={selectedAgency}
                screenSize={screenSize}
                onRegionSelected={onRegionSelected}
                onDistrictClick={onDistrictClick}
                onCircuitClick={onCircuitClick}
                onBackClicked={onBackClicked}
              />
            </div>
          )
        )}
      </div>
    </IndicatorDrillDownContext.Provider>
  );
};

const SideMenuComponent = ({
  selectedIndicator,
  selectedSchool,
  selectedDistrict,
  selectedCircuit,
  selectedRegion,
  areasWithData,
  selectedYear,
  selectedTerm,
  onRegionSelected,
  onDistrictClick,
  selectedAgency,
  screenSize,
  onCircuitClick,
  onBackClicked,
  isInSideMenu = false,
}) => {
  const navigationContext = useContext(NavigationContext);

  function onRegionSel(region) {
    onRegionSelected(region);
    if (isInSideMenu) navigationContext.toggleRightDrawer();
  }
  return (
    <>
      <div
        className={`${
          isInSideMenu ? "block" : "hidden"
        } md:flex lg:flex xl:flex 2xl:flex flex-col justify-start ${
          isInSideMenu ? "w-full h-screen pl-2" : "w-2/6 h-full"
        } items-center pr-2`}
      >
        <div
          className={`overflow-y-auto w-full h-full ${
            isInSideMenu ? "pt-5" : ""
          } flex flex-col`}
        >
          {selectedIndicator && (
            <CardContainer
              setOpacity={false}
              child={
                <div className="flex flex-wrap h-full p-2 space-x-3 items-center">
                  <img
                    src={logos[selectedIndicator.sourceID]}
                    width={80}
                    alt="Agency Logo"
                    height={80}
                  />
                  <div>
                    <h3 className="text-lg font-extrabold">
                      {selectedIndicator.responsibleAgencies ?? ""}
                    </h3>
                    <h3 className="text-md font-bold">
                      {"LEVEL: " + selectedIndicator.level}
                    </h3>
                  </div>
                </div>
              }
            />
          )}
          <div className="h-5"></div>
          <CardContainer
            child={
              <div className="flex flex-col justify-start">
                <div className="flex">
                  {selectedRegion && (
                    <button onClick={() => onBackClicked()}>
                      <BiArrowBack size={20} className="m-2" />
                    </button>
                  )}
                  <h3 className="title-md text-center w-full">
                    {!selectedRegion && "Select Region"}

                    {selectedRegion && !selectedDistrict && "Select District"}
                    {selectedDistrict &&
                      !selectedCircuit &&
                      (selectedAgency.id === 1
                        ? `School List (${selectedDistrict.districtName})`
                        : "Select Circuit")}
                    {selectedCircuit && !selectedSchool && "Select School"}
                  </h3>
                </div>

                <div
                  style={{
                    height: screenSize.height / (selectedRegion ? 1.5 : 1.7),
                  }}
                >
                  {selectedRegion && !selectedDistrict && (
                    <Districts
                      onDistrictClick={onDistrictClick}
                      selectedRegion={selectedRegion}
                      areasWithData={areasWithData}
                    />
                  )}
                  {selectedDistrict && !selectedCircuit && (
                    <Circuits
                      selectedDistrict={selectedDistrict}
                      onCircuitClick={onCircuitClick}
                      selectedAgency={selectedAgency}
                      selectedRegion={selectedRegion}
                    />
                  )}
                  {selectedCircuit && (
                    <Schools
                      circuitId={selectedCircuit.circuitID}
                      indicatorId={selectedIndicator.id}
                      selectedAgency={selectedAgency}
                      year={selectedYear}
                      term={selectedTerm}
                    />
                  )}
                  {!selectedRegion && <MapView onSelectRegion={onRegionSel} />}
                </div>
              </div>
            }
          />
        </div>
      </div>
    </>
  );
};

const Districts = ({ onDistrictClick, areasWithData, selectedRegion }) => {
  const {
    data: regionDistrictList,
    isLoading,
    error,
  } = useFetch(
    `/retrieve_region_district/&region=${selectedRegion.regionID}`,
    {
      method: "GET",
    },
    Constants.ApiCallerRegionDistricts
  );

  return (
    <PageLoader isLoading={isLoading} error={error} isPage={false}>
      {regionDistrictList && (
        <DistrictList
          regionDistrictList={regionDistrictList}
          areasWithData={areasWithData}
          onDistrictClick={onDistrictClick}
        />
      )}
    </PageLoader>
  );
};

const DistrictList = ({
  regionDistrictList,
  onDistrictClick,
  areasWithData,
}) => {
  const finalList =
    (areasWithData && areasWithData.length) > 0
      ? areasWithData.map((item) => ({
          ...item,
          canDrillDown:
            item.mainValue && item.mainValue !== "0" && item.mainValue !== "�",
        }))
      : regionDistrictList;

  return (
    <div>
      {finalList &&
        finalList.map((item, index) => (
          <div
            key={index}
            onClick={() => {
              onDistrictClick(item);
            }}
          >
            <div className="flex justify-between hover-style items-center">
              <h3 className="hover-style p-1 ">{item.districtName}</h3>
              {item.canDrillDown && (
                <div>
                  <p className="rounded-2xl bg-green-500 mt-1 text-black text-center py-1 px-3 text-sm">
                    &gt;
                  </p>
                </div>
              )}
            </div>
            <div className="h-px w-full bg-gray-400 mt-1"></div>
          </div>
        ))}
    </div>
  );
};

const Circuits = ({
  selectedAgency,
  selectedDistrict,
  selectedRegion,
  onCircuitClick,
}) => {
  var url = `/retrieve_district_circuit/&district=${selectedDistrict.districtId}`;
  var titleKey = "circuitName";
  var apiCaller = Constants.ApiCallerDistrictsCircuits;
  //when the selected agency is SRIM(EMIS) we show the school list instead because SRIM doesn't report data on circuit
  if (selectedAgency && selectedAgency.id === 1) {
    url = `/schoolManuals/minimal&only_msrc=false&district=${selectedDistrict.districtId}&region=${selectedRegion.regionID}`;
    titleKey = "nameOfSchool";
    apiCaller = Constants.ApiCallerSchoolList;
  }

  const {
    data: districtCircuitList,
    isLoading,
    error,
  } = useFetch(
    url,
    {
      method: "GET",
    },
    apiCaller
  );
  const [schoolId, setSchoolId] = useState();

  const modalRef = useRef();

  const onClickWrapper = (item) => {
    onCircuitClick(item);
    //when the selected agency is SRIM(EMIS) we show the school list instead because SRIM doesn't report data on circuit
    //Hence when a circuit is clicked check if its a school and open the school page
    if (selectedAgency.id === 1 && item.hasOwnProperty("school_id")) {
      setSchoolId(item.school_id);
    }
  };

  useEffect(() => {
    if (schoolId) return modalRef.current.open();
  }, [schoolId]);

  const onModalClose = () => {
    modalRef.current.close();
  };

  return (
    <PageLoader isLoading={isLoading} error={error} isPage={false}>
      {schoolId && (
        <FullScreenModal className="bg-gray-200" title="" ref={modalRef}>
          <div className="bg-gray-200 p-5 w-full mt-3 rounded-xl">
            <SchoolPage onBackClicked={onModalClose} schoolID={schoolId} />
          </div>
        </FullScreenModal>
      )}
      {districtCircuitList && (
        <>
          {(selectedAgency && selectedAgency.id !== 1) && (
            <SimpleList
              itemList={districtCircuitList}
              onItemClick={onClickWrapper}
              titleKey={titleKey}
            />
          )}

          {(selectedAgency && selectedAgency.id === 1) &&
            districtCircuitList.map((item, index) => (
              <div key={index} onClick={() => onClickWrapper(item)}>
                <div className="flex justify-between hover-style items-center">
                  <h3 className="hover-style p-1 ">{item[titleKey]}</h3>
                  <div>
                    <p className="rounded-2xl bg-green-500 mt-1 text-black text-center py-1 px-3 text-sm">
                      &gt;
                    </p>
                  </div>
                </div>
                <div className="h-px w-full bg-gray-400 mt-1"></div>
              </div>
            ))}
        </>
      )}
    </PageLoader>
  );
};

const Schools = ({ indicatorId, selectedAgency, circuitId, year, term }) => {
  const navigate = useNavigate();
  const {
    data: schoolList,
    isLoading,
    error,
  } = useFetch(
    `/circuitSchoolsList/${indicatorId}/${circuitId}&year=${year}&term=${term}`,
    {
      method: "GET",
    },
    Constants.ApiCallerCircuitSchools
  );

  function onSchoolClick(school) {
    if (selectedAgency.id === 2 || selectedAgency.id === 3)
      navigate(
        "/masterFacility/schoolDetails?schoolID=" +
          school.schoolId +
          "&agencyName=" +
          selectedAgency.agencyName
      );
  }

  return (
    <PageLoader
      isLoading={isLoading}
      error={error}
      showNoDataAsError={true}
      isPage={false}
    >
      {schoolList && (
        <SimpleList
          itemList={schoolList}
          onItemClick={onSchoolClick}
          titleKey="schoolName"
        />
      )}
    </PageLoader>
  );
};

export default Indicators;
