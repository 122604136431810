import useFetch from "../../../util/useFetch";
import CardContainer from "../../CardContainer";
import Spinner from "../../../Spinner/Spinner";
import Error from "../../Error";
import { useNavigate } from "react-router-dom";
import logos from "../../../util/logos";
import NavigationContext from "../../../context/NavigationContext";
import { useContext,useEffect } from "react";

const Agencies = () => {
  const navigate = useNavigate();
  const navigationContext = useContext(NavigationContext);


  const {
    data: itemList,
    isLoading,
    error,
  } = useFetch("/retrieve_agencies", {
    method: "GET",
  });

  useEffect(() => {
    navigationContext.setDrawerRightContent(null);

  }, [])
  


  return isLoading || error ? (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      {error && <Error />}
      {isLoading && <Spinner />}
    </div>
  ) : (
    <div className="overflow-auto h-screen">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-2 pl-4 pr-3 gap-2  mb-12">
        {itemList &&
          itemList.map((model) => (
            <AgencyItem model={model} key={model.id} navigate={navigate} />
          ))}
      </div>
    </div>
  );
};

const AgencyItem = ({ model, navigate }) => {
  // const {
  //   data: image,
  //   isLoading,
  //   error,
  // } = useFetch(model.image, {
  //   method: "GET",
  // });
  const navigationContext = useContext(NavigationContext);

  function handleOnClick(agencyId,isVisualization){
    if(isVisualization){
      // navigationContext.setNavData({color:"bg-nav-national",title:"Indicators"});
      // navigate("/indicators?agencyId=" + agencyId);
      navigate("/agencies/landingPage/"+agencyId);
    }
    else{
    // navigationContext.setNavData({color:"bg-nav-national",title:"Agency Indicators"});
    navigate("/agencies/" + agencyId);
  }
  }

  return (
    <CardContainer
      child={
        <div className="flex flex-col justify-center w-full h-full">
          <img
            src={logos[model.id]}
            width={180}
            height={180}
            alt="Agency Logo"
            className="self-center my-3"
          />
          <h3 className="text-center text-xl font-bold">{model.agencyName}</h3>
          <p className="text-sm h-full">{model.description}</p>
          <div className="flex w-full space-x-3 justify-between">
          <button
            onClick={() => handleOnClick(model.id,false)}
            className="bg-black text-white mt-2 rounded-md py-2 px-4"
          >
            View Indicator Manual
          </button>
          <button
            onClick={() => handleOnClick(model.id,true)}
            className="bg-green-500 text-white mt-2 rounded-md py-2 px-4"
          >
            Visualize Indicators
          </button>
          </div>
        </div>
      }
    />
  );
};

export default Agencies;
