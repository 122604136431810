import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import CardContainer from "../../../CardContainer";
import Spinner from "../../../../Spinner/Spinner";
import NoData from "../../../NoData";
import { useLocation } from "react-router-dom";
import { useContext, useRef } from "react";
import { AiOutlineFullscreen } from "react-icons/ai";
import FullScreenModal from "../../../FullScreenModal";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

var options = {
  plugins: {
    // title: {
    //   display: true,
    //   text: 'Chart.js Bar Chart - Stacked',
    // },
    // label: function(item,data){
    //   return data+" %";
    // },
    datalabels: {
      formatter: (value, context) => {
        return value + "%";
      },
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "600",
        size: 12,
      },
    },
    tooltip: {
      xAlign: "center",
      yAlign: "bottom",
      backgroundColor: "#000000",
      bodyAlign: "center",
      callbacks: {
        // beforeBody: (context) => {
        //   console.log('before body: ', context)
        //   return 'before body'
        // },
        // title: (context) => {
        //   console.log('title context: ', context)
        //   return 'title'
        // },
        // beforeLabel: (context) => {
        //   console.log('before label: ', context)
        //   return 'before label'
        // },
        label: (context) => {
          return context.dataset.label + ": " + context.raw + "%";
        },
      },
    },

    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 13,
        },
      },
    },
  },

  responsive: true,
  scales: {
    x: {
      stacked: false,
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
      },
    },
    y: {
      stacked: false,
      display: true,
      // title: {
      //   display: true,
      //   text: "PERCENTAGE",
      //   color: "#000",
      // },
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 14,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
        callback: function (value, index, values) {
          return value + "%";
        },
      },
      // padding: { top: 30, left: 0, right: 0, bottom: 0 },
    },
  },
};

//data structure
// const labels = ["KG", "PRIMARY", "JHS"];
// const data = {
//   labels,
//   datasets: [
//     {
//       label: "Female-Current",
//       data: [112.6, 99.5, 84.1],
//       backgroundColor: "rgb(255, 99, 172)",
//       // stack: "0",
//     },
//     // {
//     //   label: "Female-Target",
//     //   data: [120, 100, 100],
//     //   backgroundColor: "rgb(239, 68, 68)",
//     //   // stack: "0",
//     // },
//     {
//       label: "Male-Current",
//       data: [109.2, 97.2, 82.3],
//       backgroundColor: "rgb(53, 162, 210)",
//       // stack: "1",
//     },
//     // {
//     //   label: "Male-Target",
//     //   data: [120, 100, 100],
//     //   backgroundColor: "rgb(29, 78, 216)",
//     //   // stack: "1",
//     // },
//     {
//       label: "Total",
//       data: [120, 98.3, 90],
//       backgroundColor: "rgb(60, 150, 29)",

//       // stack: "2",
//     },
//   ],
// };

const ByLevel = ({ selectedIndicator, selectedYear, data: chartData }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  let indicatorId = query.get("indicatorId");

  if (indicatorId === "14") {
    delete options.scales.y.ticks.callback;
    options.plugins.datalabels.formatter = (value, context) => {
      return value.toLocaleString();
    };
    options.plugins.tooltip.callbacks.label = (context) => {
      return context.dataset.label + ": " + context.raw.toLocaleString();
    };
  }

  let levelData = chartData.levelTrends.filter(
    (item) => item.keyIndicatorName !== "PRESCHOOL"
  );

  var labels = levelData.map((item) => item.keyIndicatorName);
  let finalData = {};

  let datasets = [
    {
      label: "Females",
      data: [],
      backgroundColor: "rgb(255, 99, 172)",
    },
    {
      label: "Male",
      data: [],
      backgroundColor: "rgb(53, 162, 210)",
    },
    {
      label: "Main Value/Total",
      data: [],
      backgroundColor: "rgb(60, 150, 29)",
    },
  ];

  if (levelData.length > 0) {
    let tempLabel = levelData[0].mainValueBreakdown.map(
      (item) => item.nameofBreakdown
    );
    datasets[0].label = tempLabel[0];
    datasets[1].label = tempLabel[1];

    for (const level of levelData) {
      let value1 = level.mainValueBreakdown.find(
        (item) => item.nameofBreakdown === tempLabel[0]
      ).value;
      let value2 = level.mainValueBreakdown.find(
        (item) => item.nameofBreakdown === tempLabel[1]
      ).value;
      datasets[0].data.push(Number(value2.replace(/,/g, "")));
      datasets[1].data.push(Number(value1.replace(/,/g, "")));
      datasets[2].data.push(Number(level.mainValue.replace(/,/g, "")));
    }
  }
  finalData.datasets = datasets;
  finalData.labels = labels;

  const modalRef = useRef();

  return (
    <CardContainer
      // bgColor="bg-blue-200"
      child={
        <>
          {(!selectedIndicator || levelData.length < 1) && <NoData />}
          <FullScreenModal
            title={`${selectedIndicator.indicatorName} By Level:`}
            ref={modalRef}
          >
            <Body
              isFullScreen={true}
              selectedIndicator={selectedIndicator}
              modalRef={modalRef}
              levelData={levelData}
              finalData={finalData}
              selectedYear={selectedYear}
            />
          </FullScreenModal>
          <Body
            isFullScreen={false}
            selectedIndicator={selectedIndicator}
            modalRef={modalRef}
            levelData={levelData}
            finalData={finalData}
            selectedYear={selectedYear}
          />
        </>
      }
    />
  );
};

const Body = ({
  isFullScreen,
  selectedIndicator,
  modalRef,
  finalData,
  selectedYear,
  levelData,
}) => {

  return (
    <>
      {selectedIndicator && levelData.length > 0 && (
        <div className="flex flex-col">
          <div className="w-full h-full flex justify-between">
            {!isFullScreen && (
              <h3 className="title-md mb-5">
                {selectedIndicator.indicatorName +
                  " By Level (" +
                  selectedYear +
                  "):"}
              </h3>
            )}
            {!isFullScreen && (
              <AiOutlineFullscreen
                size={28}
                className="ml-2 border border-gray-600 rounded-md"
                title="Fullscreen"
                onClick={() => modalRef.current.open()}
              />
            )}
          </div>
          <Bar options={
              isFullScreen
                ? {
                    ...options,
                    plugins: {
                      ...options.plugins,
                      datalabels: {
                        ...options.plugins.datalabels,
                        font: {
                          ...options.plugins.datalabels.font,
                          size: 35,
                          weight: "bold",
                        },
                      },
                    },
                  }
                : options
            } data={finalData} />
        </div>
      )}
    </>
  );
};

export default ByLevel;
