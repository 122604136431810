import React from "react";
import Constants from "../../../../util/Constants";
import useFetch from "../../../../util/useFetch";
import CardContainer from "../../../CardContainer";
import PageLoader from "../../../PageLoader";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { useNavigate, useParams } from "react-router-dom";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  ChartDataLabels,
  Legend
);

const bigPieOPtions = {
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        // console.log('formatter here,,...', value);
        return value.toLocaleString();
      },
      // labels: {
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "bold",
        size: 25,
      },
      // },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: 16,
        },
      },
    },
  },
  maintainAspectRatio: true,
  responsive: true,
  rotation: 180,
};

const SecondCard = ({ indicator }) => {
  
  const indicatorId = indicator.id;
  const navigate = useNavigate();
  const params = useParams();
  var latestYear,
    term = "0";
  const yearsTermsAvailable = indicator.yearsTermsAvailable;
  if (yearsTermsAvailable) {
    if (yearsTermsAvailable.includes(","))
      latestYear = yearsTermsAvailable.split(",").pop();
    //to get the latest year
    else latestYear = yearsTermsAvailable;
    if (latestYear.includes(":")) {
      //get the term
      term = latestYear.split(":").pop();
      //get the year
      latestYear = latestYear.split(":")[0];
    }
  } else {
    latestYear = "2019";
    term = "0";
  }
  // console.log(yearsTermsAvailable);
  // console.log(term);
  const {
    data: breakdownData,
    isLoading,
    error,
  } = useFetch(
    `/nationalKeyIndicators/${indicator.id}&year=${latestYear}&term=${term}`,
    {
      method: "GET",
    },
    Constants.ApiCallerIndicatorBreakdown
  );

  // console.log(latestYear);

  const {
    data: overviewData,
    isLoading: isLoadingPie,
    error: pieError,
  } = useFetch(
    `/nationalMainValue/${indicatorId}&year=${latestYear}&term=${term}`,
    {
      method: "GET",
    },
    Constants.ApiCallerOverviewPieChart
  );

  // console.log("overviewData", overviewData);
  
  return (
     <CardContainer
      child={
        indicator ?    <>
                <div className="w-full">
                  <div className="flex justify-between">
                    <h3 className="text-2xl font-bold mb-5">
                      {indicator ? indicator.indicatorName : '' }  {(breakdownData || overviewData) ? "("+latestYear+")" : ""}
                    </h3>
                    {(breakdownData || overviewData) && <button
                      onClick={() => {
                        navigate(
                          "/indicators?agencyId=" +
                            params.id +
                            "&indicatorId=" +
                            indicatorId +
                            "&year=" +
                            latestYear +
                            "&term=" +
                            term
                        );
                      }}
                      className="rounded-lg p-2 text-white bg-green-500 h-10 w-32"
                    >
                      More Info
                    </button>}
                  </div>
                </div>
              
          <PageLoader
            showNoDataAsError={true}
            isLoading={isLoading}
            error={error}
            isPage={false}
          >
            <>
            
              {breakdownData && (
                <div className="w-full">
                  <Bar
                    // width={400}
                    // height={400}
                    options={breakdownData.options}
                    data={breakdownData}
                  />
                  {/*//TODO: check the for errors and no data*/}
                </div>
              )}
            </>
          </PageLoader>
          <PageLoader
            showNoDataAsError={true}
            isLoading={isLoadingPie}
            error={pieError}
            isPage={false}
          >
            {overviewData && overviewData.mainValue !== "0" && (
              <>
                <div className="flex lg:justify-between flex-col lg:flex-row">
                  <h3 className="title-md mb-5">
                    Current Value({latestYear}): {overviewData.mainValue}
                  </h3>
                  <h3 className="title-md mb-5">
                    Previous Value({Number(latestYear) - 1}):{" "}
                    {overviewData.previousYearValue}
                  </h3>
                </div>
                <div className="flex flex-col items-center w-full">
                  <div className="w-3/6">
                    <Pie
                      //   height={300}
                      //   width={300}
                      data={overviewData.data}
                      options={bigPieOPtions}
                    />
                  </div>
                </div>
              </>
            )}
          </PageLoader>
        </> : <></>
      }
    />
  );
};

export default SecondCard;
