import { React, useMemo,useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CardContainer from "../../../CardContainer";
import MapView from "../../../MapView";
import schoolImg from "../../../../assets/images/school.png";
import useFetch from "../../../../util/useFetch";
import Constants from "../../../../util/Constants";
import PageLoader from "../../../PageLoader";
import SimpleList from "../../../SimpleList";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import TableSearchBar from "../../../TableSearchBar";


const MSRCPage = ({agency}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [selectedRegion, setSelectedRegion] = useState();

  useEffect(() => {
    if (query) {
      const regionName = query.get("regionName");
      const regionID = query.get("regionID");
      if (regionName && regionID)
        setSelectedRegion({ regionName: regionName, regionID: regionID });
    }
  }, [query.toString()]);

  function onSelectRegion(region) {
    if (region) {
      query.set("regionName", region.regionName);
      query.set("regionID", region.regionID);
      navigate("/agencies/landingPage/2?" + query.toString());
    }
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-2 mt-5 pr-2 pb-16">
      <CardContainer
        child={
          <div>
            <h3 className="text-2xl font-bold text-center mb-3">
              Please select a region
            </h3>
            <MapView onSelectRegion={onSelectRegion} />
          </div>
        }
      />
      <CardContainer
        child={
          <div>
            {selectedRegion && <h3 className="text-2xl font-bold text-center mb-3">
              List of mSRC Schools ({selectedRegion.regionName})
            </h3>}
            {!selectedRegion && (
              <div className="flex flex-col items-center justify-center">
              <h3 className="text-2xl font-bold text-center mt-3 mb-20">
                PLEASE SELECT A REGION TO VIEW LIST OF mSRC SCHOOLS
              </h3>
              <img
                  width={300}
                  height={300}
                  src={schoolImg}
                  alt="school"
                />
              </div>
            )}
            {selectedRegion && <SchoolTableWrapper agency={agency} selectedRegion={selectedRegion}/>}
          </div>
        }
      />
    </div>
  );
};


const COLUMNS = [
  // {
  //   Header: "No.",
  // },
  {
    Header: "No.",
    id: "row",
    maxWidth: 40,
    filterable: false,
    Cell: (row) => {
        return <p>{Number(row.row.id)+1}</p>;
    }
},
  {
    Header: "School Name",
    accessor: "nameOfSchool",
  },
  {
    Header: "Actions",
  },
];

const SchoolTableWrapper = ({selectedRegion,agency}) =>{
  const {
    data,
    isLoading,
    error,
  } = useFetch(
    '/schoolManuals/minimal&only_msrc=true&region='+selectedRegion.regionID,
    {
      method: "GET",
    },
    Constants.ApiCallerMSRCSchools
  );
  return <div>
      <PageLoader isPage={false} isLoading={isLoading} error={error}>
        {data && <SchoolTable agency={agency} schoolList={data} titleKey='nameOfSchool'/>}
      </PageLoader>
  </div>
}


const SchoolTable = ({schoolList,agency}) => {
  const navigate = useNavigate();

  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => schoolList,[]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows, //if you use rows it will show you full list without pagination
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    page, //if you use page page will put it in pages, thus ten items in a page
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {pageSize: 15}
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex } = state;
  return ( <div>
    <TableSearchBar filter={globalFilter} setFilter={setGlobalFilter} />
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup, index) => {
          return (
            <tr {...headerGroup.getFooterGroupProps()} key={index}>
              {headerGroup.headers.map((column, index2) => (
                <th {...column.getHeaderProps()} key={index2}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          );
        })}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <tr key={index}>
              {row.cells.map((cell, index2) => {
                const originalData = cell.row.original;
                const columnHeader = cell.column.Header;
                return (
                  <td {...cell.getCellProps()} key={index2}>
                    {columnHeader === "Actions" ? (
                      <div className="flex space-x-3">
                        <button
                          onClick={() => {
                           navigate('/masterFacility/schoolDetails?schoolID='+originalData.school_id+'&agencyName='+agency.agencyName+'&emisCode='+originalData.emis_code+'&msrcId='+(originalData.msrcId ?? 0));
                        }}
                          className="text-blue-500"
                        >
                          Visualize Indicators
                        </button>
                       
                      </div>
                    ) : (
                      cell.render("Cell")
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
    <div className="flex justify-center items-center space-x-5 space-y-2 lg:space-y-0 xl:space-y-0 2xl:space-y-0 p-3 flex-wrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap">
      <button
        onClick={() => gotoPage(0)}
        className="bg-black text-white rounded-lg px-3 py-1 mt-2 lg:mt-0 xl:mt-0 2xl:mt-0"
        disabled={!canPreviousPage}
      >
        {"<<"}
      </button>
      <button
        className="bg-black text-white rounded-lg px-2 py-1"
        disabled={!canPreviousPage}
        onClick={() => previousPage()}
      >
        {"<"} Previous
      </button>
      <span>
        Page{" "}
        <strong>
          {pageIndex + 1} of {pageOptions.length}
        </strong>
      </span>
      <span>
        | &nbsp;&nbsp;&nbsp;
        <input
          placeholder="Go to page"
          className="px-2 py-1 rounded-lg bg-white bg-opacity-80 w-36 border-black border-2"
          onChange={(e) => {
            var pageNumber = e.target.value ? Number(e.target.value) : 0;
            pageNumber = pageNumber >= 1 ? pageNumber - 1 : pageNumber;
            gotoPage(pageNumber);
          }}
        />
      </span>
      <button
        className="bg-black text-white rounded-lg px-3 py-1"
        disabled={!canNextPage}
        onClick={() => nextPage()}
      >
        Next {">"}
      </button>
      <button
        onClick={() => gotoPage(pageCount - 1)}
        className="bg-black text-white rounded-lg px-3 py-1"
        disabled={!canNextPage}
      >
        {">>"}
      </button>
    </div>
  </div>);
}


export default MSRCPage;
