import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import NoData from "../../../NoData";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const options = {
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        // console.log('formatter here,,...', value);
        return (
          context.chart.data.labels[context.dataIndex] +
          ":\n" +
          value.toLocaleString()
        );
      },
      // labels: {
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "bold",
        size: 18,
      },
      // },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "600",
          size: 12,
        },
      },
    },
  },
  maintainAspectRatio: true,
  responsive: true,
};

const data = {
  labels: ["KG", "PRIMARY", "JHS"],
  datasets: [
    {
      label: "# of Votes",
      data: [26187, 26276, 18334],
      backgroundColor: [
        "rgb(255, 206, 86)",
        "rgb(153, 102, 255)",

        "rgb(75, 192, 192)",
        "rgb(54, 162, 235)",
        "rgb(255, 159, 64)",
        "rgb(255, 99, 132)",
      ],
      // borderColor: [
      //   'rgb(255, 99, 132, 1)',
      //   'rgb(54, 162, 235, 1)',
      //   'rgb(255, 206, 86, 1)',
      //   'rgb(75, 192, 192, 1)',
      //   'rgb(153, 102, 255, 1)',
      //   'rgb(255, 159, 64)',
      // ],
      // borderWidth: 1,
    },
  ],
};

const NumberOfSchools = ({ data: chartData }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  let labels = Object.keys(chartData)
    .filter(
      (item) =>
        item.includes("JHS") || item.includes("KG") || item.includes("Primary")
    ).map((item) => item.replaceAll("Total_With_", ""));
  let derivedData = {
    labels: labels,
    datasets: [
      {
        data: [
          Number(chartData.Total_With_Primary.replace(/,/g, "")),
          Number(chartData.Total_With_KG.replace(/,/g, "")),
          Number(chartData.Total_With_JHS.replace(/,/g, "")),

        ],
        backgroundColor: [
          "rgb(255, 206, 86)",
          "rgb(153, 102, 255)",
          "rgb(75, 192, 192)",
          "rgb(54, 162, 235)",
          "rgb(255, 159, 64)",
          "rgb(255, 99, 132)",
        ],
      },
    ],
  };

  return (
    <>
      {Object.keys(chartData).length < 1 && <NoData />}
      {Object.keys(chartData).length > 0 && (
        <div>
          <h3 className="text-center text-xl font-bold mb-2">
            Number of Basic Schools
          </h3>

          <h3 className="text-center text-2xl font-bold mb-2">{chartData.Total}</h3>
          <Pie height={400} width={400} data={derivedData} options={options} />
          <p className="font-semibold text-sm text-center">
            Source: SRIM(EMIS) {query.get('year') ?? ''} *Includes Multi-Level Schools
          </p>
        </div>
      )}
    </>
  );
};

export default NumberOfSchools;
