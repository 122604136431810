import { useEffect, useState } from "react";
import Spinner from "../../../../Spinner/Spinner";
import Constants from "../../../../util/Constants";
import Dropdown from "../../../dropdown/Dropdown";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";


const districtTypeList = ["DEPRIVED", "NON DEPRIVED"];

const AddEditSchoolModal = ({ schoolInfo, setIsAddingSchool,isAddingSchool, onCancel }) => {
  const [districtList, setDistrictList] = useState();
  const [circuitList, setCircuitList] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [schoolName, setSchoolName] = useState();
  const [hasKG, setHasKG] = useState();
  const [hasPrimary, setHasPrimary] = useState();
  const [hasJHS, setHasJHS] = useState();
  const [schoolCode, setSchoolCode] = useState();
  const [emisCode, setEmisCode] = useState();
  const [city, setCity] = useState();
  const [locality, setLocality] = useState();
  const [telephoneNumber, setTelephoneNumber] = useState();
  const [enrolmentMaleCount, setEnrolmentMaleCount] = useState();
  const [enrolmentFemaleCount, setEnrolmentFemaleCount] = useState();
  const [nameOfHeadMaster, setNameOfHeadMaster] = useState();
  const [headOfSchoolPhoneNumber, setHeadOfSchoolPhoneNumber] = useState();
  const [kgHeadName, setKgHeadName] = useState();
  const [kgHeadNumber, setKgHeadNumber] = useState();
  const [primaryHeadName, setPrimaryHeadName] = useState();
  const [primaryHeadPhone, setPrimaryHeadPhone] = useState();
  const [jhsHeadName, setJhsHeadName] = useState();
  const [jhsHeadPhone, setJhsHeaPhone] = useState();
  const [poBox, setPoBox] = useState();
  const [ghanaPostGPS, setGhanaPostGPS] = useState();
  const [gpsCoordinates, setGpsCoordinates] = useState();
  const [isPrivateSchool, setIsPrivateSchool] = useState();
  const [isUrbanSchool, setIsUrbanSchool] = useState();
  const [hasShiftSystem, setHasShiftSystem] = useState();
  const [selectedRegion, setSelectedRegion] = useState();
  const [selectedDistrict, setSelectedDistrict] = useState();
  const [selectedCircuit, setSelectedCircuit] = useState();
  const [districtType, setDistrictType] = useState();
  const [showDistrictDropDown, setShowDistrictDropDown] = useState(true);
  const [showCircuitDropDown, setShowCircuitDropDown] = useState(true);

  useEffect(() => {
      if(!isAddingSchool && schoolInfo){
        //is editing school
        setSchoolName(schoolInfo.nameOfSchool);
        setHasKG(schoolInfo.levelOfEducationKG === 'YES' ? true : false);
        setHasPrimary(schoolInfo.levelOfEducationPRIMARY === 'YES' ? true :false);
        setHasJHS(schoolInfo.levelOfEducationJHS === 'YES' ? true : false);
        setSchoolCode(schoolInfo.schoolCode);
        setEmisCode(schoolInfo.emisCode);
        setCity(schoolInfo.city);
        setLocality(schoolInfo.suburb);
        setTelephoneNumber(schoolInfo.telephoneNumber);
        setEnrolmentMaleCount(schoolInfo.totalMales);
        setEnrolmentFemaleCount(schoolInfo.totalFemales);
        setNameOfHeadMaster(schoolInfo.nameOfHeadmaster);
        setHeadOfSchoolPhoneNumber(schoolInfo.headPhoneNumber);
        setKgHeadName(schoolInfo.kg_name_of_head);
        setKgHeadNumber(schoolInfo.kg_head_number);
        setPrimaryHeadName(schoolInfo.primary_name_of_head);
        setPrimaryHeadPhone(schoolInfo.primary_head_number);
        setJhsHeadName(schoolInfo.jhs_name_of_head);
        setJhsHeaPhone(schoolInfo.jhs_head_number);
        setPoBox(schoolInfo.ghanaPostAddress);
        setGhanaPostGPS(schoolInfo.ghanaPostGPS);
        setGpsCoordinates(schoolInfo.gpsCordinates);
        setIsPrivateSchool(schoolInfo.school_type === 'PUBLIC' ? false : true);
        setIsUrbanSchool(schoolInfo.schoolUrbanRural === 'URBAN' ? true : false);
        setHasShiftSystem(schoolInfo.shift === true ? true: false);
        setShowDistrictDropDown(false);
        setShowCircuitDropDown(false);
        setSelectedRegion(Constants.regionList.find(rg => rg.regionID == schoolInfo.region));
        setSelectedDistrict({districtID: schoolInfo.districtId,districtName: schoolInfo.district});
        setSelectedCircuit({circuitName: schoolInfo.circuitName,circuitID: schoolInfo.circuit});
        setDistrictType(schoolInfo.districtType);
      }else{
        setSchoolName(null);
        setHasKG(false);
        setHasPrimary(false);
        setHasJHS(false);
        setSchoolCode(null);
        setEmisCode(null);
        setCity(null);
        setLocality(null);
        setTelephoneNumber(null);
        setEnrolmentMaleCount(null);
        setEnrolmentFemaleCount(null);
        setNameOfHeadMaster(null);
        setHeadOfSchoolPhoneNumber(null);
        setKgHeadName(null);
        setKgHeadNumber(null);
        setPrimaryHeadName(null);
        setPrimaryHeadPhone(null);
        setJhsHeadName(null);
        setJhsHeaPhone(null);
        setPoBox(null);
        setGhanaPostGPS(null);
        setGpsCoordinates(null);
        setIsPrivateSchool(false);
        setIsUrbanSchool(false);
        setHasShiftSystem(false);
        setSelectedRegion(null);
        setSelectedDistrict(null);
        setSelectedCircuit(null);
        setDistrictType(null);
      }
  }, [isAddingSchool,schoolInfo]);


  const handleSave = (event) => {
      event.preventDefault();
    if(!schoolName){
        toastError('Please enter school name');
    }else if(!hasKG && !hasPrimary && !hasJHS){
      toastError('Please select at least one level');
    }else if(!selectedRegion){
        toastError('Please select region');
    }else if(!selectedDistrict){
        toastError('Please select district')
    }else if(!selectedCircuit){
        toastError('Please select circuit');
    }
    else if(!districtType){
        toastError('Please select district type');
    }
    else{
      const schoolData = {
        // id: isAddingSchool ? 0 :  schoolInfo.id,
        nameOfSchool: schoolName,
        levelOfEducationKG: hasKG ? 'YES' : 'NO',
        levelOfEducationPRIMARY:  hasPrimary ? 'YES':'NO',
        levelOfEducationJHS: hasJHS ? 'YES' : 'NO',
        schoolCode: schoolCode,
        emisCode: emisCode,
        city: city,
        suburb: locality ?? '',
        telephoneNumber: telephoneNumber,
        totalMales: Number(enrolmentMaleCount),
        totalFemales: Number(enrolmentFemaleCount),
        totalMalesAndFemales: Number(enrolmentMaleCount)+Number(enrolmentFemaleCount),
        nameOfHeadmaster: nameOfHeadMaster,
        headPhoneNumber: headOfSchoolPhoneNumber,
        kg_name_of_head: kgHeadName ?? '',
        kg_head_number: kgHeadNumber ?? '',
        primary_name_of_head: primaryHeadName ?? '',
        primary_head_number: primaryHeadPhone ?? '',
        jhs_name_of_head: jhsHeadName ?? '',
        jhs_head_number: jhsHeadPhone ?? '',
        ghanaPostAddress: poBox,
        ghanaPostGPS: ghanaPostGPS ?? '', 
        gpsCordinates: gpsCoordinates ?? '',
        school_type: isPrivateSchool ? 'PRIVATE' : 'PUBLIC',
        schoolUrbanRural: isUrbanSchool ? 'URBAN' : 'RURAL',
        localityType: isUrbanSchool ? 'URBAN' : 'RURAL',
        shift: hasShiftSystem,
        districtType: districtType,
        circuit: selectedCircuit.circuitID,
        circuitName: selectedCircuit.circuitName,
        district: selectedDistrict.districtName,
        districtId: selectedDistrict.districtID,
        region: selectedRegion.regionID,
      };
      setIsLoading(true);
      console.log(schoolData);
      fetch(Constants.BaseUrl + "/save_update_school_manual/", {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(schoolData),
        headers: { "Content-Type": "application/json" },
      })
        .then((res) => {
          setIsLoading(false);
          if (!res.ok) {
            throw Error(
              "An error occurred while saving, please try again later"
            );
          }
          return res.json();
        })
        .then((result) => {
          toast.info("Saved Successfully", {
            position: "top-center",
          });
          console.log('res',result);
        })
        .catch((err) => {
          toast.error(err.message, {
            position: "top-center",
          });
        });
    }
  };

  async function onRegionSelect(region){
    const regionObj = Constants.regionList.find(rg => rg.regionName === region);
    setSelectedRegion(regionObj);
    setDistrictList(null);
    setCircuitList(null);
    setSelectedDistrict(null);
    const districtResponse = await fetch(
        Constants.BaseUrl  + `/retrieve_region_district/&region=${regionObj.regionID}`,{
        credentials: "include",});
        if(districtResponse.ok){
            const res = await districtResponse.json();
            setDistrictList(res.districtList);
        }else{
            toastError('Unable to load districts, please try later');
        }
  }

  function onDistrictTypeSelect(type){
    setDistrictType(type);
  }
  
  function toastError(message) {
    toast.error(message, {
      position: "top-center",
    });
  }

  async function onDistrictSelect(dsName){
      const dsObj = districtList.find( ds => ds.districtName === dsName);
      setSelectedDistrict(dsObj);
      setCircuitList(null);
      const circuitResponse = await fetch(
        Constants.BaseUrl  + `/retrieve_district_circuit/&district=${dsObj.districtID}`,{
        credentials: "include",});
        if(circuitResponse.ok){
            const res = await circuitResponse.json();            
            setCircuitList(res.circuitList);
        }else{
            toastError('Unable to load districts, please try later');
        }
  }

  function onCircuitSelect(circuitName){
    const ctObj = circuitList.find(ct => ct.circuitName === circuitName);
    setSelectedCircuit(ctObj);
  }

  return (
    <div
      id="add-edit-modal"
      className="fixed hidden inset-0 p-5 md:p-0 lg:p-0 xl:p-0 2xl:p-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
    >
      <div className="relative top-20 mx-auto p-5 border md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full shadow-lg rounded-xl bg-white">
        <div className="flex justify-between">
          <h3 className="title-md">
            {!isAddingSchool ? "Edit" : "Add"} School
          </h3>
          <button
            type="button"
            onClick={() => {
              //   setSelectedIndicator(null);
              onCancel();
              setIsAddingSchool(!isAddingSchool); //to make the useEffect run again and set the school details to null
            }}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {/* {isLoading && <Spinner />} */}
        {isLoading ? <Spinner/> : <form className="px-4 pb-4 space-y-3 mt-3" onSubmit={handleSave}>
          <div>
            <label
              htmlFor="school-name"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              School Name
            </label>
            <input
              type="text"
              onChange={(e) => setSchoolName(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={schoolName ?? ""}
              required
            />
          </div>
          <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
            Levels:
          </label>
          <div className="flex flex-wrap space-x-3">
            <div className="flex">
              <input
                type="checkbox"
                name="checkbox"
                id="kindergarten-checkbox"
                  checked={hasKG}
                className="m-1"
                  onChange={(e) => {
                    setHasKG(e.target.checked);
                  }
                }
                // value={}
              />
              <label
                htmlFor="kindergarten-checkbox"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Kindergarten
              </label>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                name="checkbox"
                id="primary-checkbox"
                  checked={hasPrimary}
                className="m-1"
                  onChange={(e) => {
                   setHasPrimary(e.target.checked);
                  }}
                // value={}
              />
              <label
                htmlFor="primary-checkbox"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Primary
              </label>
            </div>
            <div className="flex">
              <input
                type="checkbox"
                name="checkbox"
                id="jhs-checkbox"
                  checked={hasJHS}
                className="m-1"
                  onChange={(e) => {
                    setHasJHS(e.target.checked);
                  }}
                // value={}
              />
              <label
                htmlFor="jhs-checkbox"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                JHS
              </label>
            </div>
          </div>
          <div>
            <label
              htmlFor="school-code"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              School Code
            </label>
            <input
              type="text"
              onChange={(e) => setSchoolCode(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={schoolCode ?? ""}
              required
            />
          </div>
          <div>
            <label
              htmlFor="emis-code"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              EMIS Code
            </label>
            <input
              type="text"
              onChange={(e) => setEmisCode(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={emisCode ?? ""}
              required
            />
          </div>
          <div>
            <label
              htmlFor="city"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              City
            </label>
            <input
              type="text"
              onChange={(e) => setCity(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={city ?? ""}
              required
            />
          </div>
          <div>
            <label
              htmlFor="suburb"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Locality
            </label>
            <input
              type="text"
              onChange={(e) => setLocality(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={locality ?? ""}
            />
          </div>
          <div>
            <label
              htmlFor="telephone-number"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Telephone Number
            </label>
            <input
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setTelephoneNumber(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={telephoneNumber ?? ""}
              required
            />
          </div>
          <div>
            <label
              htmlFor="jhs-head-details"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Enrolment: Males and Females
            </label>
            <div className="flex space-x-5">
              <input
                type="text"
                placeholder="Number of Males"
                onChange={(e) => setEnrolmentMaleCount(e.target.value)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={enrolmentMaleCount ?? ""}
                required
              />
              <input
                type="text"
                placeholder="Number of Females"
                onChange={(e) => setEnrolmentFemaleCount(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={enrolmentFemaleCount ?? ""}
                required
              />
            </div>
          </div>
          <div>
            <label
              htmlFor="name-of-head-master"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Name of Head Master
            </label>
            <input
              type="text"
              onChange={(e) => setNameOfHeadMaster(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={nameOfHeadMaster ?? ""}
              required
            />
          </div>
          <div>
            <label
              htmlFor="suburb"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Head of School Phone Number
            </label>
            <input
              type="text"
              onKeyPress={(event) => {
                if (!/[0-9]/.test(event.key)) {
                  event.preventDefault();
                }
              }}
              onChange={(e) => setHeadOfSchoolPhoneNumber(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={headOfSchoolPhoneNumber ?? ""}
              required
            />
          </div>
          {hasKG && <div>
            <label
              htmlFor="kj-head-details"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Kindergarten Head Details
            </label>
            <div className="flex space-x-5">
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setKgHeadName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={kgHeadName ?? ""}
                required
              />
              <input
                type="text"
                placeholder="Phone"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setKgHeadNumber(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={kgHeadNumber ?? ""}
                required
              />
            </div>
          </div>}
         {hasPrimary && <div>
            <label
              htmlFor="primary-head-details"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Primary Head Details
            </label>
            <div className="flex space-x-5">
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setPrimaryHeadName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={primaryHeadName ?? ""}
                required
              />
              <input
                type="text"
                placeholder="Phone"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setPrimaryHeadPhone(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={primaryHeadPhone ?? ""}
                required
              />
            </div>
          </div>}
         {hasJHS && <div>
            <label
              htmlFor="jhs-head-details"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              JHS Head Details
            </label>
            <div className="flex space-x-5">
              <input
                type="text"
                placeholder="Name"
                onChange={(e) => setJhsHeadName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={jhsHeadName ?? ""}
                required
              />
              <input
                type="text"
                placeholder="Phone"
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setJhsHeaPhone(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={jhsHeadPhone ?? ""}
                required
              />
            </div>
          </div>}
          <div>
            <label
              htmlFor="telephone-number"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Post Office Box
            </label>
            <input
              type="text"
              onChange={(e) => setPoBox(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={poBox ?? ""}
              required
            />
          </div>
          <div>
            <label
              htmlFor="ghana-post-gps"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Ghana Post GPS
            </label>
            <input
              type="text"
              onChange={(e) => setGhanaPostGPS(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={ghanaPostGPS ?? ""}
            />
          </div>
          <div>
            <label
              htmlFor="gps-coordinates"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              GPS Coordinates
            </label>
            <input
              type="text"
              onChange={(e) => setGpsCoordinates(e.target.value)}
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
              value={gpsCoordinates ?? ""}
            />
          </div>
          <div>
            <label
              htmlFor="isKey-indicator-no-radio"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Is School Public or Private?
            </label>
            <div className="flex space-x-3">
              <div className="flex">
                <input
                  type="radio"
                  checked={!isPrivateSchool}
                  id="public-radio"
                  className="m-1"
                  onChange={() => setIsPrivateSchool(false)}
                  // value={}
                />
                <label
                  htmlFor="public-radio"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  Public
                </label>
              </div>
              <div className="flex">
                <input
                  type="radio"
                  // name=""
                  id="private-radio"
                  className="m-1"
                  checked={isPrivateSchool}
                  onChange={() => setIsPrivateSchool(true)}
                  // value={}
                />
                <label
                  htmlFor="private-radio"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  Private
                </label>
              </div>
            </div>
          </div>
          <div>
            <label
              htmlFor="isKey-indicator-no-radio"
              className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              Is School Urban or Rural?
            </label>
            <div className="flex space-x-3">
              <div className="flex">
                <input
                  type="radio"
                  checked={isUrbanSchool}
                  id="urban-radio"
                  className="m-1"
                  onChange={() => setIsUrbanSchool(true)}
                  // value={}
                />
                <label
                  htmlFor="urban-radio"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  Urban
                </label>
              </div>
              <div className="flex">
                <input
                  type="radio"
                  // name=""
                  id="rural-radio"
                  className="m-1"
                  checked={!isUrbanSchool}
                  onChange={(e) => setIsUrbanSchool(false)}
                  // value={}
                />
                <label
                  htmlFor="rural-radio"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  Rural
                </label>
              </div>
            </div>
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              Does School Accommodate Shift System?
            </label>
            <div className="flex space-x-3">
              <div className="flex">
                <input
                  type="radio"
                  checked={hasShiftSystem}
                  id="yes-shift-radio"
                  className="m-1"
                  onChange={() => setHasShiftSystem(true)}
                  // value={}
                />
                <label
                  htmlFor="yes-shift-radio"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  Yes
                </label>
              </div>
              <div className="flex">
                <input
                  type="radio"
                  // name=""
                  id="no-shift-radio"
                  className="m-1"
                  checked={!hasShiftSystem}
                  onChange={() => setHasShiftSystem(false)}
                  // value={}
                />
                <label
                  htmlFor="no-shift-radio"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  No
                </label>
              </div>
            </div>
          </div>
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              Select Region
            </label>
            <Dropdown
              className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
              placeHolder="Select One"
              values={Constants.regionList.map(rg => rg.regionName)}
              // itemKey='regionName'
              selected={selectedRegion && selectedRegion.regionName}
              onSelect={onRegionSelect}
            />
          </div>
          {!showDistrictDropDown ? <div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              Selected District:
            </label>
            <div className="flex">
                <h3>
                    {selectedDistrict ? (selectedDistrict.districtName ?? "None") : "None"}
                </h3>
                <button onClick={(e)=>{
                    e.preventDefault();
                    setShowDistrictDropDown(true);
                    if(selectedRegion)
                    onRegionSelect(selectedRegion.regionName);
                
                }} className="p-1 bg-green-500 text-white ml-5 rounded-md">
                    Change
                </button>
            </div>
          </div> : (selectedRegion && (districtList ? <div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              Select District
            </label>
            <Dropdown
              className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
              placeHolder="Select One"
              values={districtList.map((item) => item.districtName)}
              selected={selectedDistrict && selectedDistrict.districtName}
              onSelect={onDistrictSelect}
            />
          </div> : <Spinner/>))}
         {!showCircuitDropDown ? <div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              Selected Circuit:
            </label>
            <div className="flex">
                <h3>
                    {selectedCircuit ? (selectedCircuit.circuitName ?? "None") : "None"}
                </h3>
                <button onClick={(e)=>{
                    e.preventDefault();
                    setShowDistrictDropDown(true);
                    setShowCircuitDropDown(true);
                    if(selectedRegion)
                    onRegionSelect(selectedRegion.regionName);
                
                }} className="p-1 bg-green-500 text-white ml-5 rounded-md">
                    Change
                </button>
            </div>
         </div> : ((selectedRegion && selectedDistrict) &&  (circuitList ? <div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              Select Circuit
            </label>
            <Dropdown
              className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
              placeHolder="Select One"
              values={circuitList.map(item => item.circuitName)}
              selected={selectedCircuit && selectedCircuit.circuitName}
              onSelect={onCircuitSelect}
            />
          </div> : <Spinner/>)) }
          <div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              District Type:
            </label>
            <Dropdown
              className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
              placeHolder="Select One"
              values={districtTypeList}
              selected={districtType && districtType}
              onSelect={onDistrictTypeSelect}
            />
          </div>
          <div className="flex justify-end">
              <button
                type="submit"
                className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
              >
                <FaSave size={18} color="white" />
                <p>Save</p>
              </button>
            </div>
        </form>}
      </div>
    </div>
  );
};

export default AddEditSchoolModal;
