import gesLogo from "../assets/logos/ges_logo.png";
import msrcLogo from "../assets/logos/msrc_logo.png";
import naccaLogo from "../assets/logos/nacca_logo.png";
import nasiaLogo from "../assets/logos/nasia_logo.png";
import ntcLogo from "../assets/logos/ntc_logo.png";
import srimLogo from "../assets/images/moe_logo_medium.png";

const logos = {
  1: srimLogo,
  2: msrcLogo,
  3: naccaLogo,
  4: ntcLogo,
  5: nasiaLogo,
  6: gesLogo,
};

export default logos;