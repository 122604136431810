import {React,useState,useEffect,useContext} from "react";
import SchoolInfo from "./SchoolInfo";
import SchoolListManagement from "./SchoolListManagement";
import SchoolSearchBar from "./SchoolSearchBar";
import PageNavigator from "./PageNavigator";
import AddEditSchoolModal from "./AddEditSchoolModal";
import AuthContext from "../../../../context/AuthContext";
import UserAccessUtil from "../UserAccessUtil";
import NavigationContext from "../../../../context/NavigationContext";
import { useNavigate } from "react-router-dom";

const SchoolsManagement = () => {
  const navigate = useNavigate();
  let modal = document.getElementById("add-edit-modal");
  const [selectedSchool, setSelectedSchool] = useState();
  const [isAddingSchool, setIsAddingSchool] = useState(false);
  const userInfo = useContext(AuthContext).userInfo;
  const navigationContext = useContext(NavigationContext);


  useEffect(() => {
    navigationContext.setDrawerRightContent(
      <SideMenuComponent isInSideMenu={true}/>
    );
    if(!UserAccessUtil.canGotoSchoolManagement(userInfo)){
      navigate('/admin');
      return;
    }
  }, []);
  

  function showModal() {
    modal.style.display = "block";
  }
 
  function onCancel() {
    modal.style.display = "none";
    // setSelectedSchool(null);
  }

  return (
    <div className="flex h-screen ml-4 mt-2 pb-16">
        <AddEditSchoolModal isAddingSchool={isAddingSchool} onCancel={onCancel} setIsAddingSchool={setIsAddingSchool} schoolInfo={selectedSchool}/>
        <SideMenuComponent isInSideMenu={false}/>
      <div className="w-full mr-2 ml-2 overflow-y-auto">
        <SchoolInfo isAddingSchool={isAddingSchool} setIsAddingSchool={setIsAddingSchool} showModal={showModal} schoolInfo={selectedSchool} setSelectedSchool={setSelectedSchool}/>
      </div>
    </div>
  );
};

const SideMenuComponent = ({ isInSideMenu }) => {
  return (
    <div className={`${isInSideMenu ? "flex p-2 w-full " : "hidden"} md:flex lg:flex xl:flex 2xl:flex flex-col h-full pb-16 w-80 pr-1`}>
     <SchoolSearchBar />
        <h3 className="text-lg font-bold  mb-1">
          List of Basic Schools (National)
        </h3>
        <SchoolListManagement />
        <PageNavigator />
    </div>
  );
};

export default SchoolsManagement;
