import { React, useState, useEffect, useContext } from "react";
import Spinner from "../../../../Spinner/Spinner";
import { FaSave } from "react-icons/fa";
import { toast } from "react-toastify";
import Constants from "../../../../util/Constants";
import AuthContext from "../../../../context/AuthContext";
import ModalState from "./ModalState";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";

const EditAddAgencyModal = ({ hideModal, selectedAgency, modalState }) => {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.userInfo;
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);


  const [isLoading, setIsLoading] = useState(false);
  const [agencyName, setAgencyName] = useState();
  const [agencyId, setAgencyId] = useState();
  const [agencyDescription, setAgencyDescription] = useState();

  useEffect(() => {
    if (selectedAgency) {
      setAgencyName(selectedAgency.agencyName);
      setAgencyDescription(selectedAgency.description);
      setAgencyId(selectedAgency.id);
    } else {
      setAgencyName(null);
      setAgencyDescription(null);
      setAgencyId(null);
    }
  }, [modalState, selectedAgency]);

  const handleSave = (e) => {
    e.preventDefault();
    sendRequest(false);
  };

  function checkAgencyListForAvailableId() {
    const agencyList = agencyIndicatorListContext.agencyIndicatorList.agencyList;
    var lastId = 0;
    var idExists = false;
    for (let i = 0; i < agencyList.length; i++) {
      if (agencyList[i].id > lastId) lastId = agencyList[i].id;
      if (agencyList[i].id == agencyId && !idExists) idExists = true;
    }
    return { idExists: idExists, lastId: lastId + 1 };
  }

  function sendRequest(deactivate) {

    const idChecker = checkAgencyListForAvailableId();

    if(modalState === ModalState.Adding && idChecker.idExists){
      toast.error(
        "Entered id exists, please the next available id is " +
        idChecker.lastId +
          " and above",{
            position: "top-center",
          }
      );
    }else{
    const data = {
      id: agencyId,
      agencyName: agencyName,
      description: agencyDescription,
      deactivated: (modalState === ModalState.Editing && selectedAgency) ? selectedAgency.deactivated : (modalState === ModalState.Adding) ? false : deactivate, //isEditing && selectedAgency ? selectedAgency.deactivated : deactivate,
      indicators_available: true,
      drilldown_possible: true,
      createdBy: selectedAgency ? selectedAgency.createdBy : currentUser.id,
      modifiedBy: currentUser.id,
      lastModified: new Date().toISOString(),
      retiredBy: selectedAgency ? selectedAgency.retiredBy : null,
      dateCreated: selectedAgency
        ? selectedAgency.dateCreated
        : new Date().toISOString(),
    };
    setIsLoading(true);
    let url = `${Constants.BaseUrl}/save_update_agency/&update_or_save=${modalState !== ModalState.Adding ? 'update' : 'save'}`;
    fetch(
      url,
      {
        credentials: "include",
        method: "POST",
        body: JSON.stringify(data),
        headers: { "Content-Type": "application/json" },
      }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error("An error occurred while saving, please try again later");
        }
        return res.json();
      })
      .then(async (result) => {
        console.log(result);
        if(result.hasOwnProperty('Message') && result.Message.includes('ID already exists')){
          toast.error("A record with the same ID exists, please change ID", {
            position: "top-center",
          });
          setIsLoading(false);
        }else{
          toast.info("Saved Successfully", {
            position: "top-center",
          });
          hideModal();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, {
          position: "top-center",
        });
      });
    }


  }

  function deactivateAgency() {
    sendRequest(true);
  }
  function activateAgency() {
    sendRequest(false);
  }

  return (
    <div
      className="fixed hidden inset-0 bg-gray-600 p-5 md:p-0 lg:p-0 xl:p-0 2xl:p-0 bg-opacity-50 overflow-y-auto h-full w-full"
      id="add-edit-modal"
    >
      <div className="relative top-20 mx-auto p-5 border md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full shadow-lg rounded-xl bg-white">
        <div className="flex justify-between">
          <h3 className="title-md">
            {modalState === ModalState.Editing && selectedAgency
              ? "Edit " + selectedAgency.agencyName + ":"
              : modalState == ModalState.Deactivating
              ? "Deactivate " + selectedAgency.agencyName + "?"
              : modalState == ModalState.Activating
              ? "Activate Agency?"
              : "Add Agency"}
          </h3>
          <button
            type="button"
            onClick={() => hideModal()}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {isLoading && <Spinner />}
        {!isLoading &&
          (modalState === ModalState.Activating || modalState === ModalState.Deactivating) &&
          selectedAgency && (
            <>
              Are you sure you want to
              {modalState === ModalState.Deactivating ? "deactivate " : "activate "}
              {selectedAgency.agencyName}?
              <br />
              <div className="flex space-x-5 justify-end">
                <div className="flex">
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      hideModal();
                    }}
                    className="flex cursor-pointer bg-green-500 px-8 items-center h-10 text-white rounded-lg"
                  >
                    <p>NO</p>
                  </button>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      modalState === ModalState.Deactivating ? deactivateAgency() : activateAgency();
                    }}
                    className="flex cursor-pointer bg-red-500 space-x-1.5 items-center h-10 text-white px-8 rounded-lg"
                  >
                    <p>YES</p>
                  </button>
                </div>
              </div>
            </>
          )}
        {!isLoading && (modalState === ModalState.Editing || modalState === ModalState.Adding) && (
          <form className="px-4 pb-4 space-y-3 mt-3" onSubmit={handleSave}>
           <div>
              <label
                htmlFor="indicator-id"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Agency ID
              </label>
              <input
                type="number"
                name="agency-id"
                disabled={(modalState === ModalState.Editing && selectedAgency)}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setAgencyId(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={agencyId ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="term"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Agency Name
              </label>
              <input
                type="text"
                onChange={(e) => setAgencyName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={agencyName ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="term-definition"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Agency Description
              </label>
              <textarea
                type="textarea"
                name="term-definition"
                rows={8}
                maxLength={6000}
                onChange={(e) => setAgencyDescription(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={agencyDescription ?? ""}
                required
              />
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
              >
                <FaSave size={18} color="white" />
                <p>Save</p>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditAddAgencyModal;
