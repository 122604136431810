import { useState } from "react";
import { BsSearch } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  var params;

  const onSubmit = (e) => {
    e.preventDefault();
    const queryString = query.toString();
    if (searchQuery && searchQuery !== "" && !queryString.includes("regionID") && !queryString.includes("districtName") && !queryString.includes("circuitName")) {
      query.set('search',searchQuery);
      navigate("/masterFacility?" + query.toString());
    }
  };

  const onChange = (e) => {
    e.preventDefault();
     params = location.search;
     console.log(params);
    if (e.target.value === "") {
      if (params.includes("regionID") || params.includes('search') || params.includes("districtName") || params.includes("circuitName") || params.includes("schoolID")) {
        query.delete("filter");
        query.delete('search');
        navigate("/masterFacility?" + query.toString());
      }
    } else {
      if (params.includes("regionID") || params.includes("districtName") || params.includes("circuitName")) {
        query.set("filter", e.target.value);
        navigate("/masterFacility?" + query.toString());
      }
    }
  };

  return (
    <form className="mt-1 mb-1 w-80 flex flex-nowrap" onSubmit={onSubmit}>
      <input
        className="px-2 py-1.5 w-full rounded-xl bg-white bg-opacity-80 border-black border-2"
        type="text"
        onInput={(e) => setSearchQuery(e.target.value)}
        id="school-search"
        placeholder="Search School"
        name="search"
        onChange={(e) => onChange(e)}
      />
      <button className="bg-black p-2.5 mx-2 rounded-3xl" type="submit">
        <BsSearch color="white" />
      </button>
    </form>
  );
};

export default SearchBar;
