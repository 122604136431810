import { useState } from "react";
import { BiErrorAlt } from "react-icons/bi";
import forgotPassword from "../../../../assets/svg/forgot_password.svg";
import Spinner from "../../../../Spinner/Spinner";
import { MdDone } from "react-icons/md";
import Constants from "../../../../util/Constants";
import { toast } from "react-toastify";

const ResetPassword = ({ onCancel }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [error, setError] = useState();
  const [message, setMessage] = useState();
  const [email, setEmail] = useState("");
  const [passResetDone, setPassResetDone] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [otp, setOtp] = useState("");

  // function validateForm() {
  //   return newPassword.length > 0 && confirmPassword.length > 0;
  // }

  const isValidEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onSubmit = async () => {
    if (isSent) {
      if (newPassword !== confirmPassword) {
        toast.error("Passwords do not match");
      } else {
        //TODO: if password has been changed successfully hide the views and onclick ok close the dialog

        if (passResetDone) {
          onCancel();
          setError(null);
          setIsSent(false);
          setPassResetDone(false);
          setIsLoading(false);
          setEmail("");
          setNewPassword("");
          setConfirmPassword("");
          setOtp("");
        } else {
          setIsLoading(true);
          const resetPassResponse = await fetch(
            Constants.BaseProxyUrl + "/resetPassword",
            {
              method: "POST",
              body: JSON.stringify({
                email: email,
                newPassword: newPassword,
                otp: otp,
              }),
              headers: { "Content-Type": "application/json" },
            }
          );
          const result = await resetPassResponse.json();
          if (resetPassResponse.ok) {
            setMessage(result.message);
            setError(false);
            setIsSent(true);
            setIsLoading(false);
            setPassResetDone(true);
          } else {
            setMessage(result.message);
            setError(true);
            setIsSent(false);
            setIsLoading(false);
            setPassResetDone(false);
          }
        }
      }
    } else {
      if (error === true) {
        onCancel();
        setError(null);
        setIsSent(false);
        setPassResetDone(false);
        setIsLoading(false);
        setEmail("");
        setEmail("");
        setNewPassword("");
        setConfirmPassword("");
        setOtp("");
      } else {
        if (isValidEmail(email)) {
          setIsLoading(true);
          const forgotPassResponse = await fetch(
            Constants.BaseProxyUrl + "/sendForgotPasswordOTP",
            {
              method: "POST",
              body: JSON.stringify({ email: email }),
              headers: { "Content-Type": "application/json" },
            }
          );
          const result = await forgotPassResponse.json();
          if (forgotPassResponse.ok) {
            setMessage(result.message);
            setError(false);
            setIsSent(true);
            setIsLoading(false);
          } else {
            setMessage(result.message);
            setError(true);
            setIsSent(false);
            setIsLoading(false);
          }
        } else {
          toast.error("Please enter a valid email");
        }
      }
    }
  };

  return (
    <div className="relative top-20 mx-auto p-5 border md:w-1/2 lg:w-1/3 xl:w-1/3 2xl:w-1/3 w-full shadow-lg rounded-xl bg-white">
      <div className="flex justify-between">
        <h3 className="title-md">Reset Password</h3>
        <button
          type="button"
          onClick={() => onCancel()}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
        >
          <svg
            className="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <div className="flex flex-col items-center">
          {error ? (
            <BiErrorAlt size={80} color="red" className="my-5" />
          ) : isSent ? (
            <div className="flex w-28 h-28 items-center justify-center rounded-full my-8 bg-green-500">
              <MdDone size={80} color="white" />
            </div>
          ) : (
            <div className="flex justify-center my-8">
              <img width={250} height={250} src={forgotPassword} />
            </div>
          )}
          {!error && !isSent && (
            <h3 className="mt-5">Please enter your email</h3>
          )}
          {error || isSent ? (
            <h3 className="mb-5 text-center"> {message} </h3>
          ) : (
            <input
              className="ring-1 ring-gray-300 w-full mt-2 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
              type="email"
              name="emailId"
              placeholder={"E-mail"}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          )}
          {isSent && !passResetDone && (
            <>
              <input
                className="ring-1 ring-gray-300 w-full mt-5 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                type="text"
                name="otp"
                placeholder="OTP"
                value={otp}
                onChange={(e) => setOtp(e.target.value)}
                required
              />
              <input
                className="ring-1 ring-gray-300 w-full mt-5 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                type="password"
                name="new-password"
                placeholder="New Password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                required
              />
              <input
                className="ring-1 ring-gray-300 w-full mt-5 py-2 px-3 rounded-lg focus:outline-none focus:ring-2 focus:ring-green-500"
                type="password"
                name="confirm-new-password"
                placeholder="Confirm New Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </>
          )}
          <button
            // type="submit"
            onClick={onSubmit}
            className="bg-green-500 px-5 py-2 w-full mt-5 rounded-lg text-white"
          >
            {isSent || error ? "OK" : "Submit"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
