import { React, useMemo, useContext } from "react";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import { useTable, useGlobalFilter, usePagination } from "react-table";
import TableSearchBar from "../../../TableSearchBar";
import UserAccessUtil from "../UserAccessUtil";
import AuthContext from "../../../../context/AuthContext";
import ModalState from "./ModalState";

const COLUMNS = [
  {
    Header: "Name",
    accessor: "agencyName",
  },
  {
    Header: "Description",
    accessor: "description",
  },
  {
    Header: "Number of Indicators",
  },
  {
    Header: "Number of Visualizable Indicators",
  },
  {
    Header: "Actions",
  },
];

const AgencyManagementTable = ({
  userAgency,
  setModalState,
  showModal,
  setSelectedAgency,
}) => {
  const userInfo = useContext(AuthContext).userInfo;
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);
  const columns = useMemo(() => COLUMNS, []);
  const data = useMemo(() => agencyIndicatorListContext.agencyIndicatorList.agencyList,[]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    // rows, //if you use rows it will show you full list without pagination
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
    pageOptions,
    gotoPage,
    pageCount,
    state,
    page, //if you use page page will put it in pages, thus ten items in a page
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
    
    },
    useGlobalFilter,
    usePagination
  );

  const { globalFilter, pageIndex } = state;

  return (
    <div>
      <TableSearchBar filter={globalFilter} setFilter={setGlobalFilter} />
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup, index) => {
            return (
              <tr {...headerGroup.getFooterGroupProps()} key={index}>
                {headerGroup.headers.map((column, index2) => (
                  <th {...column.getHeaderProps()} key={index2}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            );
          })}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index}>
                {row.cells.map((cell, index2) => {
                  const originalData = cell.row.original;
                  const columnHeader = cell.column.Header;
                  return (
                    <td {...cell.getCellProps()} key={index2}>
                      {columnHeader === "Actions" ? (
                        <div className="flex space-x-3">
                          {(UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) || (userAgency && userAgency.id === originalData.id)) && <button
                            onClick={() => {
                              setSelectedAgency(originalData);
                              setModalState(ModalState.Editing)
                              showModal();
                            }}
                            className="text-blue-500"
                          >
                            Edit
                          </button>}
                          { (UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) || (userAgency && userAgency.id === originalData.id)) && <button
                            onClick={() => {
                              setSelectedAgency(originalData);
                              setModalState(originalData.deactivated ? ModalState.Activating : ModalState.Deactivating);
                              showModal();
                            }}
                            className={originalData.deactivated ? "text-green-500": "text-red-500"}
                            >
                            {originalData.deactivated ? 'Activate': 'Deactivate'}
                          </button>}
                        </div>
                      ) : columnHeader === "Number of Indicators" ? (
                        <p className="text-center">
                          {originalData.indicators.length}
                        </p>
                      ) : columnHeader === "Number of Visualizable Indicators" ?  (
                        <p className="text-center">
                          {originalData.visibleOnDashboardCount}
                        </p>
                      ) : (
                        cell.render("Cell")
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="flex justify-center items-center space-x-5 p-3 w-full">
        <button
          onClick={() => gotoPage(0)}
          className="bg-black text-white rounded-lg px-3 py-1"
          disabled={!canPreviousPage}
        >
          {"<<"}
        </button>
        <button
          className="bg-black text-white rounded-lg px-2 py-1"
          disabled={!canPreviousPage}
          onClick={() => previousPage()}
        >
          {"<"} Previous
        </button>
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <span>
          | &nbsp;&nbsp;&nbsp;
          <input
            placeholder="Go to page"
            className="px-2 py-1 rounded-lg bg-white bg-opacity-80 w-36 border-black border-2"
            // value={filter || ""}
            onChange={(e) => {
              var pageNumber = e.target.value ? Number(e.target.value) : 0;
              pageNumber = pageNumber >= 1 ? pageNumber - 1 : pageNumber;
              gotoPage(pageNumber);
            }}
          />
        </span>
        <button
          className="bg-black text-white rounded-lg px-3 py-1"
          disabled={!canNextPage}
          onClick={() => nextPage()}
        >
          Next {">"}
        </button>
        <button
          onClick={() => gotoPage(pageCount - 1)}
          className="bg-black text-white rounded-lg px-3 py-1"
          disabled={!canNextPage}
        >
          {">>"}
        </button>
      </div>
    </div>
  );
};

export default AgencyManagementTable;
