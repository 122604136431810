import { React, useState, useEffect } from "react";
import { BiEdit } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import SchoolPage from "../../master_facility/components/SchoolPage";

const SchoolInfo = ({ setSelectedSchool, showModal, setIsAddingSchool }) => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const [schoolID, setSchoolID] = useState();

  useEffect(() => {
    if (query.toString().includes("schoolID")) {
      setSchoolID(query.get("schoolID"));
    }
  }, [query]);

  function onShowModal(isAdding) {
    setIsAddingSchool(isAdding);
    if (!isAdding && !query.toString().includes("schoolID")) {
      //is editing school and no school is selected to edit
      toast.error("Please select a school to edit", { position: "top-center" });
    } else showModal();
  }

  return (
    <div className="w-full h-full">
      <div className="flex justify-between w-full">
        <div
          onClick={() => onShowModal(false)}
          className="flex w-48 cursor-pointer bg-green-500 space-x-1.5 items-center h-8 truncate text-white px-2 py-1 rounded-lg"
        >
          <BiEdit size={18} color="white" />
          <p>Edit School</p>
        </div>
        {schoolID && (
          <h3 className="w-full text-center title-lg px-2.5">{query.get("schoolName")}</h3>
        )}
        <div
          onClick={() => onShowModal(true)}
          className="flex w-48 cursor-pointer bg-green-500 space-x-1.5 items-center h-8 truncate text-white px-2 py-1 rounded-lg"
        >
          <p>+ Add School</p>
        </div>
      </div>

      {schoolID ? (
        <SchoolPage
          schoolID={schoolID}
          setSchoolInfo={setSelectedSchool}
          showTopBar={false}
        />
      ) : <div className="flex w-full h-full items-center justify-center"> <h3 className="title-lg">PLEASE SELECT SCHOOL TO VIEW DETAILS</h3></div>}
    </div>
  );
};

export default SchoolInfo;
