import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import CardContainer from "../../../CardContainer";
import useFetch from "../../../../util/useFetch";
import PageLoader from "../../../PageLoader";
import Constants from "../../../../util/Constants";
import NoData from "../../../NoData";
import { useContext, useRef } from "react";
import ScreenSizeContext from "../../../../context/ScreenSizeContext";
import FullScreenModal from "../../../FullScreenModal";
import { AiOutlineFullscreen } from "react-icons/ai";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

// const labels = [
//   "ASHANTI",
//   "BONO",
//   "CENTRAL",
//   "EASTERN",
//   "G. ACCRA",
//   "NORTHERN",
//   "UPPER EAST",
//   "UPPER WEST",
//   "VOLTA",
//   "WESTERN",
//   "AHAFO",
//   "BONO EAST",
//   "NORTH EAST",
//   "SAVANNA",
//   "OTI",
//   "WESTERN NORTH",
// ];

// const top = ["KUMASI", "KPONE KATAMANSO", "TAMALE", "GA EAST", "KWABRE EAST"];

// const bottom = [
//   "SENE EAST",
//   "MION",
//   "NORTH EAST GONJA",
//   "MAMPRUGU MOADURI",
//   "YUNYOO-NASUAN",
// ];

// // const labels = [...top,...bottom];

// const topData = {
//   labels: top,
//   datasets: [
//     {
//       label: "Top",
//       data: [110938, 86281, 78564, 75123, 74646],
//       //   borderColor: "rgb(53, 162, 235)",
//       backgroundColor: "rgb(16, 185, 129)",
//     },
//   ],
// };

// const bottomData = {
//   labels: bottom,
//   datasets: [
//     {
//       label: "Bottom",
//       data: [4572, 4367, 4313, 3281, 2893],
//       //   borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: "rgb(239, 68, 68)",
//     },
//   ],
// };

const options = {
  indexAxis: "y",
  maintainAspectRatio: true,
  elements: {
    bar: {
      //   borderWidth: 2,
      borderRadius: 0,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      formatter: function (value, context) {
        return value.toLocaleString();
      },
      color: "rgb(0, 0, 0)",
      font: {
        family: "Inter",
        weight: "600",
        // size: 11,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "bold",
          size: "16",
        },
      },
    },
  },
  scales: {
    yAxes: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 12,
          weight: "bold",
        },
        color: "rgb(0, 0, 0)",
      },
    },
    xAxes: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 11,
          weight: "semibold",
        },
        color: "rgb(0, 0, 0)",
      },
    },
  },
};

const BottomAndTopFive = ({
  indicatorId,
  indicatorName,
  year,
  term,
  region,
  district,
  circuit,
  school,
}) => {
  const notApplicable = district || circuit || school;

  var url;
  if (region)
    url = `/regionTopBottom_5/${indicatorId}/${region.regionID}&year=${year}&term=${term}`;
  else url = `/nationalTopBottom_5/${indicatorId}&year=${year}&term=${term}`;

  const {
    data: topAndBottomData,
    isLoading,
    error,
  } = useFetch(
    url,
    {
      method: "GET",
    },
    Constants.ApiCallerTopBottomFive
  );
  const modalRef = useRef();

  if (notApplicable) return <div></div>;

  return (
    <CardContainer
      child={
        <PageLoader
          showNoDataAsError={true}
          isLoading={isLoading}
          error={error}
          isPage={false}
        >
          {notApplicable && <NoData />}
          <FullScreenModal
            ref={modalRef}
            title={`${indicatorName} Top & Bottom Five Districts:`}
          >
            <Body
              isFullScreen={true}
              indicatorName={indicatorName}
              modalRef={modalRef}
              notApplicable={notApplicable}
              topAndBottomData={topAndBottomData}
            />
          </FullScreenModal>
          <Body
            isFullScreen={false}
            indicatorName={indicatorName}
            modalRef={modalRef}
            notApplicable={notApplicable}
            topAndBottomData={topAndBottomData}
          />
        </PageLoader>
      }
    />
  );
};

const Body = ({
  isFullScreen,
  indicatorName,
  notApplicable,
  modalRef={modalRef},
  topAndBottomData,
}) => {
  const screenSize = useContext(ScreenSizeContext);

  return (
    <>
      {topAndBottomData && !notApplicable && (
        <div>
          <h3 className="title-md mb-3 flex justify-between">{!isFullScreen && indicatorName} Top Five Districts:
          {!isFullScreen && (
              <AiOutlineFullscreen
                size={28}
                className="ml-2 border border-gray-600 rounded-md"
                title="Fullscreen"
                onClick={() => modalRef.current.open()}
              />
            )}
          </h3>

          <div className="flex flex-col">
            {allAreZeros(topAndBottomData.topData.datasets[0].data) ? (
              <NoData />
            ) : (
              <Bar
                width={100}
                height={50}
                options={
                  isFullScreen
                    ? {
                        ...options,
                        plugins: {
                          ...options.plugins,
                          datalabels: {
                            ...options.plugins.datalabels,
                            font: {
                              ...options.plugins.datalabels.font,
                              size: 22,
                            },
                          },
                        },
                      }
                    : options
                }
                data={topAndBottomData.topData}
              />
            )}
            <h3 className="title-md mb-3">
              {!isFullScreen && indicatorName} Bottom Five Districts:
            </h3>

            {allAreZeros(topAndBottomData.bottomData.datasets[0].data) ? (
              <NoData />
            ) : (
              <Bar
                width={100}
                height={50}
                options={
                  isFullScreen
                    ? {
                        ...options,
                        plugins: {
                          ...options.plugins,
                          datalabels: {
                            ...options.plugins.datalabels,
                            font: {
                              ...options.plugins.datalabels.font,
                              size: 22,
                            },
                          },
                        },
                      }
                    : options
                }
                data={topAndBottomData.bottomData}
              />
            )}
          </div>
        </div>
      )}
    </>
  );
};

function allAreZeros(values) {
  for (var i = 0; i < values.length; i++) {
    if (values[i] > 0) return false;
    else return true;
  }
}

export default BottomAndTopFive;
