import React from 'react'

const TableSearchBar = ({ filter, setFilter }) => {
    return (
        <div className="flex justify-center">
          <input
            placeholder="Search"
            className="px-2 mb-2 py-1 rounded-xl bg-white bg-opacity-80 border-black border-2"
            value={filter || ""}
            onChange={(e) => setFilter(e.target.value)}
          />
        </div>
      );
}

export default TableSearchBar