import { useContext, useState,memo } from "react";
import * as AntDesign from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri";
import { BsKey, BsBook } from "react-icons/bs";
import moeLogo from "../../assets/images/moe_logo_small.png";
import { Link, useLocation } from "react-router-dom";
import NavigationContext from "../../context/NavigationContext";

const SideBar = memo(({ isForNavBar = false }) => {
  const [isHovering, setIsHovering] = useState(isForNavBar ? true : false);
  const location = useLocation();
  const navigationContext = useContext(NavigationContext);
  var style = isForNavBar
    ? " top-0 h-full left-0 flex flex-col text-white w-full"
    : " hidden md:fixed lg:fixed xl:fixed 2xl:fixed top-0 h-screen left-0 w-16 md:flex lg:flex xl:flex 2xl:flex flex-col text-white hover:w-52 duration-300 ease-in-out transition-all";

  return (
    location.pathname !== "/login" &&
    location.pathname !== "/" && (
      <div
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(isForNavBar ? true : false)}
        className={navigationContext.navData.color + style}
      >
        <div className="flex p-2 items-center mt-3">
          <img src={moeLogo} width={70} height={70} alt="logo" />
          {isHovering && (
            <h4 className="text-sm ml-2 font-semibold">
              Ministry of Education
            </h4>
          )}
        </div>
        {isHovering && (
          <p
            style={{
              paddingLeft: 10,
              paddingRight: 10,
              fontSize: 11,
              textAlign: "center",
            }}
          >
            PERFORMANCE MANAGEMENT & DATA VISUALIZATION DASHBOARD
          </p>
        )}
        {isHovering && <div className="bg-green-500 mb-8 mt-2 h-px w-full" />}
        {!isHovering && <div className="mb-8" />}
        <SideBarItem
          icon={AntDesign.AiOutlineHome}
          title="Home"
          isHovering={isHovering}
          path="/home"
          onClick={() => {
            if (isForNavBar) navigationContext.toggleLeftDrawer();
            navigationContext.setNavData({
              color: "bg-nav-national",
              title: "Home",
            });
          }}
        />
        <SideBarItem
          icon={AntDesign.AiOutlinePieChart}
          title="Indicators"
          isHovering={isHovering}
          path="/indicators"
          onClick={() => {
            if (isForNavBar) navigationContext.toggleLeftDrawer();
            navigationContext.setNavData({
              color: "bg-nav-national",
              title: "Indicators",
            });
          }}
        />
        <SideBarItem
          icon={AntDesign.AiOutlineBank}
          title="Agencies"
          isHovering={isHovering}
          path="/agencies"
          onClick={() => {
            if (isForNavBar) navigationContext.toggleLeftDrawer();
            navigationContext.setNavData({
              color: "bg-nav-national",
              title: "Agencies",
            });
          }}
        />
        <SideBarItem
          icon={BsKey}
          title="Master Facility"
          isHovering={isHovering}
          path="/masterFacility"
          onClick={() => {
            if (isForNavBar) navigationContext.toggleLeftDrawer();
            navigationContext.setNavData({
              color: "bg-nav-national",
              title: "Master Facility (School Data)",
            });
          }}
        />
        <SideBarItem
          icon={BsBook}
          title="Indicator Manual"
          isHovering={isHovering}
          path="/indicatorManual"
          onClick={() => {
            if (isForNavBar) navigationContext.toggleLeftDrawer();
            navigationContext.setNavData({
              color: "bg-nav-national",
              title: "Indicator Manual",
            });
          }}
        />
        <SideBarItem
          icon={AntDesign.AiOutlineBook}
          title="Data Dictionary"
          isHovering={isHovering}
          path="/dataDictionary"
          onClick={() => {
            if (isForNavBar) navigationContext.toggleLeftDrawer();
            navigationContext.setNavData({
              color: "bg-nav-national",
              title: "Data Dictionary",
            });
          }}
        />
        <SideBarItem
          icon={RiAdminLine}
          title="Admin"
          isHovering={isHovering}
          path="/admin"
          onClick={() => {
            if (isForNavBar) navigationContext.toggleLeftDrawer();
            navigationContext.setNavData({
              color: "bg-nav-national",
              title: "Admin",
            });
          }}
        />
      </div>
    )
  );
});

const SideBarItem = ({ icon: Icon, title, isHovering, path, onClick }) => (
  <NavLink
    to={path}
    onClick={onClick}
    activeClassName="bg-green-500"
    className="flex p-3 content-center hover:bg-green-500 hover:-translate-y-1 hover:scale-110 transform transition-all duration-300"
  >
    <Icon size={isHovering ? "24" : "26"} />
    {isHovering && <h3 className="py-1 px-3 text-sm">{title}</h3>}
  </NavLink>
);

function NavLink({
  to,
  exact,
  className,
  activeClassName,
  inactiveClassName,
  ...rest
}) {
  let location = useLocation();
  let isActive = false;
  isActive = location.pathname === to || location.pathname.startsWith(to);
  let allClassNames =
    className + (isActive ? ` ${activeClassName}` : ` ${inactiveClassName}`);
  return <Link className={allClassNames} to={to} {...rest} />;
}

export default SideBar;
