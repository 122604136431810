import React, { useContext, useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useLocation, useNavigate } from "react-router-dom";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import Constants from "../../../../util/Constants";

import logos from "../../../../util/logos";
import useFetch from "../../../../util/useFetch";
import CardContainer from "../../../CardContainer";
import Dropdown from "../../../dropdown/Dropdown";
import PageLoader from "../../../PageLoader";
import SimpleList from "../../../SimpleList";
import SchoolPage from "./SchoolPage";
import {
  Chart as ChartJS,
  LineController,
  LineElement,
  LinearScale,
  Filler,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import NoData from "../../../NoData";
import { Line } from "react-chartjs-2";
import noIndicatorSvg from "../../../../assets/svg/no_indicator_graph2.svg";

ChartJS.register(
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    elements: {
      point: {
        radius: 5,
        hoverRadius: 10,
      },
    },
    tooltips: {
      mode: "index",
      intersect: false,
      position: "nearest",
    },
    hover: {
      mode: "index",
      intersect: false,
    },
    datalabels: {
      formatter: (value, context) => {
        let item = context.dataset.data[context.dataIndex];

        // console.log('value is',value);
        // console.log('context',context);
        // return '';
        // return context.dataset.label === "Total"
        //   ? context.dataset.data[context.dataIndex].full
        //   : //   context.dataset.label +
        // ":\n" +

        return context.dataset.isPercentage
          ? value + "%"
          : value.toLocaleString(); //+ `${item.isPercentage ? "%" : ""}`;
      },
      color: "rgb(0, 0, 0)",
      textAlign: "center",
      font: {
        family: "Inter",
        weight: "500",
        size: 16,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "600",
          size: 12,
        },
      },
    },
    tooltip: {
      xAlign: "center",
      yAlign: "bottom",
      //   backgroundColor: "#000000",
      bodyAlign: "center",
      callbacks: {
        // beforeBody: (context) => {
        //   console.log('before body: ', context)
        //   return 'before body'
        // },
        // title: (context) => {
        //   console.log('title context: ', context)
        //   return 'title'
        // },
        // beforeLabel: (context) => {
        //   console.log('before label: ', context)
        //   return 'before label'
        // },
        label: (context) => {
          // if (context.dataset.label === "Total")
          //   return (
          //     "Total: " + context.dataset.data[context.dataIndex].full
          //   );
          let item = context.dataset.data[context.dataIndex];
          // return (
          //   context.dataset.label +
          //   ":\n"
          //    +
          //   `${context.dataset.isPercentage ? (item+ "%") : item.toLocaleString()}`
          // );
          return item.toLocaleString();
        },
      },
    },
  },
  //   maintainAspectRatio: false,
  //   responsive: true,
  tension: 0.3,
  scales: {
    y: {
      beginAtZero: true,
      display: true,
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 12,
          weight: "600",
        },
        color: "black",
        // callback: (value, index, values) => {
        //   if (String(value).includes(".5")) return "";
        //   return value;
        // },
      },
    },
    x: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 12,
          weight: "600",
        },
        color: "black",
        // callback: (value, index, values) => {
        //   if (String(value).includes(".5")) return "";
        //   return value;
        // },
      },
    },
  },
};

const SchoolPageDetail = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);
  const filteredAgencyList =
    agencyIndicatorListContext.agencyIndicatorList.agencyList.filter(
      (agc) => agc.id === 2
    );
  const indicatorList =
    agencyIndicatorListContext.agencyIndicatorList.indicatorList.filter(
      (ind) => ind.id === 59 || ind.id === 60
    );
  // const [indicatorList, setIndicatorList] = useState();
  const [selectedIndicator, setSelectedIndicator] = useState();
  const [selectedYear, setSelectedYear] = useState();
  const [selectedTerm, setSelectedTerm] = useState();
  const [emisCode, setEmisCode] = useState();
  const [selectedAgency, setSelectedAgency] = useState();
  //TODO: Only Teacher attendance rate and student attendance rate will be visualized

  useEffect(() => {
    //   first;
    if (query) {
      const year = query.get("year");
      const agencyName = query.get("agencyName");
      const indicatorName = query.get("indicatorName");
      const emisCode = query.get("emisCode");
      const indicatorId = query.get("indicatorID");
      const term = query.get("term");
      if (year) {
        setSelectedYear(year);
      }
      setSelectedAgency(filteredAgencyList[0]); //because we are only using mSRC

      // if (agencyName) {
      //   const agc = filteredAgencyList.find(
      //     (agc) => agc.agencyName === agencyName
      //   );
      //   if (agc) {
      //     setSelectedAgency(agc);
      //     setIndicatorList(agc.indicators.filter(ind => ind.id === 59 || ind.id === 60));

      //     if(selectedAgency && selectedAgency.agencyName !== agencyName){ //if different agency either than the one selected
      //     setTimeout(() => {
      //       const agencyInd = agc.indicators;
      //       if(agencyInd && agencyInd.length > 0){
      //         const first = agencyInd[0];
      //         query.set("indicatorName", first.indicatorName);
      //         query.set("indicatorID", first.id);
      //         navigate("/masterFacility/schoolDetails?" + query.toString());
      //       }
      //     }, 300);
      //   }
      //   }
      // }
      if (indicatorId && indicatorName) {
        setSelectedIndicator({
          indicatorId: indicatorId,
          indicatorName: indicatorName,
        });
      }
      if (term) {
        setSelectedTerm(term);
      }
      if(emisCode){
        setEmisCode(emisCode);
      }
    }
  }, [query.toString()]);

  function onYearSelected(year) {
    query.set("year", year);
    navigate("/masterFacility/schoolDetails?" + query.toString());
  }

  function onAgencySelected(agency) {
    query.set("agencyName", agency);
    navigate("/masterFacility/schoolDetails?" + query.toString());
  }

  function onIndicatorClick(ind) {
    query.set("indicatorName", ind.indicatorName);
    query.set("indicatorID", ind.id);
    navigate("/masterFacility/schoolDetails?" + query.toString());
  }

  function onTermClick(term) {
    query.set("term", Constants.termObject[term]);
    navigate("/masterFacility/schoolDetails?" + query.toString());
  }

  return (
    <div className="w-full h-full">
      <div className="flex mr-20 h-full">
        <div className="flex flex-col w-1/5 space-y-2 overflow-y-auto">
          <div
            onClick={() => {
              navigate(-1);
            }}
            className="flex cursor-pointer w-20 mb-3 bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
          >
            <BiArrowBack size={18} color="white" />
            <p>Back</p>
          </div>
          <Dropdown
            placeHolder="Select Agency"
            values={filteredAgencyList}
            itemKey="agencyName"
            selected={selectedAgency && selectedAgency.agencyName}
            onSelect={onAgencySelected}
          />
          <Dropdown
            placeHolder="Select Year"
            values={Constants.yearList}
            selected={selectedYear && selectedYear}
            onSelect={onYearSelected}
          />
          <Dropdown
            placeHolder="Select Term"
            values={Object.keys(Constants.termObject)}
            selected={selectedTerm && Constants.termsObjectReversed[selectedTerm]}
            onSelect={onTermClick}
          />
          <h3 className="title-md">Select Indicator</h3>
          <CardContainer
            child={
              <div className="pb-20">
                {indicatorList && (
                  <SimpleList
                    itemList={indicatorList}
                    titleKey="indicatorName"
                    onItemClick={onIndicatorClick}
                  />
                )}
              </div>
            }
          />
        </div>
        <div className="w-full ml-2 overflow-y-auto">
          <SchoolPage schoolID={query.get("schoolID")} />
          {/* Student Attendance,Teacher Attendance  */}
          {selectedIndicator && (
            <div className="flex justify-between items-center py-2 px-3">
              <h3 className="text-2xl font-bold">
                {selectedIndicator.indicatorName +
                  (selectedYear ? " (" + selectedYear + ")" : "")}
              </h3>
              <CardContainer
                child={
                  <div className="flex items-center">
                    <img
                      src={logos[selectedAgency.id]}
                      width={55}
                      alt="Agency Logo"
                      height={55}
                    />
                    <h3 className="text-2xl p-1.5 font-bold">
                      {selectedAgency.agencyName}
                    </h3>
                  </div>
                }
              />
            </div>
          )}
          {selectedIndicator && selectedYear && selectedTerm ? (
            <Charts
              indicatorId={selectedIndicator.indicatorId}
              term={selectedTerm}
              year={selectedYear}
              emisCode={emisCode}
            />
          ) : (
            <div className="w-full flex flex-col items-center">
              <h3 className="title-lg m-24">
                Please Select Year, Term and Indicator to Visualize
              </h3>
              <img
                width={500}
                height={500}
                style={{
                  backgroundColor: "white",
                  marginTop: 30,
                  padding: 10,
                  borderRadius: 10,
                }}
                src={noIndicatorSvg}
              ></img>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const Charts = ({ indicatorId, term, year, emisCode }) => {
  const { data, isLoading, error } = useFetch(
    `/msrc/&indicatorID=${indicatorId}&term=${term}&year=${year}&schoolID=${emisCode}`,//101070147
    {
      method: "GET",
    }
  );
  
  // const finalData = {};
  const finalDataAbsentPresent = {};
  const finalDataFemaleMaleAttendance = {};

  const xLabels = [];
  // const datasets = [];
  const presentDataSet = {
    label: "Present",
    backgroundColor: "rgba(58, 182, 82,0.4)",
    strokeColor: "rgba(58, 182, 82,1)",
    pointColor: "rgba(58, 182, 82,1)",
    // pointStrokeColor: "#fff",
    borderColor: "green",
    fill: true,
    // pointHighlightFill: "#fff",
    // pointHighlightStroke: "rgba(220,220,220,1)",
    data: [],
  };
  const absentDataSet = {
    label: "Absent",
    backgroundColor: "rgba(202, 57, 75,0.3)",
    strokeColor: "rgb(202, 57, 75)",
    pointColor: "rgba(220,220,220,1)",
    pointStrokeColor: "rgb(202, 57, 75)",
    borderColor: "rgb(202, 57, 75)",
    fill: true,
    // pointHighlightFill: "#fff",
    // pointHighlightStroke: "rgba(220,220,220,1)",
    data: [],
  };
  const femaleAttendanceDataSet = {
    label: "Female Attendance",
    backgroundColor: "rgba(187, 53, 95,0.2)",
    strokeColor: "rgba(187, 53, 95,1)",
    pointColor: "rgba(187, 53, 95,1)",
    // pointStrokeColor: "#fff",
    borderColor: "rgba(187, 53, 95,.8)",
    fill: true,
    // pointHighlightFill: "#fff",
    pointHighlightStroke: "rgba(220,220,220,1)",
    data: [],
  };
  const maleAttendanceDataset = {
    label: "Male Attendance",
    backgroundColor: "rgba(77, 134, 247,0.3)",
    strokeColor: "rgba(220,220,220,1)",
    pointColor: "rgba(220,220,220,1)",
    // pointStrokeColor: "#fff",
    borderColor: "rgb(77, 134, 247)",
    fill: true,
    // pointHighlightFill: "#fff",
    // pointHighlightStroke: "rgba(220,220,220,1)",
    data: [],
  };

  if(data && data.length > 0){
  for (let weekData of data) {
    xLabels.push("Week " + weekData.weekNumber);
    absentDataSet.data.push(Number(weekData.absent.replace(/,/g, "")));
    presentDataSet.data.push(Number(weekData.present.replace(/,/g, "")));
    femaleAttendanceDataSet.data.push(
      Number(weekData.femaleAttendance.replace(/,/g, ""))
    );
    maleAttendanceDataset.data.push(
      Number(weekData.maleAttendance.replace(/,/g, ""))
    );
  }
  
  finalDataAbsentPresent.labels = xLabels;
  finalDataAbsentPresent.datasets = [presentDataSet, absentDataSet];

  finalDataFemaleMaleAttendance.labels = xLabels;
  finalDataFemaleMaleAttendance.datasets = [
    maleAttendanceDataset,
    femaleAttendanceDataSet,
  ];
}

return (
    <div className="flex flex-col w-full space-y-3 pb-20">
      <PageLoader
        showNoDataAsError={true}
        isLoading={isLoading}
        error={error}
        isPage={false}
      >
        <CardContainer
          child={
            <>
              { (data && data.length > 0) && <h3 className="title-md mb-2">Males & Females</h3>}
             {(data && data.length > 0) && <Line options={options} data={finalDataFemaleMaleAttendance} />}
             {(data && (data.length === 0 || data.status === 500)) && <NoData/>}

            </>
          }
        />
      </PageLoader>
      <PageLoader
        showNoDataAsError={true}
        isLoading={isLoading}
        error={error}
        isPage={false}
      >
        <CardContainer
          child={
            <>
               {(data && data.length > 0) && <h3 className="title-md mb-2">Present & Absent</h3>}
             {(data && data.length > 0) &&  <Line options={options} data={finalDataAbsentPresent} />}
             {(data && (data.length === 0 || data.status === 500)) && <NoData/>}
            </>
          }
        />
      </PageLoader>
    </div>
  );
};

export default SchoolPageDetail;
