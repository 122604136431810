// import "./Dropdown.css";
import PropTypes from "prop-types";

const Dropdown = ({ placeHolder, values, onSelect, itemKey = "",className,selected }) => {
  return (
    //TODO: doesn't complain when the className is passed but if not it will complain about the defaultValue....
    <select className={className ?? "p-2 border-2 border-black rounded-xl"} onChange={(event) => onSelect(event.target.value)}>
      <option value={placeHolder} >
        {placeHolder + ":"}
      </option>
      {values.map((v,index) => {
        const value = getValueToDisplay(v, itemKey);
        return (
        <option
          selected={selected && (selected === value && selected ) }
          key={index}
          value={value}
        >
          {value}
        </option>
      )})}
    </select>
  );
};

function getValueToDisplay(value, itemKey) {
  if (itemKey === "") {
    return value;
  } else {
    return value[itemKey];
  }
}

Dropdown.protoTypes = {
  values: PropTypes.array,
  placeHolder: PropTypes.string,
};

export default Dropdown;
