import React, { useContext, useRef } from "react";

// import {
//     LineChart,
//     Line,
//     XAxis,
//     YAxis,
//     CartesianGrid,
//     Tooltip,
//     Legend,
//     BarChart,
//     Bar,
//     Cell,
//     ResponsiveContainer,
//   } from "recharts";

import {
  Chart as ChartJS,
  LineController,
  LineElement,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";
import Spinner from "../../../../Spinner/Spinner";
import CardContainer from "../../../CardContainer";
import NoData from "../../../NoData";
import { Line } from "react-chartjs-2";
import ScreenSizeContext from "../../../../context/ScreenSizeContext";
import FullScreenModal from "../../../FullScreenModal";
import { AiOutlineFullscreen } from "react-icons/ai";

ChartJS.register(
  LinearScale,
  PointElement,
  LineController,
  LineElement,
  Tooltip,
  Legend
);

const options = {
  plugins: {
    tension: 0.3,
    datalabels: {
      formatter: (value, context) => {
        let item = context.dataset.data[context.dataIndex];

        // console.log('value is',value);
        // console.log('context',context);
        // return '';
        // return context.dataset.label === "Total"
        //   ? context.dataset.data[context.dataIndex].full
        //   : //   context.dataset.label +
        // ":\n" +

        return context.dataset.isPercentage
          ? value + "%"
          : value.toLocaleString(); //+ `${item.isPercentage ? "%" : ""}`;
      },
      color: "rgb(0, 0, 0)",
      textAlign: "center",
      font: {
        family: "Inter",
        weight: "500",
        size: 11,
      },
    },
    legend: {
      position: "bottom",
      labels: {
        color: "rgb(0, 0, 0)",
        font: {
          family: "Inter",
          weight: "600",
          size: 12,
        },
      },
    },
    tooltip: {
      xAlign: "center",
      yAlign: "bottom",
      //   backgroundColor: "#000000",
      bodyAlign: "center",
      callbacks: {
        // beforeBody: (context) => {
        //   console.log('before body: ', context)
        //   return 'before body'
        // },
        // title: (context) => {
        //   console.log('title context: ', context)
        //   return 'title'
        // },
        // beforeLabel: (context) => {
        //   console.log('before label: ', context)
        //   return 'before label'
        // },
        label: (context) => {
          // if (context.dataset.label === "Total")
          //   return (
          //     "Total: " + context.dataset.data[context.dataIndex].full
          //   );
          let item = context.dataset.data[context.dataIndex];
          return (
            context.dataset.label +
            ":\n" +
            `${
              context.dataset.isPercentage ? item + "%" : item.toLocaleString()
            }`
          );
        },
      },
    },
  },
  //   maintainAspectRatio: false,
  //   responsive: true,
  scales: {
    y: {
      beginAtZero: true,
      display: true,
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 12,
          weight: "600",
        },
        color: "black",
        // callback: (value, index, values) => {
        //   if (String(value).includes(".5")) return "";
        //   return value;
        // },
      },
    },
    x: {
      ticks: {
        font: {
          family: "Inter", // Add your font here to change the font of your y axis
          size: 12,
          weight: "600",
        },
        color: "black",
        // callback: (value, index, values) => {
        //   if (String(value).includes(".5")) return "";
        //   return value;
        // },
      },
    },
  },
};

//data structure
// const data = {
//   datasets: [
//     {
//       label: "Females",
//       data: [
//         {
//           x: 2015,
//           y: 10,
//           r: downScaleNum(111.7),
//         },
//         {
//           x: 2016,
//           y: 10,
//           r: downScaleNum(111.7),
//         },
//         {
//           x: 2017,
//           y: 10,
//           r: downScaleNum(106.3),
//           pop: 5555,
//         },
//         {
//           x: 2018,
//           y: 10,
//           r: downScaleNum(105.3),
//         },
//         {
//           x: 2019,
//           y: 10,
//           r: downScaleNum(99.5),
//         },
//       ],
//       backgroundColor: "rgb(255, 99, 132)",
//       borderWidth: 1,
//       borderColor: "#fff",
//     },

//     {
//       label: "Males",
//       data: [
//         {
//           x: 2015,
//           y: 29,
//           r: downScaleNum(110.9),
//         },
//         {
//           x: 2016,
//           y: 29,
//           r: downScaleNum(111),
//         },
//         {
//           x: 2017,
//           y: 29,
//           r: downScaleNum(106),
//         },
//         {
//           x: 2018,
//           y: 29,
//           r: downScaleNum(105.2),
//         },
//         {
//           x: 2019,
//           y: 29,
//           r: downScaleNum(97.2),
//         },
//       ],
//       backgroundColor: "rgb(54, 162, 235)",
//       borderWidth: 1,
//       borderColor: "#fff",
//     },

//     {
//       label: "Population",
//       data: [
//         {
//           x: 2015,
//           y: 50,
//           r: 45,
//           full: "7,173,470",
//         },
//         {
//           x: 2016,
//           y: 50,
//           r: 45,
//           full: "7,336,614",
//         },
//         {
//           x: 2017,
//           y: 50,
//           r: 45,
//           full: "7,638,343",
//         },
//         {
//           x: 2018,
//           y: 50,
//           r: 45,
//           full: "7,840,346",
//         },
//         {
//           x: 2019,
//           y: 50,
//           r: 45,
//           full: "8,452,162",
//         },
//       ],
//       backgroundColor: "rgb(60, 150, 29)",
//       borderWidth: 1,
//       borderColor: "#fff",
//     },
//   ],
// };

function downScaleNum(num) {
  return (num / (num > 500 ? 1000 : 25)) * 11;
}

function upScaleNum(num) {
  return ((num / 11) * (num > 500 ? 1000 : 25)).toFixed(2);
}

const ByYear = ({ selectedIndicator, data: chartData,setHideByRegionChart }) => {
  let yearData = chartData.yearTrends;
  var finalData = {};
  var datasets = [];

  if (yearData.length > 0) {
    let tempXaxisLabels = yearData.map((item) => item.year);
    let tempLegendLabels = yearData[0].mainValueBreakdown.map(
      (item) => item.nameofBreakdown
    );

    finalData.labels = tempXaxisLabels;

    datasets.push({
      label: tempLegendLabels[0],
      data: [],
      backgroundColor: "rgba(54, 162, 235,.2)",
      fill: true,
      borderColor: "rgba(54, 162, 235,.8)",
      isPercentage:
        yearData[0].indicatorId !== "9" && yearData[0].indicatorId !== "14",
    });
    datasets.push({
      label: tempLegendLabels[1],
      data: [],
      backgroundColor: "rgba(255, 99, 132,.2)",
      fill: true,
      borderColor: "rgba(255, 99, 132,.8)",
      isPercentage:
        yearData[0].indicatorId !== "9" && yearData[0].indicatorId !== "14",
    });

    for (let item of yearData) {
      datasets[0].data.push(
        Number(item.mainValueBreakdown[0].value.replace(/,/g, ""))
      );
      datasets[1].data.push(
        Number(item.mainValueBreakdown[1].value.replace(/,/g, ""))
      );
    }
  }
  finalData.datasets = datasets;
  const modalRef = useRef();
  const onCloseDialog = () => {
    setHideByRegionChart(false);
  }

  return (
    <CardContainer
      child={
        <>
          {(!selectedIndicator || yearData.length < 1) && <NoData />}
          <FullScreenModal
            title={`${selectedIndicator.indicatorName} By Year:`}
            ref={modalRef}
            onClose={onCloseDialog}
          >
            <Body
              isFullScreen={true}
              selectedIndicator={selectedIndicator}
              setHideByRegionChart={setHideByRegionChart}
              modalRef={modalRef}
              yearData={yearData}
              finalData={finalData}
            />
          </FullScreenModal>
          <Body
            isFullScreen={false}
            selectedIndicator={selectedIndicator}
            modalRef={modalRef}
            setHideByRegionChart={setHideByRegionChart}
            yearData={yearData}
            finalData={finalData}
          />
        </>
      }
    />
  );
};

const Body = ({
  isFullScreen,
  selectedIndicator,
  yearData,
  modalRef,
  setHideByRegionChart,
  finalData,
}) => {
  const screenSize = useContext(ScreenSizeContext);

  const onExpandClick = ()=>{
    setHideByRegionChart(true)
    modalRef.current.open()
  }

  return (
    <>
      {selectedIndicator && yearData.length > 1 && (
        <div className="flex flex-col">
          <div className="w-full h-full flex justify-between">
            {!isFullScreen && (
              <h3 className="title-md mb-5">
                {selectedIndicator.indicatorName} By Year:
              </h3>
            )}
            {!isFullScreen && (
              <AiOutlineFullscreen
                size={28}
                className="ml-2 border border-gray-600 rounded-md"
                title="Fullscreen"
                onClick={onExpandClick}
              />
            )}
          </div>
          <Line options={ isFullScreen
                ? {
                    ...options,
                    plugins: {
                      ...options.plugins,
                      datalabels: {
                        ...options.plugins.datalabels,
                        font: {
                          ...options.plugins.datalabels.font,
                          size: 30,
                          weight: "bold",
                        },
                      },
                    },
                  }
                : options} data={finalData} />
        </div>
      )}
    </>
  );
};

export default ByYear;
