import React, { useState,useContext,useEffect } from "react";
import { BiArrowBack, BiPlus } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import AuthContext from "../../../../context/AuthContext";
import Constants from "../../../../util/Constants";
import CardContainer from "../../../CardContainer";
import UserAccessUtil from "../UserAccessUtil";
import AgencyManagementTable from "./AgencyManagementTable";
import EditAddAgencyModal from "./EditAddAgency";
import ModalState from "./ModalState";


const AgencyManagement = () => {
    const navigate = useNavigate();
    let modal = document.getElementById("add-edit-modal");
    const userInfo = useContext(AuthContext).userInfo;
    const agencyList = useContext(AgencyIndicatorListContext).agencyIndicatorList.agencyList;
    const agencyNameList = agencyList.map((agc)=>agc.agencyName.toLowerCase());
    const [userAgency, setUserAgency] = useState();

    useEffect(() => {
    if(!UserAccessUtil.canGoToAgencyManagement(userInfo,agencyNameList)){
        navigate('/admin');
        return ()=>{};
      }
      setUserAgency(agencyList.find((agc)=>agc.agencyName.toLowerCase() === String(userInfo.agency).toLowerCase()) ?? null);
    }, [])
    

    const [selectedAgency, setSelectedAgency] = useState();
    const [modalState, setModalState] = useState();

    function onBackClick() {
        navigate(-1);
      }
    
      function hideModal() {
        modal.style.display = "none";
      }
    
      function showModal() {
        modal.style.display = "block";
      }

    return (
        <div className="pl-4 pr-2 pt-2 h-screen">
      <div className="flex justify-between mb-2">
        <div
          onClick={() => onBackClick()}
          className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
        >
          <BiArrowBack size={18} color="white" />
          <p>Back</p>
        </div>
        <h3 className="title-md">
          Agency Management
        </h3>
        {UserAccessUtil.isSystemAdminOrMOEAdmin(userInfo) ? <div
          onClick={() => {
            setModalState(ModalState.Adding);
            showModal();
          }}
          className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
        >
          <BiPlus size={18} color="white" />
          <p> Add Agency</p>
        </div> : <div></div>}
      </div>
      <EditAddAgencyModal
        modalState={modalState}
        selectedAgency={selectedAgency}
        hideModal={hideModal}
      />
      <div className="overflow-y-auto h-full">
        <CardContainer
          setOpacity={false}
          child={
              <div>
                <AgencyManagementTable
                  userAgency={userAgency}
                  setModalState={setModalState}
                  setSelectedAgency={setSelectedAgency}
                  showModal={showModal}
                />
              </div>
          }
        />
        <div className="h-28"></div>
      </div>
    </div>
    )
}

export default AgencyManagement
