import React from "react";

const CardContainer = ({
  child,
  bgColor = "bg-white",
  setOpacity = true,
  clickable = false,
}) => {
  return (
    <div
      className={
        "rounded-xl p-2 overflow-auto " +
        bgColor +
        " " +
        (setOpacity ? "bg-opacity-80" : "") +
        (clickable ? " hover:bg-blue-200" : "")
      }
    >
      {child}
    </div>
  );
};

export default CardContainer;
