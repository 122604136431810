import { React, useContext, useState, useEffect } from "react";
import Error from "../../../Error";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import { useLocation } from "react-router-dom";
import NavigationContext from "../../../../context/NavigationContext";


var tempList;

const IndicatorList = ({ selectedIndicator,isInSideMenu, setSelectedIndicator }) => {
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);
  const [error, setError] = useState(false);
  const [filteredIndicatorList, setFilteredIndicatorList] = useState();
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    if (agencyIndicatorListContext.agencyIndicatorList) {
      const indicatorList =
        agencyIndicatorListContext.agencyIndicatorList.indicatorList;
      const ger = indicatorList.find((element) => element.id === 3);

      tempList = indicatorList.filter(
        (element) => element.visibleOnSummary === true && element.id !== 3
      );
      tempList.sort(function (x, y) {
        return x.responsibleAgencies !== null
          ? -1
          : y.responsibleAgencies !== null
          ? 1
          : 0;
      });
      tempList.unshift(ger);
      setFilteredIndicatorList(tempList);
        setTimeout(() => {
          let indId = query.get('indicatorId');
          if (!selectedIndicator && !indId)
          setSelectedIndicator(tempList[0]);
          if(indId){
            let selectedInd = tempList.find(item => item.id === Number(indId));
            setSelectedIndicator(selectedInd);
          }
        }, 500);
        
    } else {
      setError(true);
    }
  }, []);

  return (
    <div className="bg-white bg-opacity-80 rounded-xl p-2 flex flex-col overflow-auto ml-2 mb-16">
      {error && <Error />}
      {filteredIndicatorList &&
        filteredIndicatorList.map((item) => (
          <IndicatorItem
            key={item.id}
            item={item}
            isInSideMenu={isInSideMenu}
            setSelectedIndicator={setSelectedIndicator}
            selectedIndicator={selectedIndicator}
            // indicatorName={item.indicatorName}
            // level={item.level}
            // source={item.responsibleAgencies}
            // description={item.description}
          />
        ))}
    </div>
  );
};

const IndicatorItem = ({ item,isInSideMenu, setSelectedIndicator, selectedIndicator }) => {
  const navigationContext = useContext(NavigationContext);

  return (
    <div
      onClick={() =>{ 
        if(isInSideMenu) navigationContext.toggleRightDrawer();
         setSelectedIndicator(item)
      }}
      className={`p-1 transition-all rounded-xl group cursor-pointer hover:bg-blue-200 ${
        selectedIndicator
          ? selectedIndicator.id === item.id
            ? "bg-blue-200"
            : ""
          : ""
      }`}
    >
      <div className="flex">
        <h3 className="font-semibold w-full">{item.indicatorName}</h3>
      </div>
      <p>{item.level}</p>
      <p>{"Source: " + (item.responsibleAgencies ?? "To Be Developed")}</p>
      <div className="hidden opacity-0 rounded-lg group-hover:block w-full group-hover:opacity-100 duration-200 bg-blue-300">
        <p className="text-md font-semibold p-2">
          Description:
          <br /> {item.description ?? "To Be Developed"}
        </p>
      </div>
      <div className="h-px w-full bg-gray-400 mt-1"></div>
    </div>
  );
};

export default IndicatorList;
