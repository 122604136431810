import React from "react";
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

var pageCount = 1;

const Paginator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchQuery = new URLSearchParams(location.search).get("search");

  const onNextPage = ()=>{
    console.log('Next');
    pageCount +=1;
    navigate('/masterFacility?page='+pageCount);
  }

  const onPreviousPage = ()=>{
    console.log('Previous');
    if(pageCount > 1)
    pageCount -=1;
    navigate('/masterFacility?page='+pageCount);
  }

  if(searchQuery){
    return <></>;
  }

  return (
    <div className="flex justify-between pt-2">
      <div onClick={onPreviousPage} className="flex bg-black space-x-1.5 items-center text-white px-2 py-1 rounded-lg">
        <BsArrowLeftCircle size={20} color="white" />
        <button > Previous</button>
      </div>
      <div onClick={onNextPage} className="flex bg-black space-x-2 items-center text-white px-2 py-1 rounded-lg">
      <button >Next</button>
        <BsArrowRightCircle size={20} color="white" />
      </div>
    </div>
  );
};

export default Paginator;
