import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Constants from "../../../../util/Constants";
import CardContainer from "../../../CardContainer";
import NoData from "../../../NoData";
import PageLoader from "../../../PageLoader";
import SimpleList from "../../../SimpleList";

const SchoolListManagement = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const pageNumber = query.get("page");
  const searchQuery = query.get("search");

  const [isLoading, setIsLoading] = useState();
  const [error, setError] = useState();
  const [schoolList, setSchoolList] = useState();
  var path = "/schoolManuals/minimal&startAt=1&limitTo=100";
  const abortController = new AbortController();
  const options = { method: "GET", credentials: "include" };
  options.signal = abortController.signal;

  function onSchoolClick(school) {
    query.set("schoolID", school.school_id ?? school.id);
    query.set("schoolName", school.nameOfSchool);
    navigate("/admin/schools?" + query.toString());
  }

  useEffect(() => {
    if (pageNumber && pageNumber !== "") {
      path = `/schoolManuals/minimal&startAt=${
        (Number(pageNumber) - 1) * 100
      }&limitTo=${Number(pageNumber) * 100}`;
    } else if (searchQuery && searchQuery !== "") {
      path = "/schoolManuals/&schoolName=" + searchQuery;
    } else if((pageNumber && pageNumber !== "") && (searchQuery && searchQuery !== "")){
      path = "/schoolManuals/&schoolName=" + searchQuery;
    } 
    else {
      path = "/schoolManuals/minimal&startAt=1&limitTo=100";
    }
    async function getSchools() {
      try {
        setIsLoading(true);
        setError(null);
        const res = await fetch(Constants.BaseUrl + path, options);
        if (res.ok) {
          const itemList = await res.json();
          setSchoolList(
            searchQuery && searchQuery !== "" && (!pageNumber)
              ? itemList
              : itemList.school_minimal
          );
          setIsLoading(false);
          setError(null);
        } else {
          throw Error("Unable to fetch data from that resource");
        }
      } catch (err) {
        if (err.name !== "AbortError") {
          setIsLoading(false);
          setError(err.message);
        }
      }
    }

    getSchools();
    return () => abortController.abort();
  }, [pageNumber,searchQuery]);

  return (
    <CardContainer
      child={
        <PageLoader isLoading={isLoading} error={error} isPage={false}>
          <div className="overflow-y-auto">
            {schoolList &&
              (schoolList.length < 1 ? (
                <NoData details="No Results" />
              ) : (
                schoolList.map((school, index) => (
                  <div key={index} onClick={() => onSchoolClick(school)}>
                    <h3 className="hover-style p-1">{school.nameOfSchool}</h3>
                    <div className="h-px w-full bg-gray-400 mt-1"></div>
                  </div>
                ))
              ))}
          </div>
        </PageLoader>
      }
    />
  );
};

export default SchoolListManagement;
