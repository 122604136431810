import React, { useState, useContext, useEffect } from "react";
import CardContainer from "../../CardContainer";
import useFetch from "../../../util/useFetch";
import Spinner from "../../../Spinner/Spinner";
import Error from "../../Error";
import ExpandableList from "../../ExpandableList";
import logos from "../../../util/logos";
import { BiArrowBack } from "react-icons/bi";
import Constants from "../../../util/Constants";
import NavigationContext from "../../../context/NavigationContext";

var sortedTargets;

const IndicatorManual = ({
  onBackClick,
  selectedAgencyId,
  initialExpand = false,
}) => {
  const [selectedIndicator, setSelectedIndicator] = useState({});
  const navigationContext = useContext(NavigationContext);

  const {
    data: indicatorList,
    isLoading,
    error,
  } = useFetch(
    "/indicatorManuals",
    {
      method: "GET",
    },
    Constants.ApiCallerIndicatorManual
  );

  useEffect(() => {
    navigationContext.setDrawerRightContent(
      <SideMenuComponent
      isInSideMenu={true}
      onBackClick={onBackClick}
      indicatorList={indicatorList}
      selectedAgencyId={selectedAgencyId}
      initialExpand={true}
      onIndicatorClick={onIndicatorClick}
    />
    );
  }, [indicatorList]);

  function onIndicatorClick(ind,isInSideMenu) {
    if (ind.referenceIndicatorTarget.length > 0) {
      sortedTargets = ind.referenceIndicatorTarget.sort((a, b) =>
        a.target_id > b.target_id ? 1 : -1
      );
    } else {
      sortedTargets = [];
    }
    if(isInSideMenu)
    navigationContext.toggleRightDrawer();
    setSelectedIndicator(ind);
  }

  return isLoading || error ? (
    <div className="w-screen h-screen flex flex-col items-center justify-center">
      {error && <Error />}
      {isLoading && <Spinner />}
    </div>
  ) : (
    <div className="flex space-x-2 h-screen w-screen p-1 md:w-full lg:w-full xl:w-full 2xl:w-full md:py-3 lg:py-3 xl:py-3 2xl:py-3 md:pl-4 lg:pl-4 xl:pl-4 2xl:pl-4 md:pr-3 lg:pr-3 xl:pr-3 2xl:pr-3">
      <SideMenuComponent
        isInSideMenu={false}
        onBackClick={onBackClick}
        indicatorList={indicatorList}
        selectedAgencyId={selectedAgencyId}
        initialExpand={initialExpand}
        onIndicatorClick={onIndicatorClick}
      />

      {Object.keys(selectedIndicator).length === 0 ? (
        <div className="w-full h-full flex flex-col justify-center text-center">
          <h3 className="title-lg p-5">SELECT AN INDICATOR TO VIEW DETAILS</h3>
        </div>
      ) : (
        <div className="overflow-y-auto w-full">
          <div className="flex my-3 space-x-5 items-center">
            <img
              src={logos[selectedIndicator.sourceID]}
              alt="Agency Logo"
              width={150}
              height={150}
            />
            <div>
              <h3 className="text-3xl font-extrabold">
                {selectedIndicator.responsibleAgencies ?? ""}
              </h3>
              <h3 className="text-xl font-extrabold">
                {"LEVEL: " + selectedIndicator.level}
              </h3>
            </div>
          </div>
          <CardContainer
            child={
              <div>
                <h3 className="title-md">
                  {selectedIndicator.indicatorName.toUpperCase()}
                </h3>
                <p className="mb-2 font-bold">
                  {selectedIndicator.description}
                </p>
              </div>
            }
          />
          <div className="grid grid-cols-1 lg:grid-cols-3 mt-3 md:grid-cols-2 gap-2">
            {indicatorInfo.map((item, index) => (
              <CardContainer
                key={index}
                bgColor={item.title === "FORMULA" ? "bg-blue-100" : "bg-white"}
                child={
                  <div>
                    <h3 className="title-md">{item.title + ":"}</h3>
                    <p className="font-semibold">
                      {selectedIndicator[item.key]
                        ? selectedIndicator[item.key]
                        : "Info Not Available"}
                    </p>
                  </div>
                }
              />
            ))}
          </div>
          <div className="mt-3 mb-12">
            <CardContainer
              child={
                <div>
                  <h3 className="title-md">TARGETS</h3>
                  {sortedTargets && sortedTargets.length > 0 ? (
                    <>
                      <table className="table-auto w-full divide-y divide-x divide-gray-400">
                        <thead>
                          <tr>
                            <th className="w-1/2">Year</th>
                            <th className="w-1/2">Target</th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-400">
                          {selectedIndicator.referenceIndicatorTarget.map(
                            (item) => (
                              <tr className="divide-x divide-gray-400">
                                <td className="p-1">{item.year}</td>
                                <td className="p-1">{item.target}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </>
                  ) : (
                    <p className="font-semibold">Info Not Available</p>
                  )}
                </div>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

const SideMenuComponent = ({
  isInSideMenu,
  onBackClick,
  indicatorList,
  selectedAgencyId,
  initialExpand,
  onIndicatorClick,
}) => {
  return (
    <div className={`${isInSideMenu ? "block p-2" : "hidden"} md:flex lg:flex xl:flex 2xl:flex flex-col w-full md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2  mb-12`}>
      <div className="flex w-full items-center mb-2 space-x-2">
        {selectedAgencyId && (
          <div
            onClick={() => onBackClick()}
            className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
          >
            <BiArrowBack size={18} color="white" />
            <p>Back</p>
          </div>
        )}
        <h3 className="title-lg">List of Agency Indicators</h3>
      </div>
      <CardContainer
        child={
          <div>
            {indicatorList &&
              indicatorList.map((agency, index1) => (
                <ExpandableList
                  key={index1}
                  title={agency.agencyName}
                  initialExpand={
                    selectedAgencyId
                      ? agency.indicators[0].sourceID == selectedAgencyId
                      : initialExpand
                  }
                  child={agency.indicators.map((item, index2) => (
                    <div
                      key={index2}
                      className="ml-2 cursor-pointer hover:bg-gray-400 transition-colors duration-200"
                      onClick={() => onIndicatorClick(item,isInSideMenu)}
                    >
                      <h3
                        className={
                          "text-lg p-1 " +
                          (item.visibleOnDashboard ? "font-bold" : "")
                        }
                      >
                        {item.indicatorName}
                      </h3>
                      <div className="h-px w-full bg-gray-400 mt-1"></div>
                    </div>
                  ))}
                />
              ))}
          </div>
        }
      />
    </div>
  );
};

const indicatorInfo = [
  { title: "REPORTING UNIT", key: "reportingUnit" },
  { title: "POLICY SOURCE", key: "policySource" },
  { title: "FORMULA", key: "indicatorFormula" },
  { title: "COLLECTION METHOD", key: "collectionMethod" },
  { title: "COLLECTION FREQUENCY", key: "collectionFrequency" },
  { title: "INDICATOR DATA SOURCE", key: "indicatorDataSource" },
  { title: "SOURCE QUESTIONNAIRE", key: "relatedQuestionnaire" },
  { title: "QUESTION REFERENCE", key: "questions" },
  { title: "INDICATOR DISAGGREGATION", key: "indicatorDisagregation" },
];

export default IndicatorManual;
