import React,{useContext,useEffect} from "react";
import { BsCardChecklist } from "react-icons/bs";
import { RiUserSettingsLine } from "react-icons/ri";
import { HiOutlineCog } from "react-icons/hi";
import { IoSchoolOutline } from "react-icons/io5";
import CardContainer from "../../CardContainer";
import { GiBookshelf } from "react-icons/gi";
import { AiOutlineBank } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import NavigationContext from "../../../context/NavigationContext";
import AuthContext from "../../../context/AuthContext";
import AgencyIndicatorListContext from "../../../context/AgencyIndicatorListContext";
import UserAccessUtil from "./UserAccessUtil";

const iconColor = "#10B981";

const Admin = () => {
  const navigate = useNavigate();
  const navigationContext = useContext(NavigationContext);
  const userInfo = useContext(AuthContext).userInfo;
  const agencyNameList = useContext(AgencyIndicatorListContext).agencyIndicatorList.agencyList.map((agc)=>agc.agencyName.toLowerCase());
  useEffect(() => {
    navigationContext.setDrawerRightContent(null);
  }, [])
  

  const handleOnclick = (path) => {
    navigate(path);
  };
  return (
    <div className="h-screen overflow-y-auto">
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 py-4 pl-5 pr-3 gap-4 mb-12">
      {(userInfo.sysAdmin || (String(userInfo.divisionalLevel).toLowerCase() === 'ministry of education' && userInfo.userRole === 'admin') || UserAccessUtil.isAgencyAdmin(userInfo,agencyNameList)) && <CardContainer
        clickable={true}
        child={
          <div
            onClick={() => handleOnclick("/admin/users")}
            className="w-full flex flex-col cursor-pointer items-center">
            <RiUserSettingsLine className="my-5" size={150} color={iconColor} />
            <h3 className="title-lg py-5 text-center">User Management</h3>
          </div>
        }
      />}
      { UserAccessUtil.canGoToIndicatorManagement(userInfo,agencyNameList) && <CardContainer
        clickable={true}
        child={
          <div
            onClick={() => handleOnclick("/admin/indicatorManagement")}
            className="w-full flex flex-col cursor-pointer items-center"
          >
            <BsCardChecklist className="my-5" size={150} color={iconColor}/>
            <h3 className="title-lg py-5 text-center">Indicator Management</h3>
          </div>
        }
      />}
      { UserAccessUtil.canGotoSchoolManagement(userInfo) && <CardContainer
        clickable={true}
        child={
          <div
            onClick={() => handleOnclick("/admin/schools")}
            className="w-full flex flex-col cursor-pointer items-center"
          >
            <IoSchoolOutline className="my-5" size={150} color={iconColor}/>
            <h3 className="title-lg py-5 text-center">School Manual Management</h3>
          </div>
        }
      />}
      { UserAccessUtil.canGoToDataDictionaryManagement(userInfo,agencyNameList) && <CardContainer
        clickable={true}
        child={
          <div
            onClick={() => handleOnclick("/admin/dataDictionary")}
            className="w-full flex flex-col cursor-pointer items-center"
          >
            <GiBookshelf className="my-5" size={150} color={iconColor} />
            <h3 className="title-lg py-5 text-center">Data Dictionary Management</h3>
          </div>
        }
      />}
      {UserAccessUtil.canGoToAgencyManagement(userInfo,agencyNameList) && <CardContainer
        clickable={true}
        child={
          <div
            onClick={() => handleOnclick("/admin/agencies")}
            className="w-full flex flex-col cursor-pointer items-center"
          >
            <AiOutlineBank className="my-5" size={150} color={iconColor}/>
            <h3 className="title-lg py-5 text-center">Agency Management</h3>
          </div>
        }
      />}
      <CardContainer
        clickable={true}
        child={
          <div
            onClick={() => handleOnclick("/admin/settings")}
            className="w-full flex flex-col cursor-pointer items-center"
          >
            <HiOutlineCog className="my-5" size={150} color={iconColor}/>
            <h3 className="title-lg py-5 text-center">Account Settings</h3>
          </div>
        }
      />
    </div>
    </div>
  );
};

export default Admin;
