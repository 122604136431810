import { useState, useEffect, useContext } from "react";
import { FaSave } from "react-icons/fa";
import { MdAdd } from "react-icons/md";
import { toast } from "react-toastify";
import AgencyIndicatorListContext from "../../../../context/AgencyIndicatorListContext";
import AuthContext from "../../../../context/AuthContext";
import Spinner from "../../../../Spinner/Spinner";
import Constants from "../../../../util/Constants";
import Dropdown from "../../../dropdown/Dropdown";

const levelList = ["SHS", "JHS", "Basic", "SHS/TVET", "TVET", "Pre-Tertiary"];
const measuringUnitList = ["Number", "Percentage"];
const collectionFrequencyList = [
  "Annually",
  "Quarterly",
  "Termly",
  "Monthly",
  "Weekly",
];
const lowestDrillDownList = [
  "National",
  "Region",
  "District",
  "School",
  "Circuit",
];
const policySourceList = [
  "CPD Framework",
  "ESP 2018-2030",
  "Education Regulatory Bodies ACT 2020",
  "ACT 1023",
  "Improve management of education service delivery",
];

const disaggregationConstants = {
  National: "National",
  Regional: "Region",
  District: "District",
  School: "School",
};

const termList = ["Term One", "Term Two", "Term Three", "Not Applicable"];

const termsObject = {
  "Term One": 1,
  "Term Two": 2,
  "Term Three": 3,
};

//TODO: add input for years terms available

const AddEditIndicatorModal = ({
  onCancel,
  agencyList,
  selectedIndicator,
  isReinstating,
  showIsRetiringOrReinstatingDialog,
  setSelectedIndicator,
  userAgency
}) => {
  const authContext = useContext(AuthContext);
  const currentUser = authContext.userInfo;
  const agencyIndicatorListContext = useContext(AgencyIndicatorListContext);

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAgency, setSelectedAgency] = useState();
  const [selectedLevel, setSelectedLevel] = useState();
  const [lowestDrillDown, setLowestDrillDown] = useState();
  const [measuringUnit, setMeasuringUnit] = useState();
  const [indicatorId, setIndicatorId] = useState();
  const [collectionFrequency, setCollectionFrequency] = useState();
  const [policySource, setPolicySource] = useState();
  const [indicatorName, setIndicatorName] = useState();
  const [indicatorDescription, setIndicatorDescription] = useState();
  const [policyIndicator, setPolicyIndicator] = useState();
  const [termOneIsChecked, setTermOneIsChecked] = useState(false);
  const [termTwoIsChecked, setTermTwoIsChecked] = useState(false);
  const [termThreeIsChecked, setTermThreeIsChecked] = useState(false);
  const [indicatorFormulaShort, setIndicatorFormulaShort] = useState();
  const [yearTermAvailableList, setYearTermAvailableList] = useState();
  const [collectionMethod, setCollectionMethod] = useState();
  const [nationalIsChecked, setNationalIsChecked] = useState(false);
  const [regionalIsChecked, setRegionalIsChecked] = useState(false);
  const [districtIsChecked, setDistrictIsChecked] = useState(false);
  const [schoolIsChecked, setSchoolIsChecked] = useState(false);
  const [indicatorDisaggregationList, setIndicatorDisaggregationList] =
    useState([]);
  const [relatedQuestionnaire, setRelatedQuestionnaire] = useState();
  const [isKeyIndicator, setIsKeyIndicator] = useState(false);
  const [questionsShort, setQuestionsShort] = useState();
  const [yearAvailableInput, setYearAvailableInput] = useState();
  const [referenceIndicatorTargetList, setReferenceIndicatorTargetList] =
    useState();
  const [referenceYearInput, setReferenceYearInput] = useState();
  const [referenceTargetInput, setReferenceTargetInput] = useState();
  const [sdgs, setSdgs] = useState();
  const [isVisibleOnDashboard, setIsVisibleOnDashboard] = useState(false);
  const [showOnManualPage, setShowOnManualPage] = useState(true);
  const [showOnAgencyPage, setShowOnAgencyPage] = useState(false);
  const [isVisibleOnSummary, setIsVisibleOnSummary] = useState(false);
  const [agencyDropDownEnabled, setAgencyDropDownEnabled] = useState(true);
  useEffect(() => {
    if (selectedIndicator) {
      setIndicatorName(selectedIndicator.indicatorName);
      setIndicatorId(selectedIndicator.id);
      setSelectedAgency(
        agencyList.find((item) => item.id === selectedIndicator.sourceID).agencyName
      );
      setIndicatorDescription(selectedIndicator.description);
      setSelectedLevel(selectedIndicator.level);
      setMeasuringUnit(selectedIndicator.reportingUnit);
      setCollectionFrequency(selectedIndicator.collectionFrequency);
      if (selectedIndicator.indicatorDisagregation)
        selectedIndicator.indicatorDisagregation.split(",").forEach((item) => {
          addToDisaggregationList(item.trim());
        });
      setIsVisibleOnDashboard(selectedIndicator.visibleOnDashboard ?? false);
      setIsVisibleOnSummary(selectedIndicator.visibleOnSummary ?? false);
      setShowOnAgencyPage(selectedIndicator.showOnManualPage ?? false);
      setShowOnManualPage(selectedIndicator.showOnManualPage ?? false);
      setPolicyIndicator(selectedIndicator.policyIndicator);
      setPolicySource(selectedIndicator.policySource);
      setIndicatorFormulaShort(selectedIndicator.indicatorFormula);
      setCollectionMethod(selectedIndicator.collectionMethod);
      setRelatedQuestionnaire(selectedIndicator.relatedQuestionnaire);
      setIsKeyIndicator(selectedIndicator.keyIndicators ? true : false);
      setQuestionsShort(selectedIndicator.questions);
      setLowestDrillDown(selectedIndicator.lowestDrillDownLevel);
      const yta = selectedIndicator.yearsTermsAvailable;
      if (yta) {
        setYearTermAvailableList(yta.split(","));
      }
      setReferenceIndicatorTargetList(
        selectedIndicator.referenceIndicatorTarget
      );
      setSdgs(selectedIndicator.sdgs);
    } else {
      setIsVisibleOnDashboard(false);
      setIndicatorId(null);
      setIsVisibleOnSummary(false);
      setShowOnAgencyPage(false);
      setShowOnManualPage(false);
      setIndicatorName(null);
      setSelectedAgency(null);
      setIndicatorDescription(null);
      setSelectedLevel(null);
      setMeasuringUnit(null);
      setCollectionFrequency(null);
      setIndicatorDisaggregationList([]);
      setNationalIsChecked(false);
      setRegionalIsChecked(false);
      setDistrictIsChecked(false);
      setSchoolIsChecked(false);
      setPolicyIndicator(null);
      setPolicySource(null);
      setReferenceIndicatorTargetList(null);
      setIndicatorFormulaShort(null);
      setCollectionMethod(null);
      setRelatedQuestionnaire(null);
      setIsKeyIndicator(false);
      setQuestionsShort(null);
      setLowestDrillDown(null);
      setYearAvailableInput(null);
      setYearTermAvailableList(null);
      setSdgs(null);
    }
    if(userAgency){
      setSelectedAgency(userAgency.agencyName)
      setAgencyDropDownEnabled(false);
    }
  }, [selectedIndicator,agencyList]);

  function onLowestDrillDownClick(item) {
    setLowestDrillDown(item);
  }

  function onAgencySelect(agency) {
    setSelectedAgency(agency);
  }

  function onLevelSelect(level) {
    setSelectedLevel(level);
  }


  function onCollectionFrequencySelect(frequency) {
    setCollectionFrequency(frequency);
  }

  function onPolicySourceSelect(policySource) {
    setPolicySource(policySource);
  }


  function onAddReferenceTargetClick() {
    if (referenceYearInput && referenceTargetInput) {
      if (
        (referenceIndicatorTargetList ?? []).find(
          (item) => item.year === referenceYearInput
        )
      ) {
        toastError("Year " + referenceYearInput + " exists");
      } else if (
        (referenceYearInput && Number(referenceYearInput) < 2000) ||
        Number(referenceYearInput) > 2040
      ) {
        toastError("Please enter a valid year");
      } else {
        setReferenceIndicatorTargetList([
          ...(referenceIndicatorTargetList ?? []),
          { year: referenceYearInput, target: referenceTargetInput },
        ]);
        setReferenceYearInput(null);
        setReferenceTargetInput(null);
      }
    } else {
      toastError("Please enter year and target");
    }
  }

  function onRemoveReferenceTarget(target) {
    setReferenceIndicatorTargetList(
      (referenceIndicatorTargetList ?? []).filter(
        (item) => item.year !== target.year
      )
    );
  }

  function onAddYearTermClick() {
    const temp = [...(yearTermAvailableList ?? [])];

    if (termOneIsChecked && temp.includes(yearAvailableInput + ":1")) {
      toastError("Year " + yearAvailableInput + " term One exists");
    } else if (termTwoIsChecked && temp.includes(yearAvailableInput + ":2")) {
      toastError("Year " + yearAvailableInput + " term Two exists");
    } else if (termThreeIsChecked && temp.includes(yearAvailableInput + ":3")) {
      toastError("Year " + yearAvailableInput + " term Three exists");
    } else if (
      temp.includes(yearAvailableInput) &&
      !termOneIsChecked &&
      !termTwoIsChecked &&
      !termThreeIsChecked
    ) {
      toastError("Year " + yearAvailableInput + " and/or term exists");
    } else if (
      (yearAvailableInput && Number(yearAvailableInput) < 2000) ||
      Number(yearAvailableInput) > 2040
    ) {
      toastError("Please enter a valid year");
    } else {
      if (yearAvailableInput) {
        if (termOneIsChecked) {
          temp.push(yearAvailableInput + ":1");
        }
        if (termTwoIsChecked) {
          temp.push(yearAvailableInput + ":2");
        }
        if (termThreeIsChecked) {
          temp.push(yearAvailableInput + ":3");
        }
        if (!termOneIsChecked && !termTwoIsChecked && !termThreeIsChecked) {
          temp.push(yearAvailableInput);
        }
      }
      setYearTermAvailableList(temp);
      setTermOneIsChecked(false);
      setTermTwoIsChecked(false);
      setTermThreeIsChecked(false);
      setYearAvailableInput(null);
    }
  }

  function removeYearTermFromList(yearTerm) {
    const temp = (yearTermAvailableList ?? []).filter(
      (item) => item !== yearTerm
    );
    setYearTermAvailableList(temp);
  }

  function toastError(message) {
    toast.error(message, {
      position: "top-center",
    });
  }

  function addToDisaggregationList(item) {
    if (item) {
      const temp = [...(indicatorDisaggregationList ?? []), item];
      setIndicatorDisaggregationList(temp);
      switch (item) {
        case disaggregationConstants.National:
          setNationalIsChecked(true);
          break;
        case disaggregationConstants.Regional:
          setRegionalIsChecked(true);
          break;
        case disaggregationConstants.District:
          setDistrictIsChecked(true);
          break;
        case disaggregationConstants.School:
          setSchoolIsChecked(true);
          break;
      }
    }
  }

  function removeFromDisaggregationList(item) {
    const tempList = indicatorDisaggregationList.filter(
      (element) => element !== item
    );
    setIndicatorDisaggregationList(tempList);
    switch (item) {
      case disaggregationConstants.National:
        setNationalIsChecked(false);
        break;
      case disaggregationConstants.Regional:
        setRegionalIsChecked(false);
        break;
      case disaggregationConstants.District:
        setDistrictIsChecked(false);
        break;
      case disaggregationConstants.School:
        setSchoolIsChecked(false);
        break;
    }
  }

  function checkIndicatorListForAvailableId() {
    const indicatorList =
      agencyIndicatorListContext.agencyIndicatorList.indicatorList;
    var lastId = 0;
    var idExists = false;
    for (let i = 0; i < indicatorList.length; i++) {
      if (indicatorList[i].id > lastId) lastId = indicatorList[i].id;
      if (indicatorList[i].id == indicatorId && !idExists) idExists = true;
    }
    return { idExists: idExists, lastId: lastId + 1 };
  }

  const handleSave = (event) => {
    event.preventDefault();
    const checker = checkIndicatorListForAvailableId();
    if (!selectedAgency) {
      toastError("Please select agency");
    } else if (!indicatorId) {
      toastError("Please enter indicator id");
    } else if (!selectedIndicator && checker.idExists) {
      //if not editing and id entered exists
      toastError(
        "Please entered id exists, the next available id is " +
          checker.lastId +
          " and above"
      );
    } else if (!indicatorName) {
      toastError("Please enter indicator name");
    } else if (!indicatorDescription) {
      toastError("Please enter indicator description");
    } else if (!selectedLevel) {
      toastError("Please select level");
    } else if (!measuringUnit) {
      toastError("Please enter measuring unit");
    } else if (!collectionFrequency) {
      toastError("Please select collection frequency");
    } else if (indicatorDisaggregationList.length === 0) {
      toastError("Please select at least one indicator disaggregation");
    } else if (!policyIndicator) {
      toastError("Please enter policy indicator");
    } else if (!policySource) {
      toastError("Please select policy source");
    } else if (!indicatorFormulaShort) {
      toastError("Please enter indicator formula");
    }
    else if (isVisibleOnDashboard && !yearTermAvailableList) {
      toastError("Please add years and/or term");
    } else if (!collectionMethod) {
      toastError("Please enter collection method");
    }
    else if (!relatedQuestionnaire) {
      toastError("Please enter related questionnaire");
    } else if (!questionsShort) {
      toastError("Please enter questions");
    }
    else if (!lowestDrillDown) {
      toastError("Please select lowest drill down");
    } else if (!sdgs) {
      toastError("Please enter SDGS");
    }
    else {
      const indicator = {
        id: indicatorId,
        indicatorName: indicatorName,
        sourceID: agencyList.find((item) => item.agencyName === selectedAgency).id,
        level: selectedLevel,
        description: indicatorDescription,
        orderID: null,
        dataLocation: null,
        reportingUnit: measuringUnit,
        collectionFrequency: collectionFrequency,
        indicatorDisagregation: String(indicatorDisaggregationList),
        policySource: policySource,
        policyIndicator: policyIndicator,
        responsibleAgencies: selectedAgency,
        indicatorDataSource: selectedAgency,
        collectionMethod: collectionMethod,
        indicatorFormula: indicatorFormulaShort,
        relatedQuestionnaire: relatedQuestionnaire,
        remarks: null,
        keyIndicators: isKeyIndicator ? "KI" : null,
        questions: questionsShort,
        lowestDrillDownLevel: lowestDrillDown,
        indicator_formula_long: null,
        questions_long: null,
        description_long: null,
        collection_method_long: null,
        showOnManualPage: showOnManualPage,
        yearsTermsAvailable: isVisibleOnDashboard
          ? yearTermAvailableList
            ? String(yearTermAvailableList)
            : null
          : null,
        showOnAgencyPage: showOnAgencyPage,
        keepOnlyLatestAPIRecord: null,
        referenceIndicatorTarget: referenceIndicatorTargetList ?? null,
        sdgs: sdgs,
        visibleOnDashboard: isVisibleOnDashboard,
        visibleOnSummary: isVisibleOnSummary,
        createdBy: selectedIndicator ? selectedIndicator.createdBy : currentUser.id,
        modifiedBy: currentUser.id,
        lastModified: new Date().toISOString(),
        retiredBy: selectedIndicator ? selectedIndicator.retiredBy : null,
        dateCreated: selectedIndicator ? selectedIndicator.dateCreated : new Date().toDateString()
      };
      setIsLoading(true);
      fetch(
        Constants.BaseUrl +
          "/save_update_reference_indicator/&update_or_save=" +
          (selectedIndicator ? "update" : "save"),
        {
          credentials: "include",
          method: "POST",
          body: JSON.stringify(indicator),
          headers: { "Content-Type": "application/json" },
        }
      )
        .then((res) => {
          if (!res.ok) {
            throw Error(
              "An error occurred while saving, please try again later"
            );
          }
          return res.json();
        })
        .then(async (result) => {
          const savedIndicator = result.ReferenceIndicator;
          //delete existing reference targets
          await fetch(
            Constants.BaseUrl +
              `/delete_indicatortargets_for_indicator/&indicator_id=${savedIndicator.id}`,
            {
              credentials: "include",
              method: "POST",
              headers: { "Content-Type": "application/json" },
            }
          );
          if (
            referenceIndicatorTargetList &&
            referenceIndicatorTargetList.length > 0
          ) {
            //save reference target
            for (const refTarget of referenceIndicatorTargetList) {
              await fetch(
                Constants.BaseUrl +
                  "/save_update_one_referenceindicatortarget/",
                {
                  credentials: "include",
                  method: "POST",
                  body: JSON.stringify({
                    year: refTarget.year,
                    target: refTarget.target,
                    indicator: savedIndicator.id,
                  }),
                  headers: { "Content-Type": "application/json" },
                }
              );
            }
          }
          setIsLoading(false);
          toast.info("Saved Successfully", {
            position: "top-center",
          });
          onCancel();
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message, {
            position: "top-center",
          });
        });
    }
  };

  function retireReinstateIndicator() {
    const indData = {
      ...selectedIndicator,
      showOnAgencyPage: isReinstating ? true : false,
      visibleOnDashboard: isReinstating ? true : false,
      showOnAgencyPage: isReinstating ? true : false,
      visibleOnSummary: isReinstating ? true : false,
      showOnManualPage: isReinstating ? true : false,
      modifiedBy: currentUser.id,
      lastModified: new Date().toISOString(),
      retiredBy: isReinstating ? null : currentUser.id,
    };
    setIsLoading(true);
    fetch(Constants.BaseUrl + "/save_update_reference_indicator/&update_or_save=update", {
      credentials: "include",
      method: "POST",
      body: JSON.stringify(indData),
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("An error occurred while saving, please try again later");
        }
        return res.json();
      })
      .then(async (result) => {
        setIsLoading(false);
        toast.info(
          isReinstating ? "Reinstated Successfully" : "Retired Successfully",
          {
            position: "top-center",
          }
        );
        onCancel();
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message, {
          position: "top-center",
        });
      });
  }

  return (
    <div
      className="fixed hidden inset-0 p-5 md:p-0 lg:p-0 xl:p-0 2xl:p-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
      id="add-edit-modal"
    >
      <div className="relative top-20 mx-auto p-5 border md:w-1/2 lg:w-1/2 xl:w-1/2 2xl:w-1/2 w-full shadow-lg rounded-xl bg-white">
        <div className="flex justify-between">
          <h3 className="title-md">
            {showIsRetiringOrReinstatingDialog
              ? isReinstating
                ? "Reinstate"
                : "Retire"
              : selectedIndicator
              ? "Edit"
              : "Add"}{" "}
            indicator
          </h3>
          <button
            type="button"
            onClick={() => {
              setSelectedIndicator(null);
              onCancel();
            }}
            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
          >
            <svg
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        {isLoading && <Spinner />}
        {showIsRetiringOrReinstatingDialog &&
          selectedIndicator &&
          !isLoading &&
          agencyList && (
            <div>
              Are you sure you want to {isReinstating ? "reinstate" : "retire"}
              <h3 className="font-bold">{selectedIndicator.indicatorName}?</h3>
              Indicator will {isReinstating ? "" : "not"} be visible on the
              following pages:
              <br />
              <br />
              • Summary Page
              <br />
              • Indicators Page
              <br />
              • Agency Page
              <br />
              • Indicator Manual Page
              <br />
              <div className="flex space-x-5 justify-end">
                <div className="flex">
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      onCancel();
                    }}
                    className="flex cursor-pointer bg-green-500 px-8 items-center h-10 text-white rounded-lg"
                  >
                    <p>NO</p>
                  </button>
                </div>
                <div className="flex justify-end">
                  <button
                    type="submit"
                    onClick={(e) => {
                      e.preventDefault();
                      retireReinstateIndicator();
                    }}
                    className="flex cursor-pointer bg-red-500 space-x-1.5 items-center h-10 text-white px-8 rounded-lg"
                  >
                    <p>YES</p>
                  </button>
                </div>
              </div>
            </div>
          )}
        {!isLoading && !showIsRetiringOrReinstatingDialog && agencyList && (
          <form className="px-4 pb-4 space-y-5 mt-3" onSubmit={handleSave}>
            {agencyDropDownEnabled && <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Select Agency
              </label>
              <Dropdown
                className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                placeHolder="Select One"
                values={agencyList.map((item) => item.agencyName)}
                // itemKey="agencyName"
                selected={selectedAgency && selectedAgency}
                onSelect={onAgencySelect}
              />
            </div>}
            <div>
              <label
                htmlFor="indicator-id"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Indicator ID
              </label>
              <input
                type="number"
                name="indicator-id"
                disabled={selectedIndicator}
                onKeyPress={(event) => {
                  if (!/[0-9]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                onChange={(e) => setIndicatorId(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={indicatorId ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="indicatorName"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Indicator Name
              </label>
              <input
                type="name"
                name="name"
                onChange={(e) => setIndicatorName(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={indicatorName ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="indicatorDescription"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Indicator Description
              </label>
              <textarea
                type="textarea"
                name="indicator-description"
                rows={8}
                maxLength={6000}
                onChange={(e) => setIndicatorDescription(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={indicatorDescription ?? ""}
                required
              />
            </div>
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Select Level
              </label>
              <Dropdown
                className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                placeHolder="Select One"
                values={levelList}
                selected={selectedLevel}
                // itemKey="agencyName"
                onSelect={onLevelSelect}
              />
            </div>
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Measuring Unit
              </label>
              <input
                type="text"
                onChange={(e) => setMeasuringUnit(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={measuringUnit ?? ""}
                required
              />
            </div>
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Collection Frequency
              </label>
              <Dropdown
                className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                placeHolder="Select One"
                selected={collectionFrequency}
                values={collectionFrequencyList}
                onSelect={onCollectionFrequencySelect}
              />
            </div>
            <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
              Indicator Disaggregation:
            </label>
            <div className="flex flex-wrap space-x-3">
              <div className="flex">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="national-checkbox"
                  checked={nationalIsChecked}
                  className="m-1"
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      addToDisaggregationList(disaggregationConstants.National);
                    } else {
                      removeFromDisaggregationList(
                        disaggregationConstants.National
                      );
                    }
                  }}
                  // value={}
                />
                <label
                  htmlFor="national-checkbox"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  National
                </label>
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="regional-checkbox"
                  checked={regionalIsChecked}
                  className="m-1"
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      addToDisaggregationList(disaggregationConstants.Regional);
                    } else {
                      removeFromDisaggregationList(
                        disaggregationConstants.Regional
                      );
                    }
                  }}
                />
                <label
                  htmlFor="regional-checkbox"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  Region
                </label>
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="district-checkbox"
                  className="m-1"
                  checked={districtIsChecked}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      addToDisaggregationList(disaggregationConstants.District);
                    } else {
                      removeFromDisaggregationList(
                        disaggregationConstants.District
                      );
                    }
                  }}
                />
                <label
                  htmlFor="district-checkbox"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  District
                </label>
              </div>
              <div className="flex">
                <input
                  type="checkbox"
                  name="checkbox"
                  id="school-checkbox"
                  className="m-1"
                  checked={schoolIsChecked}
                  onChange={(e) => {
                    const checked = e.target.checked;
                    if (checked) {
                      addToDisaggregationList(disaggregationConstants.School);
                    } else {
                      removeFromDisaggregationList(
                        disaggregationConstants.School
                      );
                    }
                  }}
                />
                <label
                  htmlFor="school-checkbox"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  School
                </label>
              </div>
            </div>
            <div>
              <label
                htmlFor="policyIndicator"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Policy Indicator
              </label>
              <input
                type="text"
                name="policy-indicator"
                onChange={(e) => setPolicyIndicator(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={policyIndicator ?? ""}
                required
              />
            </div>
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Policy Source
              </label>
              <Dropdown
                className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                placeHolder="Select One"
                selected={policySource}
                values={policySourceList}
                onSelect={onPolicySourceSelect}
              />
            </div>

            <div>
              <label
                htmlFor="indicatorFormulaShort"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Indicator Formula:
              </label>
              <textarea
                type="text"
                rows={8}
                maxLength={6000}
                onChange={(e) => setIndicatorFormulaShort(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={indicatorFormulaShort ?? ""}
                required
              />
            </div>

            {/* <div>
              <label
                htmlFor="indicatorFormulaLong"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Indicator Formula Long:
              </label>
              <textarea
                type="textarea"
                rows={10}
                maxLength={500}
                onChange={(e) => setIndicatorFormulaLong(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={indicatorFormulaLong ?? ""}
                required
              />
            </div> */}
            <div>
              <label
                htmlFor="collectionMethod"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Collection Method:
              </label>
              <textarea
                type="textarea"
                rows={8}
                maxLength={6000}
                onChange={(e) => setCollectionMethod(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={collectionMethod ?? ""}
                required
              />
            </div>
            {/* <div>
              <label
                htmlFor="collectionMethodLong"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Collection Method Long:
              </label>
              <textarea
                type="textarea"
                rows={10}
                maxLength={6000}
                onChange={(e) => setCollectionMethodLong(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={collectionMethodLong ?? ""}
                required
              />
            </div> */}
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Related Questionnaire
              </label>
              <input
                type="text"
                onChange={(e) => setRelatedQuestionnaire(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={relatedQuestionnaire ?? ""}
                required
              />
            </div>
            <div>
              Is it a key Indicator?:
              <div className="flex space-x-3">
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    checked={isKeyIndicator}
                    id="isKey-indicator-yes-radio"
                    className="m-1"
                    onChange={(e) => setIsKeyIndicator(true)}
                    // value={}
                  />
                  <label
                    htmlFor="isKey-indicator-yes-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    id="isKey-indicator-no-radio"
                    className="m-1"
                    checked={!isKeyIndicator}
                    onChange={(e) => setIsKeyIndicator(false)}
                    // value={}
                  />
                  <label
                    htmlFor="isKey-indicator-no-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div>
              <label
                htmlFor="questionsShort"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Questions:
              </label>
              <textarea
                type="textarea"
                rows={8}
                maxLength={6000}
                onChange={(e) => setQuestionsShort(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={questionsShort ?? ""}
                required
              />
            </div>
            {/* <div>
              <label
                htmlFor="questionsLong"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Questions Long:
              </label>
              <textarea
                type="textarea"
                rows={10}
                maxLength={6000}
                onChange={(e) => setQuestionsLong(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={questionsLong ?? ""}
                required
              />
            </div> */}
            <div>
              <label className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300">
                Lowest DrillDown Level
              </label>
              <Dropdown
                className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                placeHolder="Select One"
                values={lowestDrillDownList}
                selected={lowestDrillDown}
                // itemKey="agencyName"
                onSelect={onLowestDrillDownClick}
              />
            </div>
            <div>
              <label
                htmlFor="sdgs"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                SDGS:
              </label>
              <input
                type="text"
                name="sdgs"
                onChange={(e) => setSdgs(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block w-full p-2.5"
                value={sdgs ?? ""}
                required
              />
            </div>
            <div>
              <label
                htmlFor="years-terms-available"
                className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
              >
                Reference Indicator Target:
              </label>
              {referenceIndicatorTargetList && (
                <div className="flex flex-col">
                  {referenceIndicatorTargetList.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="mx-2.5 my-1 px-2 py-1 border rounded-md flex bg-gray-100"
                      >
                        <label>
                          {"Added =>"} Year: {item.year}, Target: {item.target}
                        </label>
                        <button
                          type="button"
                          onClick={(e) => {
                            e.preventDefault();
                            onRemoveReferenceTarget(item);
                          }}
                          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                        >
                          <svg
                            className="w-4 h-4"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
              <div className="flex space-x-5 justify-between mt-2">
                <input
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  name="reference-indicator-target-years"
                  placeholder="Year"
                  onInput={(e) => {
                    e.target.value = e.target.value.slice(
                      0,
                      e.target.maxLength
                    );
                  }}
                  // maxLength={4}
                  type="number"
                  pattern="\d*"
                  maxLength="4"
                  onChange={(e) => setReferenceYearInput(e.target.value)}
                  className="bg-gray-50 border border-gray-300 w-full text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block p-2.5"
                  value={referenceYearInput ?? ""}
                  // required
                />
                <input
                  // onKeyPress={(event) => {
                  //   if (!/[0-9]/.test(event.key)) {
                  //     event.preventDefault();
                  //   }
                  // }}
                  name="reference-indicator-target"
                  placeholder="Target"
                  type="text"
                  onChange={(e) => setReferenceTargetInput(e.target.value)}
                  className="bg-gray-50 border border-gray-300 w-full text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block p-2.5"
                  value={referenceTargetInput ?? ""}
                  // required
                />

                <button
                  onClick={(e) => {
                    e.preventDefault();
                    onAddReferenceTargetClick();
                  }}
                  className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
                >
                  <MdAdd size={18} color="white" />
                  <p>Add</p>
                </button>
              </div>
            </div>
            <div>
              Has/Will data been/be submitted for visualization on Dashboard?:
              <div className="flex space-x-3">
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    id="visible-on-dashboard-yes-radio"
                    className="m-1"
                    checked={isVisibleOnDashboard}
                    onChange={(e) => setIsVisibleOnDashboard(true)}
                    // value={}
                  />
                  <label
                    htmlFor="visible-on-dashboard-yes-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    id="visible-on-dashboard-no-radio"
                    className="m-1"
                    checked={!isVisibleOnDashboard}
                    onChange={(e) => setIsVisibleOnDashboard(false)}
                    // value={}
                  />
                  <label
                    htmlFor="visible-on-dashboard-no-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            {isVisibleOnDashboard && (
              <div>
                <label
                  htmlFor="years-terms-available"
                  className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                >
                  Years and terms data will be or is available:
                </label>
                {yearTermAvailableList && (
                  <div className="flex flex-col">
                    {yearTermAvailableList.map((item, index) => {
                      return (
                        <div
                          key={index}
                          className="mx-2.5 my-1 px-2 py-1 border rounded-md flex bg-gray-100"
                        >
                          <label>
                            Added{"=>"} Year{" "}
                            {item
                              .replace(":1", ", Term One")
                              .replace(":2", ", Term Two")
                              .replace(":3", ", Term Three")}
                          </label>
                          <button
                            // key={index+1}
                            type="button"
                            onClick={(e) => {
                              e.preventDefault();
                              removeYearTermFromList(item);
                            }}
                            className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
                          >
                            <svg
                              className="w-4 h-4"
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </button>
                        </div>
                      );
                    })}
                  </div>
                )}
                <div className="flex space-x-5 justify-between mt-2">
                  <input
                    // onKeyPress={(event) => {
                    //   if (!/[0-9]/.test(event.key)) {
                    //     event.preventDefault();
                    //   }
                    // }}
                    name="years-terms-available"
                    placeholder="Year"
                    onInput={(e) => {
                      e.target.value = e.target.value.slice(
                        0,
                        e.target.maxLength
                      );
                    }}
                    // maxLength={4}
                    type="number"
                    pattern="\d*"
                    maxLength="4"
                    onChange={(e) => setYearAvailableInput(e.target.value)}
                    className="bg-gray-50 border border-gray-300 w-full text-gray-900 text-sm rounded-lg focus:ring-green-500 focus:border-green-500 block p-2.5"
                    value={yearAvailableInput ?? ""}
                  />

                  <div className="flex flex-wrap space-x-3 w-full items-center">
                    <div className="flex">
                      <input
                        type="checkbox"
                        name="checkbox"
                        id="term-one-checkbox"
                        checked={termOneIsChecked}
                        className="m-1"
                        onChange={(e) => {
                          setTermOneIsChecked(e.target.checked);
                        }}
                        // value={}
                      />
                      <label
                        htmlFor="term-one-checkbox"
                        className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                      >
                        Term One
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="checkbox"
                        name="checkbox"
                        id="term-two-checkbox"
                        checked={termTwoIsChecked}
                        className="m-1"
                        onChange={(e) => {
                          setTermTwoIsChecked(e.target.checked);
                          // const checked = e.target.checked;
                          // if (checked) {
                          //   addToDisaggregationList(disaggregationConstants.Regional);
                          // } else {
                          //   removeFromDisaggregationList(
                          //     disaggregationConstants.Regional
                          //   );
                          // }
                        }}
                      />
                      <label
                        htmlFor="term-two-checkbox"
                        className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                      >
                        Term Two
                      </label>
                    </div>
                    <div className="flex">
                      <input
                        type="checkbox"
                        name="checkbox"
                        id="term-three-checkbox"
                        className="m-1"
                        checked={termThreeIsChecked}
                        onChange={(e) => {
                          setTermThreeIsChecked(e.target.checked);
                          // const checked = e.target.checked;
                          // if (checked) {
                          //   addToDisaggregationList(disaggregationConstants.District);
                          // } else {
                          //   removeFromDisaggregationList(
                          //     disaggregationConstants.District
                          //   );
                          // }
                        }}
                      />
                      <label
                        htmlFor="term-three-checkbox"
                        className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                      >
                        Term Three
                      </label>
                    </div>
                  </div>
                  {/* <Dropdown
                    className="border border-gray-300 p-2.5 rounded-md w-full bg-gray-50"
                    placeHolder="Select Term"
                    values={termList}
                    selected={selectedTerm}
                    onSelect={onTermSelected}
                  /> */}
                  <button
                    onClick={(e) => {
                      e.preventDefault();
                      onAddYearTermClick();
                    }}
                    className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
                  >
                    <MdAdd size={18} color="white" />
                    <p>Add</p>
                  </button>
                </div>
              </div>
            )}

            <div>
              Show on Manual Page?:
              <div className="flex space-x-3">
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    checked={showOnManualPage}
                    id="showOnManualPage-yes-radio"
                    className="m-1"
                    onChange={(e) => setShowOnManualPage(true)}
                    // value={}
                  />
                  <label
                    htmlFor="showOnManualPage-yes-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    id="showOnManualPage-no-radio"
                    className="m-1"
                    checked={!showOnManualPage}
                    onChange={(e) => setShowOnManualPage(false)}
                    // value={}
                  />
                  <label
                    htmlFor="showOnManualPage-no-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div>
              Show on Agency Page?:
              <div className="flex space-x-3">
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    checked={showOnAgencyPage}
                    id="showOnAgencyPage-yes-radio"
                    className="m-1"
                    onChange={(e) => setShowOnAgencyPage(true)}
                    // value={}
                  />
                  <label
                    htmlFor="showOnAgencyPage-yes-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    id="showOnAgencyPage-no-radio"
                    className="m-1"
                    checked={!showOnAgencyPage}
                    onChange={(e) => setShowOnAgencyPage(false)}
                    // value={}
                  />
                  <label
                    htmlFor="showOnAgencyPage-no-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div>
              Show on Summary Page?:
              <div className="flex space-x-3">
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    checked={isVisibleOnSummary}
                    id="isVisibleOnSummary-yes-radio"
                    className="m-1"
                    onChange={(e) => setIsVisibleOnSummary(true)}
                    // value={}
                  />
                  <label
                    htmlFor="isVisibleOnSummary-yes-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    Yes
                  </label>
                </div>
                <div className="flex">
                  <input
                    type="radio"
                    // name=""
                    id="isVisibleOnSummary-no-radio"
                    className="m-1"
                    checked={!isVisibleOnSummary}
                    onChange={(e) => setIsVisibleOnSummary(false)}
                    // value={}
                  />
                  <label
                    htmlFor="isVisibleOnSummary-no-radio"
                    className="block mb-1 text-sm font-medium text-gray-600 dark:text-gray-300"
                  >
                    No
                  </label>
                </div>
              </div>
            </div>
            <div className="flex justify-end">
              <button
                type="submit"
                className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-10 text-white p-2.5 rounded-lg"
              >
                <FaSave size={18} color="white" />
                <p>Save</p>
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default AddEditIndicatorModal;
