import React from 'react'
import { BsArrowLeftCircle, BsArrowRightCircle } from "react-icons/bs";
import { useNavigate } from 'react-router-dom';

var pageCount = 1;

const PageNavigator = () => {
    const navigate = useNavigate();

    const onNextPage = ()=>{
        console.log('Next');
        pageCount +=1;
        navigate('/admin/schools?page='+pageCount);
      }
    
      const onPreviousPage = ()=>{
        console.log('Previous');
        if(pageCount > 1)
        pageCount -=1;
        navigate('/admin/schools?page='+pageCount);
      }

  return (
    <div className="flex justify-between mt-2">
          <div
            onClick={onPreviousPage}
            className="flex bg-black space-x-1.5 items-center text-white px-2 py-1 rounded-lg"
          >
            <BsArrowLeftCircle size={20} color="white" />
            <button> Previous</button>
          </div>
          <div
            onClick={onNextPage}
            className="flex bg-black space-x-2 items-center text-white px-2 py-1 rounded-lg"
          >
            <button>Next</button>
            <BsArrowRightCircle size={20} color="white" />
          </div>
        </div>
  )
}

export default PageNavigator