// import GaugeChart from "react-gauge-chart";
import CardContainer from "../../../CardContainer";
import NoData from "../../../NoData";
import NumberOfSchools from "./NumberOfSchools";
import PupilAttendance from "./PupilAttendance";
import TeacherAttendance from "./TeacherAttendance";

// import LoginSummaryCard from "../../../Login/LoginSummaryCard";
// const chartStyle = {
//   height: 100,
//   // width: 250
// };

const SummaryCardList = ({ data }) => {
  let schoolNumbers = data.schoolNumbers;
  let teacherNumbers = data.teacherNumbers;
  let enrolmentNumbers = data.enrolmentNumbers;

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 lg:grid-rows-1 w-full">
      <CardContainer
        child={
          <>
            {Object.keys(schoolNumbers).length < 1 ? (
              <NoData />
            ) : (
              <NumberOfSchools data={schoolNumbers} />
            )}
          </>
        }
      />
      <CardContainer
        child={
          <>
            {Object.keys(enrolmentNumbers).length < 1 ? (
              <NoData />
            ) : (
              <PupilAttendance data={enrolmentNumbers} />
            )}
          </>
        }
      />
      {/* <CardContainer child={<PupilAttendance/>}/> */}

      <CardContainer
        child={
          <>
            {Object.keys(teacherNumbers).length < 1 ? (
              <NoData />
            ) : (
              <TeacherAttendance data={teacherNumbers} />
            )}
          </>
        }
      />
    </div>
  );

  // return (
  //   <div className="grid grid-cols-1 lg:grid-cols-3 gap-2 lg:grid-rows-1 w-full">
  //     <CardContainer bgColor="bg-blue-300" child={<SummaryCardItem needlePosition={0.57} value="33,456" title="Number of Schools - 2019" />}/>
  //     <CardContainer bgColor="bg-green-300" child={<SummaryCardItem needlePosition={0.9} value="8,204,211" title="Pupil Enrolment - 2019"/>}/>
  //     <CardContainer bgColor="bg-purple-300" child={<SummaryCardItem needlePosition={0.3} value="390,495" title="Number of Teachers - 2019"/>}/>
  //   </div>
  // );
};

// const SummaryCardItem = ({needlePosition,title,value}) => (

//   <div className="flex-1">
//   <h3 className="text-center text-xl font-bold mb-2">{title}</h3>
//   <h1 className="text-xl font-bold mt-2 text-center">{value}</h1>

//   <div className="flex flex-wrap justify-center">
//     <div className="flex flex-col font-bold items-center">
//       <GaugeChart
//         className="chart"
//         id="gauge-chart1"
//         // nrOfLevels={4}
//         style={chartStyle}
//         needleColor="#000000"
//         needleBaseColor="#000000"
//         arcsLength={[20,50,30]}
//         colors={["#DC2626","#F59E0B","#3C961D"]}
//         percent={needlePosition}
//         hideText={true}
//         // textColor="#000"
//         arcPadding={0.02}
//         arcWidth={0.35}
//         // formatTextValue={(v) => v}
//       />
//           <div className='h-10'></div>

//     </div>

//     <div className="flex flex-wrap justify-center">
//         <div className="flex space-x-2 m-1">
//             <div className="w-6 rounded-md bg-red-600 h-6"></div>
//             <h3 className="font-semibold">Below Target</h3>
//         </div>

//         <div className="flex space-x-2 m-1">
//             <div className="w-6 bg-yellow-500 rounded-md h-6"></div>
//             <h3 className="font-semibold">Within Target</h3>
//         </div>
//         <div className="flex space-x-2 m-1">
//             <div className="w-6 bg-summary-green rounded-md h-6"></div>
//             <h3 className="font-semibold">Above Target</h3>
//         </div>
//         {/* <div className="flex space-x-2 m-2">
//             <div className="w-1 h-1"></div>
//             <h3 className="font-bold">Target: 99999999</h3>
//         </div> */}
//     </div>
//     <h3 className="font-bold">Target: 99999999</h3>

//   </div>
// </div>

// );

export default SummaryCardList;
