import React, { useContext,memo } from "react";
import useFetch from "../../../util/useFetch";
import CardContainer from "../../CardContainer";
import MapView from "../../MapView";
import PageLoader from "../../PageLoader";
import Paginator from "./components/Paginator";
import SearchBar from "./components/SearchBar";
import { useState, useEffect } from "react";
import ScreenSizeContext from "../../../context/ScreenSizeContext";
import SchoolList from "./components/SchoolList";
import { useLocation, useNavigate } from "react-router-dom";
import NavigationContext from "../../../context/NavigationContext";
import { BiArrowBack } from "react-icons/bi";
import Constants from "../../../util/Constants";
import SimpleList from "../../SimpleList";
import SchoolPage from "./components/SchoolPage";
import SchoolPageDetail from "./components/SchoolPageDetail";
import SchoolSummaryBarChart from "./components/SchoolSummaryBarChart";

const MasterFacility = () => {
  const navigationContext = useContext(NavigationContext);
  const [tempRegion, setTempRegion] = useState();
  const [tempDistrict, setTempDistrict] = useState();
  const [selectedSchoolID, setSelectedSchoolID] = useState();
  const [where, setWhere] = useState("National");
  const navigate = useNavigate();
  const size = useContext(ScreenSizeContext);
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const params = location.search;
  const isSchoolDetailPage = location.pathname.includes(
    "/masterFacility/schoolDetails"
  );

  useEffect(() => {
    navigationContext.setDrawerRightContent(
      <SideMenuComponent isInSideMenu={true} tempRegion={tempRegion} where={where} tempDistrict={tempDistrict}/>
    );
    if (params) {
      if (params.includes("regionID")) {
        setTempRegion({
          regionName: query.get("regionName"),
          regionID: query.get("regionID"),
        });
        setWhere(query.get("regionName"));
        navigationContext.setNavData({
          color: "bg-nav-regional",
          title: "Master Facility (School Data)",
        });
      } else if (params.includes("districtName")) {
        setTempDistrict({
          districtName: query.get("districtName"),
          districtID: query.get("districtID"),
        });
        setWhere(query.get("districtName"));
        navigationContext.setNavData({
          color: "bg-nav-district",
          title: "Master Facility (School Data)",
        });
      } else if (params.includes("circuitName")) {
        setWhere(query.get("circuitName"));
        navigationContext.setNavData({
          color: "bg-nav-circuit",
          title: "Master Facility (School Data)",
        });
      }
      if (params.includes("schoolID")) {
        setSelectedSchoolID(query.get("schoolID"));
      }
    } else {
      navigationContext.setNavData({
        color: "bg-nav-national",
        title: "Master Facility (School Data)",
      });
      setWhere("National");
    }
  }, [params]);

  function onBackClicked() {
    if (params) {
      if (params.includes("schoolID")) {
        query.delete("schoolID");
        if (selectedSchoolID) setSelectedSchoolID(null);
        navigate("/masterFacility?" + query.toString());
      } else if (params.includes("circuitName")) {
        navigate(
          "/masterFacility?districtName=" +
            tempDistrict.districtName +
            "&districtID=" +
            tempDistrict.districtID
        );
      } else if (params.includes("districtName")) {
        navigate(
          "/masterFacility?regionName=" +
            tempRegion.regionName +
            "&regionID=" +
            tempRegion.regionID
        );
        setTempDistrict(null);
      } else if (params.includes("regionName")) {
        setTempDistrict(null);
        setTempRegion(null);
        navigate("/masterFacility");
      } else {
        navigate("/masterFacility");
      }
    } else {
      if (selectedSchoolID) setSelectedSchoolID(null);
      navigate("/masterFacility");
    }
  }

  const onSelectRegion = (region) => {
    navigate(
      "/masterFacility?regionID=" +
        region.regionID +
        "&regionName=" +
        region.regionName
    );
  };

  return (
    <div className="h-screen w-screen ml-4 mt-2">
      {isSchoolDetailPage ? (
        <SchoolPageDetail />
      ) : (
        <div className="flex h-full w-full space-x-1.5">
          <SideMenuComponent isInSideMenu={false} tempRegion={tempRegion} where={where} tempDistrict={tempDistrict}/>

          {selectedSchoolID ? (
            <SchoolPage
              onBackClicked={onBackClicked}
              schoolID={selectedSchoolID}
            />
          ) : (
            <div className="flex lg:space-x-2.5 xl:space-x-2.5 2xl:space-x-2.5 overflow-x-auto overflow-y-auto w-full flex-wrap lg:flex-nowrap xl:flex-nowrap 2xl:flex-nowrap">
              <div className="flex flex-col my-16 w-min">
                {!params.includes("search") && (
                  <CardContainer
                    child={
                      <div className="w-full">
                        <div className="flex">
                          {where !== "National" && (
                            <button onClick={() => onBackClicked()}>
                              <BiArrowBack size={20} className="mx-2" />
                            </button>
                          )}
                          <h3 className="title-lg mb-3 text-center w-full">
                            {getPanelTitle(params)}
                          </h3>
                        </div>

                        <div
                          style={{
                            height: size.height / 1.3,
                            width: size.width / (size.width < 600 ? 1.1 : 3.9),
                          }}
                        >
                          {params && params.includes("regionID") && (
                            <Districts regionID={query.get("regionID")} />
                          )}
                          {params &&
                            (params.includes("districtID") ||
                              params.includes("circuitID")) && (
                              <Circuits
                                districtID={query.get("districtID")}
                                tempDistrict={tempDistrict}
                              />
                            )}
                          {!params.includes("regionID") &&
                            !params.includes("districtID") &&
                            !params.includes("circuitID") && (
                              <MapView onSelectRegion={onSelectRegion} />
                            )}
                        </div>
                      </div>
                    }
                  />
                )}
              </div>
              <div className="w-full pr-5 lg:pr-20 xl:pr-20 2xl:pr-20">
                <div className="flex my-2">
                  {where !== "National" && (
                    <div
                      onClick={() => onBackClicked()}
                      className="flex cursor-pointer bg-green-500 space-x-1.5 items-center h-8 text-white px-2 py-1 rounded-lg"
                    >
                      <BiArrowBack size={18} color="white" />
                      <p>Back</p>
                    </div>
                  )}
                  <h3 className="title-lg  w-full pl-5">
                    Schools Summary ({where})
                  </h3>
                </div>
                <SchoolSummaryBarChart
                  height={size.height}
                  tempDistrict={tempDistrict}
                  tempRegion={tempRegion}
                />
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const SideMenuComponent = ({ isInSideMenu,where,tempRegion,tempDistrict }) => {
  return (
    <div className={`${isInSideMenu ? "flex p-2 w-full " : "hidden"} md:flex lg:flex xl:flex 2xl:flex flex-col h-full pb-16 w-80 pr-1`}>
      <SearchBar />
      <h3 className="text-lg font-bold  mb-1">
        List of Basic Schools ({where})
      </h3>
      <SchoolList tempDistrict={tempDistrict} tempRegion={tempRegion} />
      {where === "National" && <Paginator />}
    </div>
  );
};

const getPanelTitle = (params) => {
  if (params) {
    if (params.includes("regionID")) {
      return "Select District";
    } else if (params.includes("districtID") || params.includes("circuitID")) {
      return "Select Circuit";
    }
  } else return "Select Region";
};

const Districts = ({ regionID }) => {
  const navigate = useNavigate();

  const {
    data: regionDistrictList,
    isLoading,
    error,
  } = useFetch(
    `/retrieve_region_district/&region=${regionID}`,
    {
      method: "GET",
    },
    Constants.ApiCallerRegionDistricts
  );

  const onDistrictClick = (district) => {
    navigate(
      "/masterFacility?districtID=" +
        district.districtID +
        "&districtName=" +
        district.districtName
    );
  };

  return (
    <PageLoader isLoading={isLoading} error={error} isPage={false}>
      {regionDistrictList && (
        <SimpleList
          itemList={regionDistrictList}
          onItemClick={onDistrictClick}
          titleKey="districtName"
        />
      )}
    </PageLoader>
  );
};

const Circuits = ({ districtID, tempDistrict }) => {
  const navigate = useNavigate();

  const {
    data: districtCircuitList,
    isLoading,
    error,
  } = useFetch(
    `/retrieve_district_circuit/&district=${
      districtID ?? tempDistrict.districtID ?? ''
    }`,
    { method: "GET" },
    Constants.ApiCallerDistrictsCircuits
  );

  const onCircuitClick = (circuit) => {
    navigate(
      "/masterFacility?circuitID=" +
        circuit.circuitID +
        "&circuitName=" +
        circuit.circuitName
    );
  };

  return (
    <PageLoader isLoading={isLoading} error={error} isPage={false}>
      {districtCircuitList && (
        <SimpleList
          itemList={districtCircuitList}
          onItemClick={onCircuitClick}
          titleKey="circuitName"
        />
      )}
    </PageLoader>
  );
};

export default MasterFacility;
