import "./Spinner.css";

const Spinner = ({size="20"}) => {
  return (
<div className="loader">Loading...</div>


    // <div className={"flex justify-center items-center p-5 "+className}>
    //   <div
    //     className={`animate-spin rounded-full h-${size} w-${size} border-b-2 ${color}`}
    //   ></div>
    // </div>
    // <div style={style} class="loader">Loading...</div>
  
    // <div className="text-black p-5">Loading...</div>
    // <div class="rounded-3xl animate-spin ease duration-300 w-10 h-10 border-t-2 border-white"></div>

    // <div className={`loader ease-linear rounded-full border-4 border-t-4 border-transparent h-${size} w-${size}`}></div>
    // <div class="flex justify-center items-center">
    // <div
    //   class={`
    //     loader
    //     ease-linear
    //     rounded-full
    //     border-8 border-t-8 border-gray-200 border-opacity-5
    //     h-${size} w-${size} ${color}
    //   `}
    // ></div>
    // </div>
  
    );
};

export default Spinner;
