import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { Intro } from "./components/pages/Intro";
import AuthContext from "./context/AuthContext";
import { useContext, useEffect, useState } from "react";
import Summary from "./components/pages/home/Home";
import SideBar from "./components/bars/SideBar";
import TitleBar from "./components/bars/TitleBar";
import NavigationContext from "./context/NavigationContext";
import IndicatorManual from "./components/pages/indicator_manual/IndicatorManual";
import Indicators from "./components/pages/indicators/Indicators";
import AgencyIndicatorListContext from "./context/AgencyIndicatorListContext";
import MasterFacility from "./components/pages/master_facility/MasterFacility";
import ScreenSizeContext from "./context/ScreenSizeContext";
import DataDictionary from "./components/pages/data_dictionary/DataDictionary";
import Spinner from "./Spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Constants from "./util/Constants";
import Login from "./components/pages/login/Login";
import AgencyIndicators from "./components/pages/agencies/AgencyIndicators";
import Agencies from "./components/pages/agencies/Agencies";
import Admin from "./components/pages/admin/Admin";
import UserManagement from "./components/pages/admin/user_management/UserManagement";
import IndicatorManualManagement from "./components/pages/admin/indicator_management/IndicatorManagement";
import SchoolsManagement from "./components/pages/admin/school_management/SchoolsManagement";
import DataDictionaryManagement from "./components/pages/admin/data_dictionary_management/DataDictionaryManagement";
import AgencyManagement from "./components/pages/admin/agency_management/AgencyManagement";
import GeneralSettings from "./components/pages/admin/GeneralSettings";
import IndicatorPageContext from "./context/IndicatorPageContext";
import { GrClose } from "react-icons/gr";
import AgencyLandingPage from "./components/pages/agencies/agency_landing_page/AgencyLandingPage";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [userInfo, setUserInfo] = useState();
  const [agencyIndicatorList, setAgencyIndicatorList] = useState();
  const [indicatorPageSharedData, setIndicatorPageSharedData] = useState({});
  const [height, setHeight] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [isLoading, setIsLoading] = useState(true);
  const [drawerLeftIsOpen, setDrawerLeftIsOpen] = useState(false);
  const [drawerRightIsOpen, setDrawerRightIsOpen] = useState(false);
  const [drawerLeftContent, setDrawerLeftContent] = useState(<div>Hello</div>);
  const [drawerLeftStyle, setDrawerLeftStyle] = useState("");
  const [drawerLeftPosition, setDrawerLeftPosition] = useState("left"); //right,left,bottom,top
  const [drawerLeftSize, setDrawerLeftSize] = useState("60%"); //50%,80%..etc

  const [drawerRightContent, setDrawerRightContent] = useState(
    <div className="w-full h-full title-lg text-center">Nothing Here</div>
  );
  const [drawerRightStyle, setDrawerRightStyle] = useState("");
  const [drawerRightPosition, setDrawerRightPosition] = useState("right"); //right,left,bottom,top
  const [drawerRightSize, setDrawerRightSize] = useState("80%"); //50%,80%..etc
  const [navData, setNavData] = useState({
    color: "bg-nav-national",
    title: "Home",
  });

  //open or close left drawer
  const toggleLeftDrawer = () => {
    setDrawerLeftIsOpen((prevState) => !prevState);
  };

  //open or close right drawer
  const toggleRightDrawer = () => {
    setDrawerRightIsOpen((prevState) => !prevState);
  };

  useEffect(() => {
    checkLoginStatus();
    const handleResize = () => {
      setHeight(window.innerHeight);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []); //empty dependency array so it only runs once at render

  function checkLoginStatus() {
    fetch(Constants.BaseProxyUrl + "/checkAuthStatus", {
      credentials: "include",
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error("Unable to fetch data from that resource");
        }
      })
      .then((result) => {
        fetchAgenciesAndIndicators(result);
      })
      .catch((err) => {
        setIsAuth(false);
        setUserInfo(null);
        setIsLoading(false);
      });
  }
  //fetch all agencies and indicators once to be used in the app instead of fetching them on almost every page
  function fetchAgenciesAndIndicators(userResult) {
    fetch(Constants.BaseUrl + "/retrieve_agencies/", {
      credentials: "include",
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        if (!res.ok) {
          throw Error("An error occurred, please refresh the page");
        }
        return res.json();
      })
      .then(async (agencyResult) => {
        const indicatorResponse = await fetch(
          Constants.BaseUrl + "/indicatorManuals/",
          {
            credentials: "include",
          }
        );
        var indicatorList;
        if (indicatorResponse.ok) {
          indicatorList = await indicatorResponse.json();
        } else {
          throw Error("An error occurred, please refresh the page");
        }
        for (let i = 0; i < agencyResult.length; i++) {
          const agencyId = agencyResult[i].id;
          const filtered = indicatorList.filter(
            (item) => item.sourceID === agencyId
          );
          agencyResult[i].indicators = filtered;
          //visibleOnDashboard count
          agencyResult[i].visibleOnDashboardCount = filtered.filter(
            (item) => item.visibleOnDashboard === true
          ).length;
        }
        const finalResult = {
          agencyList: agencyResult,
          indicatorList: indicatorList,
        };
        console.log("originalList", finalResult);
        setAgencyIndicatorList(finalResult);
        setIsAuth(true);
        setUserInfo(userResult);
        setIsLoading(false);
      })
      .catch((err) => {
        toast.error(err.message, {
          position: "top-center",
        });
      });
    // }
  }

  return (
    <div className="App">
      {isLoading ? (
        <div className="w-screen h-screen flex flex-col items-center justify-center bg-white bg-opacity-60 backdrop-filter backdrop-blur-md">
          <Spinner />
        </div>
      ) : (
        <>
          <ScreenSizeContext.Provider value={{ width, height }}>
            <AgencyIndicatorListContext.Provider
              value={{ agencyIndicatorList, setAgencyIndicatorList }}
            >
              <NavigationContext.Provider
                value={{
                  navData,
                  setNavData,
                  toggleLeftDrawer,
                  toggleRightDrawer,
                  setDrawerLeftContent,
                  setDrawerLeftPosition,
                  setDrawerLeftSize,
                  setDrawerLeftStyle,
                  setDrawerRightContent,
                  setDrawerRightStyle,
                  setDrawerRightPosition,
                  setDrawerRightSize,
                }}
              >
                <AuthContext.Provider
                  value={{ isAuth, setIsAuth, userInfo, setUserInfo }}
                >
                  <IndicatorPageContext.Provider
                    value={{
                      indicatorPageSharedData,
                      setIndicatorPageSharedData,
                    }}
                  >
                    <div className="bg-white bg-opacity-30 backdrop-filter backdrop-blur-sm h-screen w-screen">
                      <div
                        className={
                          isAuth
                            ? "flex md:pl-14 lg:pl-14 xl:pl-14 2xl:pl-14"
                            : ""
                        }
                      >
                        <div className={isAuth ? "pt-12 w-screen" : ""}>
                          <ToastContainer
                            toastStyle={{
                              backgroundColor: "black",
                              color: "white",
                            }}
                          />

                          {/*if you using tom cat uncomment line below*/}
                          {/* <Router basename="/moe"> */}
                          <Router>
                            <Drawer
                              open={drawerLeftIsOpen}
                              onClose={toggleLeftDrawer}
                              direction={drawerLeftPosition}
                              size={drawerLeftSize}
                            >
                              <div className={drawerLeftStyle}>
                                {drawerLeftContent}
                              </div>
                            </Drawer>
                            <Drawer
                              open={drawerRightIsOpen}
                              onClose={toggleRightDrawer}
                              direction={drawerRightPosition}
                              size={drawerRightSize}
                            >
                              <div
                                className={`h-full overflow-y-auto flex flex-col bg-moe ${drawerRightStyle}`}
                              >
                                <div className="flex pl-3 pt-3">
                                  <GrClose
                                    cursor={"pointer"}
                                    onClick={() => toggleRightDrawer()}
                                  />
                                </div>
                                {drawerRightContent ?? (
                                  <div className="w-full h-full title-lg text-center">
                                    Nothing Here
                                  </div>
                                )}
                              </div>
                            </Drawer>
                            <TitleBar />
                            <SideBar />
                            <Routes>
                              <Route exact path="/" element={<Login />} />
                              <Route exact path="/login" element={<Login />} />
                              <Route path="/home" element={<ProtectedRoute />}>
                                <Route path="/home" element={<Summary />} />
                              </Route>
                              <Route
                                path="/indicators"
                                element={<ProtectedRoute />}
                              >
                                <Route
                                  path="/indicators"
                                  element={<Indicators />}
                                />
                              </Route>
                              <Route
                                path="/agencies/:id"
                                element={<ProtectedRoute />}
                              >
                                <Route
                                  path="/agencies/:id"
                                  element={<AgencyIndicators />}
                                />
                              </Route>
                              <Route
                                path="/agencies"
                                element={<ProtectedRoute />}
                              >
                                <Route
                                  path="/agencies"
                                  element={<Agencies />}
                                />
                                <Route
                                  path="/agencies/landingPage/:id"
                                  element={<AgencyLandingPage />}
                                />
                              </Route>
                              <Route path="/intro" element={<ProtectedRoute />}>
                                <Route path="/intro" element={<Intro />} />
                              </Route>
                              <Route
                                path="/masterFacility"
                                element={<ProtectedRoute />}
                              >
                                <Route
                                  path="/masterFacility"
                                  element={<MasterFacility />}
                                />
                              </Route>
                              <Route
                                path="/masterFacility/schoolDetails"
                                element={<ProtectedRoute />}
                              >
                                <Route
                                  path="/masterFacility/schoolDetails"
                                  element={<MasterFacility />}
                                />
                              </Route>
                              {/* <Route path="/masterFacility/*" element={<ProtectedRoute />}>
                        <Route path="/masterFacility/*" element={<MasterFacility />} />
                      </Route> */}
                              <Route
                                path="/dataDictionary"
                                element={<ProtectedRoute />}
                              >
                                <Route
                                  path="/dataDictionary"
                                  element={<DataDictionary />}
                                />
                              </Route>
                              <Route
                                path="/indicatorManual"
                                element={<ProtectedRoute />}
                              >
                                <Route
                                  path="/indicatorManual"
                                  element={<IndicatorManual />}
                                />
                              </Route>
                              <Route path="/admin" element={<ProtectedRoute />}>
                                <Route path="/admin" element={<Admin />} />
                                <Route
                                  path="/admin/users"
                                  element={<UserManagement />}
                                />
                                <Route
                                  path="/admin/indicatorManagement"
                                  element={<IndicatorManualManagement />}
                                />
                                <Route
                                  path="/admin/schools"
                                  element={<SchoolsManagement />}
                                />
                                <Route
                                  path="/admin/dataDictionary"
                                  element={<DataDictionaryManagement />}
                                />
                                <Route
                                  path="/admin/agencies"
                                  element={<AgencyManagement />}
                                />
                                <Route
                                  path="/admin/settings"
                                  element={<GeneralSettings />}
                                />
                              </Route>

                              <Route path="*" element={<PageNotFound />} />
                            </Routes>
                          </Router>
                        </div>
                      </div>
                    </div>
                  </IndicatorPageContext.Provider>
                </AuthContext.Provider>
              </NavigationContext.Provider>
            </AgencyIndicatorListContext.Provider>
          </ScreenSizeContext.Provider>
        </>
      )}
    </div>
  );
}

const ProtectedRoute = () => {
  const authContext = useContext(AuthContext);
  return authContext.isAuth ? <Outlet /> : <Navigate to="/login" />;
};

const PageNotFound = () => {
  return (
    <div className="h-screen w-full flex flex-col items-center justify-center">
      <h1 className="text-center font-black text-5xl">Sorry 😔</h1>
      <h2 className="text-center text-xl mt-3">Page Not Found</h2>
    </div>
  );
};

export default App;
