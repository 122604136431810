import React, { useRef } from "react";
import PropTypes from "prop-types";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import CardContainer from "../../../CardContainer";
import Spinner from "../../../../Spinner/Spinner";
import NoData from "../../../NoData";
import { AiOutlineFullscreen } from "react-icons/ai";
import FullScreenModal from "../../../FullScreenModal";

const colors2 = [
  "#1F77B4",
  "#FF7F0E",
  "#2CA02C",
  "#D62728",
  "#9467BD",
  "#8C564B",
  "#E377C2",
  "#8142FF",
  "#10B981",
  "#1D4ED8",
  "#2F6F07",
  "#608090",
  "#C87A08",
  "#FFC534",
  "#A561FE",
  "#232F3E",
];

//data structure
// const regionData = [
//   {
//     name: "Ashanti",
//     value: 97.1,
//     target: 115.7,
//   },
//   {
//     name: "Bono",
//     value: 109.8,
//     target: 120.1,
//   },
//   {
//     name: "Bono East",
//     value: 101.6,
//     target: 101.6,
//   },
//   {
//     name: "Ahafo",
//     value: 102.6,
//     target: 102.6,
//   },
//   {
//     name: "Central",
//     value: 114.5,
//     target: 120,
//   },
//   {
//     name: "Eastern",
//     value: 87.1,
//     target: 100.0,
//   },
//   {
//     name: "Greater Accra",
//     value: 94.5,
//     target: 150.0,
//   },
//   {
//     name: "Northern",
//     value: 101.8,
//     target: 101.8,
//   },
//   {
//     name: "Savannah",
//     value: 74.4,
//     target: 108.6,
//   },
//   {
//     name: "North East",
//     value: 82.7,
//     target: 100.0,
//   },
//   {
//     name: "Upper East",
//     value: 101.6,
//     target: 115.5,
//   },
//   {
//     name: "Upper West",
//     value: 103.5,
//     target: 105.5,
//   },
//   {
//     name: "Volta",
//     value: 94.0,
//     target: 103.5,
//   },
//   {
//     name: "Oti",
//     value: 84.7,
//     target: 100.0,
//   },
//   {
//     name: "Western",
//     value: 96.2,
//     target: 120.5,
//   },
//   {
//     name: "Western North",
//     value: 103.4,
//     target: 120.8,
//   },
// ];

const getPath = (x, y, width, height) => `M${x},${y + height}
          C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3} ${
  x + width / 2
}, ${y}
          C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
  y + height
} ${x + width}, ${y + height}
          Z`;

const TriangleBar = (props) => {
  const { fill, x, y, width, height } = props;

  return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
};

TriangleBar.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

const ByRegion = ({ selectedIndicator, hideByRegionChart,selectedYear,setHideByRegionChart, data: chartData }) => {
  let regionData = chartData.regionalTrends;

  if (regionData.length > 0) {
    regionData = regionData.map((item) => ({
      regionName: item.regionName,
      value: item.value === "�" ? "0" : item.value,
    }));
  }
  const modalRef = useRef();
  const onCloseDialog = () => {
    setHideByRegionChart(false);
  }

  return (
    <CardContainer
      child={
        <>
          {(regionData.length < 1 || !selectedIndicator) && <NoData />}
          <FullScreenModal
            title={`${selectedIndicator.indicatorName} By Year:`}
            ref={modalRef}
            onClose={onCloseDialog}
          >
            <Body
              isFullScreen={true}
              selectedIndicator={selectedIndicator}
              modalRef={modalRef}
              selectedYear={selectedYear}
              regionData={regionData}
              setHideByRegionChart={setHideByRegionChart}
            />
          </FullScreenModal>
          {!hideByRegionChart && <Body
            isFullScreen={false}
            modalRef={modalRef}
            selectedIndicator={selectedIndicator}
            selectedYear={selectedYear}
            regionData={regionData}
            setHideByRegionChart={setHideByRegionChart}
          />}
        </>
      }
    />
  );
};

const Body = ({
  isFullScreen,
  modalRef,
  regionData,
  selectedYear,
  selectedIndicator,
  setHideByRegionChart,
}) => {
  const onExpandClick = ()=>{
    setHideByRegionChart(true)
    modalRef.current.open()
  }

  return (
    <>
      {regionData.length > 0 && selectedIndicator && (
        <div className="w-full">
          <div className="w-full h-full flex justify-between">
            {!isFullScreen && (
              <h3 className="title-md mb-5">
                {selectedIndicator.indicatorName +
                  " By Region (" +
                  selectedYear +
                  "):"}
              </h3>
            )}
            {!isFullScreen && (
              <AiOutlineFullscreen
                size={28}
                className="ml-2 border border-gray-600 rounded-md"
                title="Fullscreen"
                onClick={onExpandClick}
              />
            )}
          </div>
          <ResponsiveContainer width="100%" aspect={16 / 9}>
            <BarChart
              // width={600}
              // height={300}
              data={regionData}
              margin={{
                top: 20,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="regionName" />
              <YAxis unit="%" />
              <Tooltip />
              <Bar
                dataKey="value"
                unit="%"
                fill="#8884d8"
                shape={<TriangleBar />}
                label={{ position: "top", fontSize: 11, fontWeight: 600 }}
              >
                {regionData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={colors2[index % 20]} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default ByRegion;
